// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--ec517";
export var arrow_down = "styles-module--arrow_down--d7982";
export var arrow_no = "styles-module--arrow_no--41b11";
export var arrow_yes = "styles-module--arrow_yes--ecac8";
export var error = "styles-module--error--4ab61";
export var flex = "styles-module--flex--6c3df";
export var flex_1 = "styles-module--flex_1--31da1";
export var flex_center = "styles-module--flex_center--4f849";
export var flex_center_col = "styles-module--flex_center_col--ea709";
export var flex_col = "styles-module--flex_col--8928c";
export var flex_column = "styles-module--flex_column--189a6";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--e1916";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--460e4";
export var flex_row = "styles-module--flex_row--f7af4";
export var imageAnimationHolder = "styles-module--image-animation-holder--9a9b8";
export var label = "styles-module--label--315c8";
export var linkAnimation = "styles-module--link-animation--81fae";
export var mark = "styles-module--mark--1c4d6";
export var radio_wrapper = "styles-module--radio_wrapper--294a3";
export var rotating = "styles-module--rotating--fe71f";
export var select_wrapper = "styles-module--select_wrapper--2a803";
export var swiperWrapper = "styles-module--swiper-wrapper--a7aeb";
export var wrapper = "styles-module--wrapper--122ba";