// extracted by mini-css-extract-plugin
export var arrow = "OpenPositions-module--arrow--961e0";
export var arrow_no = "OpenPositions-module--arrow_no--4bc74";
export var arrow_yes = "OpenPositions-module--arrow_yes--ecb8f";
export var buttonHolder = "OpenPositions-module--button-holder--a6cbb";
export var content = "OpenPositions-module--content--2e095";
export var flex = "OpenPositions-module--flex--f88be";
export var flex_1 = "OpenPositions-module--flex_1--6c223";
export var flex_center = "OpenPositions-module--flex_center--6f3e2";
export var flex_center_col = "OpenPositions-module--flex_center_col--3fe7d";
export var flex_col = "OpenPositions-module--flex_col--c160b";
export var flex_column = "OpenPositions-module--flex_column--7b632";
export var flex_fix_center_col = "OpenPositions-module--flex_fix_center_col--a9771";
export var flex_fixed_center_col = "OpenPositions-module--flex_fixed_center_col--3e6d2";
export var flex_row = "OpenPositions-module--flex_row--d2c40";
export var imageAnimationHolder = "OpenPositions-module--image-animation-holder--1b147";
export var linkAnimation = "OpenPositions-module--link-animation--ae2c2";
export var linkHolder = "OpenPositions-module--link-holder--59786";
export var rotating = "OpenPositions-module--rotating--af2e1";
export var swiperWrapper = "OpenPositions-module--swiper-wrapper--00859";
export var title = "OpenPositions-module--title--1968b";