import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { inject, observer } from "mobx-react";
import { motion } from "framer-motion";

import { IHeroWithImage } from "~models";
import { Container, Row, Col, VideoElement } from "~components";
import { UIStore } from "~stores";
import {
  fadeInItem,
  imageAnimationVariants,
  imageHolderAnimationVariants,
} from "~libs/framer/variants";

import * as style from "./HeroWithImage.module.scss";

interface Props {
  section: IHeroWithImage;
  uiStore?: UIStore;
}

export const HeroWithImage = inject("uiStore")(
  observer(({ section }: Props) => {
    const { imageOrVideoGroup, content, topText, contentPlacement } = section;

    return (
      <motion.section
        data-sectionname="HeroWithImage"
        viewport={{ margin: "-50% -50%" }}
        className={contentPlacement ? style.outsideText : ""}
      >
        <Container fluid className="bigger-container-exception">
          {topText && (
            <Row>
              <Col>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={fadeInItem}
                  custom={0.6}
                  className={style.topText}
                  dangerouslySetInnerHTML={{ __html: topText }}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <figure className={style.figure}>
                <motion.div
                  // initial="hidden"
                  // whileInView="visible"
                  // viewport={{ once: true }}
                  // variants={imageHolderAnimationVariants}
                  className={style.imageAnimationHolder}
                >
                  <motion.div
                  // initial="hidden"
                  // whileInView="visible"
                  // viewport={{ once: true }}
                  // variants={imageAnimationVariants}
                  >
                    {imageOrVideoGroup.imageOrVideoSelect === "image" && (
                      <div>
                        <GatsbyImage
                          image={imageOrVideoGroup.image?.localFile.childImageSharp.image}
                          style={{ width: "100%" }}
                          alt={imageOrVideoGroup.image?.caption}
                        />
                      </div>
                    )}
                    {imageOrVideoGroup.imageOrVideoSelect !== "image" && (
                      <div className={`${style.imageHolder}`}>
                        <div className={`${style.imageHolder}`}>
                          <VideoElement
                            url={imageOrVideoGroup.videoUrl}
                            controls={imageOrVideoGroup.videoSettings.controls}
                            loop={imageOrVideoGroup.videoSettings.loop}
                            customControls={
                              imageOrVideoGroup.videoSettings.customControls
                            }
                            isDark={imageOrVideoGroup.videoSettings.customControlsTheme}
                            freeAspectRatio={true}
                          />
                        </div>
                      </div>
                    )}
                  </motion.div>
                </motion.div>
                <figcaption>
                  <motion.p
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    dangerouslySetInnerHTML={{ __html: content }}
                    variants={fadeInItem}
                    custom={0.6}
                  />
                </figcaption>
              </figure>
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
