import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import { inject, observer } from "mobx-react";

import { IScrollVideo } from "~models";
import { UIStore } from "~stores";

import * as style from "./ScrollVideo.module.scss";

interface Props {
  section?: IScrollVideo;
  uiStore?: UIStore;
}

export const ScrollVideo = inject("uiStore")(
  observer(({ uiStore }: Props) => {
    const [holderTop, setHolderTop] = useState(0);
    const [innerHeight, setInnerHeigt] = useState(0);

    const holderRef = useRef(null);
    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);

    const changeBgColor = (bg, text) => {
      uiStore.setBackgroundColor(bg);
      uiStore.setContentColor(text);
    };

    const { scrollY } = useScroll();
    const sceneTop = useTransform(
      scrollY,
      [holderTop, holderTop + innerHeight],
      [-1 * innerHeight, 0]
    );

    useEffect(() => {
      const holderElement = holderRef?.current;
      setHolderTop(holderElement.offsetTop);
      setInnerHeigt(holderElement.offsetHeight / 2);
      if (!holderElement) return;
      const holderElementObserver = new ResizeObserver(() => {
        setHolderTop(holderElement.offsetTop);
        setInnerHeigt(holderElement.offsetHeight / 2);
      });
      holderElementObserver.observe(holderElement);
      setTimeout(() => {
        setHolderTop(holderElement.offsetTop);
        setInnerHeigt(holderElement.offsetHeight / 2);
      });
      setTimeout(() => {
        setHolderTop(holderElement.offsetTop);
        setInnerHeigt(holderElement.offsetHeight / 2);
      }, 500);
      setTimeout(() => {
        videoRef1.current.play();
        videoRef2.current.play();
      }, 3000);
      return () => {
        holderElementObserver.disconnect();
      };
    }, []);

    return (
      <section data-sectionname="ParallaxFigure" className={style.parallaxFigure}>
        <div className={style.container} ref={holderRef}>
          <motion.div
            className={style.firstBgHelper}
            onViewportEnter={() => changeBgColor("#ffffff", "#1e1e1e")}
          />
          <motion.div
            className={style.secondBgHelper}
            onViewportEnter={() => changeBgColor("#1e1e1e", "#ffffff")}
          />
          <div className={style.cardContent} key={1}>
            <div className={style.cardContentInnerHolder}>
              <video
                controls={false}
                muted={true}
                loop={true}
                playsInline={true}
                ref={videoRef1}
              >
                <source
                  src={
                    "https://player.vimeo.com/progressive_redirect/playback/855065620/rendition/1440p/file.mp4?loc=external&log_user=0&signature=bcb0f979c556bb464d0f096027c5d69ab4f1f258cf6c4892a5dc562451d2b1cf"
                  }
                />
              </video>
            </div>
          </div>
          <div className={style.cardContent} key={2}>
            <motion.div className={style.cardContentInnerHolder} style={{ y: sceneTop }}>
              <video
                controls={false}
                muted={true}
                loop={true}
                playsInline={true}
                ref={videoRef2}
              >
                <source
                  src={
                    "https://player.vimeo.com/progressive_redirect/playback/855065644/rendition/1440p/file.mp4?loc=external&log_user=0&signature=47e25d6a12c29fa71d50d21f482eff26c2720352f29c85bf4f45834001350b47"
                  }
                />
              </video>
            </motion.div>
          </div>
        </div>
      </section>
    );
  })
);
