import React, { Component } from "react";
import { graphql } from "gatsby";
import { ILocation, ISingleWordpressCase } from "~models";
import { withStore } from "~libs/mobx";
import { CaseTemplate } from "~views/templates";
import "~libs/graphql";
import { inject, observer } from "mobx-react";
import { Seo } from "~views/shared/components";
import { Footer, Header } from "~views/layouts";
import { NavigationStore, UIStore, ContextStore } from "~stores";

interface Props {
  caseContext: any;
  location?: ILocation;
  data: {
    wpCase: ISingleWordpressCase;
  };
  contextStore: ContextStore;
  navigationStore?: NavigationStore;
  uiStore?: UIStore;
}

@inject("contextStore", "navigationStore", "uiStore")
@observer
class CasePage extends Component<Props> {
  constructor(props) {
    super(props);
    this.props.uiStore.setBackgroundColor(
      this.props.data.wpCase.caseFields.caseSettings.bgColor
    );
    this.props.uiStore.setDefaultBackgroundColor(
      this.props.data.wpCase.caseFields.caseSettings.bgColor || "#ffffff"
    );
  }

  componentDidMount() {
    const { caseContext, contextStore, navigationStore, data, uiStore } = this.props;

    const { initNavigationStore } = navigationStore;
    contextStore.context = caseContext;
    initNavigationStore(location);

    uiStore.setDefaultHeaderTheme(
      data.wpCase.caseFields.caseSettings.headerTemplate.toLowerCase() === "dark"
        ? "dark"
        : "light" || "dark"
    );
    uiStore.setHeaderTheme(
      data.wpCase.caseFields.caseSettings.headerTemplate.toLowerCase() === "dark"
        ? "dark"
        : "light" || "dark"
    );

    setTimeout(() => {
      if (typeof window !== "undefined") {
        window.scrollTo(window.scrollX, window.scrollY + 1);
        window.document.body.classList.remove("overflow_hidden");
      }
    });
  }

  renderTemplate() {
    const { sections, caseHeader, portal, upperFooter, caseSettings } =
      this.props.data.wpCase.caseFields;

    return (
      <CaseTemplate
        bgColor={this.props.uiStore.backgroundColor}
        template={"page_builder"}
        sections={[caseHeader, ...sections, portal, upperFooter]}
      />
    );
  }

  render() {
    const { data, location } = this.props;
    const { wpCase } = data;
    const {
      title,
      seo,
      caseFields: {
        caseSettings: { headerTemplate },
      },
    } = wpCase;
    const { origin } = location;

    return (
      <>
        <Seo seo={seo} pageTitle={title} origin={origin} />
        <Header />
        {this.renderTemplate()}
        <Footer showBottomRowInFooter={true} theme={"dark"} />
      </>
    );
  }
}

export default withStore(CasePage);

export const pageQuery = graphql`
  query ($id: String!) {
    wpCase(id: { eq: $id }) {
      ...singleWpCaseFragment
    }
  }
`;
