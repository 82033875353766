// extracted by mini-css-extract-plugin
export var arrow = "GifAndTextScatter-module--arrow--e484d";
export var arrow_no = "GifAndTextScatter-module--arrow_no--855a7";
export var arrow_yes = "GifAndTextScatter-module--arrow_yes--5fb4e";
export var cardHolder = "GifAndTextScatter-module--card-holder--84fa1";
export var cardRow = "GifAndTextScatter-module--card-row--cfb2b";
export var flex = "GifAndTextScatter-module--flex--07a5d";
export var flex_1 = "GifAndTextScatter-module--flex_1--ebb07";
export var flex_center = "GifAndTextScatter-module--flex_center--31d5a";
export var flex_center_col = "GifAndTextScatter-module--flex_center_col--7ee9a";
export var flex_col = "GifAndTextScatter-module--flex_col--a1fee";
export var flex_column = "GifAndTextScatter-module--flex_column--473fa";
export var flex_fix_center_col = "GifAndTextScatter-module--flex_fix_center_col--72c52";
export var flex_fixed_center_col = "GifAndTextScatter-module--flex_fixed_center_col--a720e";
export var flex_row = "GifAndTextScatter-module--flex_row--988b9";
export var gif = "GifAndTextScatter-module--gif--10f98";
export var imageAnimationHolder = "GifAndTextScatter-module--image-animation-holder--88b8a";
export var linkAnimation = "GifAndTextScatter-module--link-animation--d19b8";
export var rotating = "GifAndTextScatter-module--rotating--bdda4";
export var swiperWrapper = "GifAndTextScatter-module--swiper-wrapper--619ae";