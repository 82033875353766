// extracted by mini-css-extract-plugin
export var active = "styles-module--active--bdc1c";
export var arrow = "styles-module--arrow--4f2df";
export var arrow_no = "styles-module--arrow_no--4c40c";
export var arrow_yes = "styles-module--arrow_yes--da75f";
export var clickable = "styles-module--clickable--c61c1";
export var flex = "styles-module--flex--63dcc";
export var flex_1 = "styles-module--flex_1--1cdf4";
export var flex_center = "styles-module--flex_center--d00d5";
export var flex_center_col = "styles-module--flex_center_col--46755";
export var flex_col = "styles-module--flex_col--b4809";
export var flex_column = "styles-module--flex_column--4bf7f";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--1ab18";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--4fc68";
export var flex_row = "styles-module--flex_row--884bc";
export var imageAnimationHolder = "styles-module--image-animation-holder--8149e";
export var label = "styles-module--label--4034f";
export var linkAnimation = "styles-module--link-animation--72a71";
export var rotating = "styles-module--rotating--8d2f9";
export var swiperWrapper = "styles-module--swiper-wrapper--37d0e";
export var white = "styles-module--white--82e1b";