import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";

import { UIStore } from "~stores";
import { IImage } from "~models";
import { CustomVideoControls } from "../CustomVideoControls";

import * as s from "./styles.module.scss";

export interface VideoElementProps {
  url: string;
  poster?: IImage;
  freeAspectRatio?: boolean;
  type?: "vimeo" | "mp4" | "youtube";
  controls?: boolean;
  loop?: boolean;
  customControls?: boolean;
  isDark?: boolean;
  uiStore?: UIStore;
}

export const VideoElement = inject("uiStore")(
  observer(
    ({
      url,
      poster,
      freeAspectRatio,
      controls,
      loop,
      customControls,
      uiStore,
      isDark,
    }: VideoElementProps) => {
      const { breakpoint } = uiStore;
      const [isPlaying, setPlaying] = useState(true);
      const [progressBar, setProgressBar] = useState(0);
      const [playPause, setPlayPause] = useState(true);
      const [videoCurrentTime, setVideoCurrentTime] = useState(0);
      const [showCustomControls, setShowCustomControls] = useState(false);
      const [isMuted, setIsMuted] = useState(true);
      // const [autoplay, setAutoplay] = useState(true);

      const videoElementRef = useRef(null);

      const updateProgressBar = () => {
        setProgressBar(
          (videoElementRef.current.currentTime / videoElementRef.current.duration) * 10000
        );
        setVideoCurrentTime(videoElementRef.current.currentTime);
      };
      const handleSeekChange = (e) => {
        e.preventDefault();
        videoElementRef.current.currentTime =
          (e.target.value * videoElementRef.current.duration) / 10000;
      };
      const showHideMobileCustomControls = () => {
        if (breakpoint === "sm" || breakpoint === "md") {
          setShowCustomControls(!showCustomControls);
        }
      };

      useEffect(() => {
        if (showCustomControls) {
          setTimeout(function () {
            breakpoint !== "sm" && breakpoint !== "md"
              ? setShowCustomControls(false)
              : null;
          }, 2000);
        }

        window.addEventListener("scroll", function () {
          if (!showCustomControls) {
            setShowCustomControls(true);
          }
        });
      }, [showCustomControls]);
      useEffect(() => {
        breakpoint === "sm" || breakpoint === "md" ? setShowCustomControls(true) : null;
      }, [breakpoint]);

      const pauseVideo = (e) => {
        e.stopPropagation();
        videoElementRef.current.pause();
        setPlayPause(false);
      };

      const playVideo = (e) => {
        e.stopPropagation();
        videoElementRef.current.play();
        setPlaying(false);
        setPlayPause(true);
      };

      const videoOnPlay = () => {
        setPlaying(true);
      };

      const videoOnPause = () => {
        setPlaying(false);
      };

      const videoOnFinish = () => {
        setPlaying(false);
      };

      // useEffect(() => {
      //   // NOTE: Chrome play bug
      //   setTimeout(() => {
      //     if (
      //       videoElementRef &&
      //       videoElementRef.current &&
      //       videoElementRef.current.autoplay &&
      //       videoElementRef.current.paused
      //     ) {
      //       videoElementRef.current.play();
      //     }
      //   });
      // }, []);

      return (
        <div
          className={`${freeAspectRatio ? "" : s.withAspectRatio} ${s.videoHolder}`}
          onMouseMove={() =>
            breakpoint !== "sm" && breakpoint !== "md"
              ? setShowCustomControls(true)
              : null
          }
          onMouseLeave={() =>
            breakpoint !== "sm" && breakpoint !== "md"
              ? setShowCustomControls(false)
              : null
          }
          onClick={() => showHideMobileCustomControls()}
        >
          <video
            ref={videoElementRef}
            poster={poster?.localFile?.childImageSharp.image.images.fallback.src || null}
            controls={controls}
            onPlay={() => videoOnPlay()}
            onPause={() => videoOnPause()}
            onEnded={() => videoOnFinish()}
            autoPlay={true}
            muted={isMuted}
            loop={loop}
            width={"100%"}
            height={"100%"}
            playsInline={true}
            onTimeUpdate={updateProgressBar}
          >
            <source src={url} />
          </video>
          {isPlaying ? null : (
            <button className={`${s.videoPlayButton}`} onClick={(e) => playVideo(e)}>
              <i className={"icon-play"} />
            </button>
          )}
          {customControls && (
            <div
              className={`
                ${s.videoHolderCustomControlsHolder}
                ${showCustomControls ? s.videoHolderCustomControlsHolderShow : ""}
                ${isDark ? s.dark : ""}`}
            >
              <CustomVideoControls
                playPause={playPause}
                playVideo={playVideo}
                pauseVideo={pauseVideo}
                handleSeekChange={handleSeekChange}
                progressBar={progressBar}
                videoCurrentTime={videoCurrentTime}
                isMuted={isMuted}
                isDark={isDark ? isDark : false}
                setIsMuted={setIsMuted}
              />
            </div>
          )}
        </div>
      );
    }
  )
);
