// extracted by mini-css-extract-plugin
export var arrow = "HeroWithImage-module--arrow--f5a80";
export var arrow_no = "HeroWithImage-module--arrow_no--7bdc6";
export var arrow_yes = "HeroWithImage-module--arrow_yes--84ae2";
export var figure = "HeroWithImage-module--figure--b48cd";
export var flex = "HeroWithImage-module--flex--f4991";
export var flex_1 = "HeroWithImage-module--flex_1--ac8f2";
export var flex_center = "HeroWithImage-module--flex_center--bf4cb";
export var flex_center_col = "HeroWithImage-module--flex_center_col--825f3";
export var flex_col = "HeroWithImage-module--flex_col--35f6b";
export var flex_column = "HeroWithImage-module--flex_column--2a9d1";
export var flex_fix_center_col = "HeroWithImage-module--flex_fix_center_col--ebb66";
export var flex_fixed_center_col = "HeroWithImage-module--flex_fixed_center_col--67a78";
export var flex_row = "HeroWithImage-module--flex_row--41863";
export var imageAnimationHolder = "HeroWithImage-module--image-animation-holder--492ce";
export var linkAnimation = "HeroWithImage-module--link-animation--6be88";
export var outsideText = "HeroWithImage-module--outside-text--f77d6";
export var rotating = "HeroWithImage-module--rotating--7433f";
export var swiperWrapper = "HeroWithImage-module--swiper-wrapper--62043";
export var topText = "HeroWithImage-module--top-text--f8264";