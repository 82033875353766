import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import { Container, Row, Col, Button } from "~components";
import { inject, observer } from "mobx-react";
import { UIStore } from "~stores";
import { ITextAndImageScatter } from "~models";
import {
  imageAnimationVariants,
  imageHolderAnimationVariants,
  horizontalTitleHolderAnimationVariants,
  horizontalTitleAnimationVariants,
  fadeInItem,
} from "~libs/framer/variants";
import { ILink } from "~models/Link";

import * as style from "./TextAndImageScatter.module.scss";
import { Icons } from "~libs/assets";

interface Props {
  section: ITextAndImageScatter;
  uiStore?: UIStore;
}

const ContentElement = ({
  text,
  button,
  absolute,
  color,
}: {
  text: string;
  button: ILink;
  absolute: boolean;
  color: string;
}) => (
  <div className={`${style.content} ${absolute ? style.absolute : ""}`}>
    <motion.p
      variants={fadeInItem}
      custom={0}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      dangerouslySetInnerHTML={{ __html: text }}
      style={{ color: color }}
    />
    {button.title && (
      <Link to={button.url}>
        <Button icon={"icon-arrow-right"} filled={color === "#ffffff"}>
          {button.title}
        </Button>
      </Link>
    )}
  </div>
);

const TitleElement = ({
  title,
  mobileTitle,
  contentColor,
  isCentered,
  isMobile,
}: {
  title: string;
  mobileTitle: string;
  contentColor;
  isCentered: boolean;
  isMobile: boolean;
}) => (
  <div className={`${style.titleHolder} ${isCentered ? style.centered : ""}`}>
    <motion.h1
      variants={horizontalTitleHolderAnimationVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.8 }}
      className={style.title}
      style={{ color: contentColor }}
    >
      {isMobile
        ? mobileTitle
            .split("|")
            .map((t, index) =>
              t === "ever-icon" ? (
                <motion.img
                  variants={horizontalTitleAnimationVariants}
                  key={index}
                  custom={index}
                  src={Icons.ever}
                />
              ) : (
                <motion.span
                  variants={horizontalTitleAnimationVariants}
                  key={index}
                  custom={index}
                  dangerouslySetInnerHTML={{ __html: t }}
                  style={{ color: contentColor }}
                />
              )
            )
        : title
            .split("|")
            .map((t, index) =>
              t === "ever-icon" ? (
                <motion.img
                  variants={horizontalTitleAnimationVariants}
                  key={index}
                  custom={index}
                  src={Icons.ever}
                />
              ) : (
                <motion.span
                  variants={horizontalTitleAnimationVariants}
                  key={index}
                  custom={index}
                  dangerouslySetInnerHTML={{ __html: t }}
                  style={{ color: contentColor }}
                />
              )
            )}
    </motion.h1>
  </div>
);

export const TextAndImageScatter = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const {
      title,
      titleMobile,
      styleKey,
      content,
      button,
      firstFigure,
      secondFigure,
      transition,
    } = section;

    const { contentColor, windowWidth } = uiStore;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="TextAndImageScatter"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
        className={`${content ? "" : style.noContent}`}
      >
        <Container fluid>
          <Row>
            <Col>
              <div
                className={`${style.main} ${
                  styleKey === "centered" ? style.centered : ""
                }`}
              >
                {content && (
                  <ContentElement
                    text={content}
                    button={button}
                    absolute={styleKey === "right-aligned"}
                    color={transition.transitionTextColor}
                  />
                )}
                <TitleElement
                  title={title}
                  mobileTitle={titleMobile}
                  contentColor={contentColor}
                  isCentered={styleKey === "centered"}
                  isMobile={windowWidth < 540}
                />
                <div
                  className={`${style.imageHolder} ${content ? style.withContent : ""}`}
                >
                  <motion.div
                    variants={imageHolderAnimationVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    className={style.imageAnimationHolder}
                  >
                    <motion.div
                      variants={imageAnimationVariants}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                    >
                      <GatsbyImage
                        image={firstFigure?.localFile?.childImageSharp?.image}
                        style={{ width: "100%" }}
                        alt={firstFigure?.caption || ""}
                      />
                    </motion.div>
                  </motion.div>
                  <motion.div
                    variants={imageHolderAnimationVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    className={style.imageAnimationHolder}
                  >
                    <motion.div
                      variants={imageAnimationVariants}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                    >
                      <GatsbyImage
                        image={secondFigure?.localFile?.childImageSharp?.image}
                        style={{ width: "100%" }}
                        alt={secondFigure?.caption || ""}
                      />
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
