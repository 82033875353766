// extracted by mini-css-extract-plugin
export var arrow = "PartnerLogos-module--arrow--66f18";
export var arrow_no = "PartnerLogos-module--arrow_no--a3492";
export var arrow_yes = "PartnerLogos-module--arrow_yes--62271";
export var flex = "PartnerLogos-module--flex--6715a";
export var flex_1 = "PartnerLogos-module--flex_1--876de";
export var flex_center = "PartnerLogos-module--flex_center--8b88d";
export var flex_center_col = "PartnerLogos-module--flex_center_col--1aa7f";
export var flex_col = "PartnerLogos-module--flex_col--09621";
export var flex_column = "PartnerLogos-module--flex_column--2ef17";
export var flex_fix_center_col = "PartnerLogos-module--flex_fix_center_col--6cf41";
export var flex_fixed_center_col = "PartnerLogos-module--flex_fixed_center_col--e8771";
export var flex_row = "PartnerLogos-module--flex_row--52350";
export var imageAnimationHolder = "PartnerLogos-module--image-animation-holder--13705";
export var linkAnimation = "PartnerLogos-module--link-animation--23f0c";
export var logo = "PartnerLogos-module--logo--29939";
export var logoHolder = "PartnerLogos-module--logo-holder--d91b8";
export var rotating = "PartnerLogos-module--rotating--4db21";
export var swiperWrapper = "PartnerLogos-module--swiper-wrapper--d1ca8";