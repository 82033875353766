// extracted by mini-css-extract-plugin
export var active = "LanguageSwitcher-module--active--d8208";
export var arrow = "LanguageSwitcher-module--arrow--05388";
export var arrow_no = "LanguageSwitcher-module--arrow_no--0f409";
export var arrow_yes = "LanguageSwitcher-module--arrow_yes--f88d3";
export var flex = "LanguageSwitcher-module--flex--c7efd";
export var flex_1 = "LanguageSwitcher-module--flex_1--1b42b";
export var flex_center = "LanguageSwitcher-module--flex_center--97476";
export var flex_center_col = "LanguageSwitcher-module--flex_center_col--28e61";
export var flex_col = "LanguageSwitcher-module--flex_col--f3bb1";
export var flex_column = "LanguageSwitcher-module--flex_column--0f21a";
export var flex_fix_center_col = "LanguageSwitcher-module--flex_fix_center_col--554d4";
export var flex_fixed_center_col = "LanguageSwitcher-module--flex_fixed_center_col--bd4e3";
export var flex_row = "LanguageSwitcher-module--flex_row--f9b69";
export var imageAnimationHolder = "LanguageSwitcher-module--image-animation-holder--ca547";
export var indicator = "LanguageSwitcher-module--indicator--8d677";
export var languageSwitcher = "LanguageSwitcher-module--language-switcher--9ab6a";
export var languageSwitcherHolder = "LanguageSwitcher-module--language-switcher-holder--628b7";
export var light = "LanguageSwitcher-module--light--c4407";
export var linkAnimation = "LanguageSwitcher-module--link-animation--47d30";
export var rotating = "LanguageSwitcher-module--rotating--13e3d";
export var second = "LanguageSwitcher-module--second--56b10";
export var sw = "LanguageSwitcher-module--sw--861e8";
export var swh = "LanguageSwitcher-module--swh--74281";
export var swiperWrapper = "LanguageSwitcher-module--swiper-wrapper--5e60d";