import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { inject, observer } from "mobx-react";
import { motion, AnimatePresence } from "framer-motion";
import scrollTo from "gatsby-plugin-smoothscroll";

import { VideoElement, Button, Container, Row, Col } from "~components";
import { UIStore } from "~stores";
import { fadeInItem, staggerChildren, staggerChildrenItem } from "~libs/framer/variants";
import { tx } from "~libs/i18n";
import { ILatestItem } from "~models/PostTypes";

import * as style from "./styles.module.scss";

export interface InsightHeroProps {
  uiStore?: UIStore;
  featuredLatests: ILatestItem[];
}

export const InsightHero = inject("uiStore")(
  observer(({ featuredLatests, uiStore }: InsightHeroProps) => {
    const { isEqualOrGreatherThanBreakpoint } = uiStore;
    const [index, setIndex] = useState(0);

    useEffect(() => {
      const id = setTimeout(
        () => {
          setIndex((index + 1) % featuredLatests.length);
        },
        isEqualOrGreatherThanBreakpoint("lg") ? 8000 : 5000
      );
      return () => {
        clearInterval(id);
      };
    }, [index]);
    const featuredLatest = featuredLatests[index];
    const classes = [style.insightHero];

    useEffect(() => {
      if (window.location.href.includes("#") && document.getElementById("isAnchored")) {
        setTimeout(() => {
          scrollTo("#isAnchored");
        }, 700);
      }
    }, []);

    const calculateReadTime = () => {
      const sections = featuredLatest.latestFields.sections.filter((section) => {
        if (Object.keys(section).length !== 0) {
          return section;
        }
      });

      const time = sections.map((section) => {
        const highlightedContent = section.highlightedContent.length;
        const content = section.content.length;
        const titleAndTextList = section.titleAndTextList.map((textElement) => {
          const title = textElement.title.length;
          const text = textElement.text.length;

          return title + text;
        });

        const titleAndTextListSum = titleAndTextList.reduce(
          (partialSum, a) => partialSum + a,
          0
        );

        return highlightedContent + content + titleAndTextListSum;
      });

      const totalChar = time.reduce((partialSum, a) => partialSum + a, 0);
      const words = totalChar / 5;
      const avrgWordsPerMinute = 180;
      return Math.round(words / avrgWordsPerMinute);
    };

    return (
      <div
        className={classes.join(" ")}
        style={{
          backgroundColor: !isEqualOrGreatherThanBreakpoint("md")
            ? featuredLatest.latestFields.latestSettings.backgroundColor
            : null,
        }}
      >
        <AnimatePresence exitBeforeEnter>
          {isEqualOrGreatherThanBreakpoint("md") && (
            <motion.div
              exit={{ opacity: 0 }}
              key={index}
              initial="hidden"
              whileInView="visible"
              variants={fadeInItem}
              custom={0.3}
              className={style.desktopVideoWrap}
            >
              <VideoElement
                url={featuredLatest.latestFields.latestSettings.featuredVideo.videoUrl}
                // poster={
                //   featuredLatest.latestFields.latestSettings.featuredVideo.videoPoster ??
                //   null
                // }
                controls={false}
                loop={false}
                freeAspectRatio={true}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <Container fluid>
          <Row className={style.contentHolder}>
            <Col xl={6} lg={5} md={6} sm={12}>
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  variants={staggerChildren}
                  exit={{ opacity: 0 }}
                  key={index}
                >
                  <motion.h5 variants={staggerChildrenItem} className={style.label}>
                    LATEST WHITE PAPER
                  </motion.h5>
                  <motion.h2
                    dangerouslySetInnerHTML={{
                      __html: featuredLatest.latestFields.latestSettings.title,
                    }}
                    variants={staggerChildrenItem}
                  />
                  {isEqualOrGreatherThanBreakpoint("lg") && (
                    <motion.p
                      variants={staggerChildrenItem}
                      className={style.description}
                    >
                      {featuredLatest.latestFields.latestSettings.description}
                    </motion.p>
                  )}
                  <motion.span
                    variants={staggerChildrenItem}
                    className={style.readMoreWrapper}
                  >
                    <Link to={featuredLatest.uri} className={style.readMore}>
                      <Button icon="icon-arrow-right" secondary withAnimation={false}>
                        READ MORE
                      </Button>
                    </Link>
                  </motion.span>
                </motion.div>
              </AnimatePresence>
            </Col>
          </Row>
        </Container>
        {!isEqualOrGreatherThanBreakpoint("md") && (
          <AnimatePresence exitBeforeEnter>
            <motion.div
              exit={{ opacity: 0 }}
              key={index}
              initial="hidden"
              whileInView="visible"
              variants={fadeInItem}
              custom={0.3}
            >
              <VideoElement
                url={
                  featuredLatest.latestFields.latestSettings.imageOrVideoGroup.videoUrl
                }
                // poster={
                //   featuredLatest.latestFields.latestSettings.featuredVideo.videoPoster ??
                //   null
                // }
                controls={false}
                loop={false}
                freeAspectRatio={true}
              />
            </motion.div>
          </AnimatePresence>
        )}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            exit={{ opacity: 0 }}
            key={index + 100}
            initial="hidden"
            whileInView="visible"
            variants={fadeInItem}
            custom={0.5}
            className={`${style.bottomInfo} ${style.navigation}`}
          >
            <button
              onClick={() => {
                setIndex((index + featuredLatests.length - 1) % featuredLatests.length);
              }}
            >
              <span>previous</span>
            </button>
            <span className={style.navigationCounter}>
              {index + 1} / {featuredLatests.length}
            </span>
            <button
              onClick={() => {
                setIndex((index + 1) % featuredLatests.length);
              }}
            >
              <span>next</span>
            </button>
          </motion.div>

          <motion.div
            exit={{ opacity: 0 }}
            key={index}
            initial="hidden"
            whileInView="visible"
            variants={fadeInItem}
            custom={0.5}
            className={`${style.bottomInfo} ${style.readTime}`}
          >
            <p>{`${calculateReadTime()} ${tx("insights.readTime")}`}</p>
          </motion.div>
        </AnimatePresence>
      </div>
    );
  })
);
