import {
  ContextStore,
  NavigationStore,
  SectionsContainerStore,
  UIStore,
  FilterStore,
} from "~stores";

let store = null;

export class Store {
  uiStore: UIStore;
  contextStore: ContextStore;
  sectionsContainerStore: SectionsContainerStore;
  navigationStore: NavigationStore;
  filterStore: FilterStore;

  constructor() {
    this.uiStore = new UIStore();
    this.contextStore = new ContextStore();
    this.sectionsContainerStore = new SectionsContainerStore(this);
    this.navigationStore = new NavigationStore(this);
    this.filterStore = new FilterStore(this);
  }

  onMount = () => {
    this.uiStore.onMount();
  };

  onUnmount = () => {
    this.uiStore.onUnmount();
  };
}

export const initStore = () => {
  store = store != null ? store : new Store();
  return store;
};
