// extracted by mini-css-extract-plugin
export var arrow = "SectionsContainer-module--arrow--ba1e0";
export var arrow_no = "SectionsContainer-module--arrow_no--3d7e2";
export var arrow_yes = "SectionsContainer-module--arrow_yes--b8788";
export var flex = "SectionsContainer-module--flex--9d80f";
export var flex_1 = "SectionsContainer-module--flex_1--22358";
export var flex_center = "SectionsContainer-module--flex_center--47eba";
export var flex_center_col = "SectionsContainer-module--flex_center_col--e378d";
export var flex_col = "SectionsContainer-module--flex_col--6c8af";
export var flex_column = "SectionsContainer-module--flex_column--42ee5";
export var flex_fix_center_col = "SectionsContainer-module--flex_fix_center_col--d9d69";
export var flex_fixed_center_col = "SectionsContainer-module--flex_fixed_center_col--37828";
export var flex_row = "SectionsContainer-module--flex_row--a5aa4";
export var imageAnimationHolder = "SectionsContainer-module--image-animation-holder--af1c9";
export var linkAnimation = "SectionsContainer-module--link-animation--7a27b";
export var rotating = "SectionsContainer-module--rotating--52149";
export var sectionsContainer = "SectionsContainer-module--sectionsContainer--32167";
export var swiperWrapper = "SectionsContainer-module--swiper-wrapper--0cf96";