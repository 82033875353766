import { withPrefix } from "gatsby";

export const Icons: { [key: string]: string } = {
  arrow_down: withPrefix("/icons/arrow-down.svg"),
  arrow_left: withPrefix("/icons/arrow-left.svg"),
  arrow_right: withPrefix("/icons/arrow-right.svg"),
  arrow_up_right: withPrefix("/icons/arrow-up-right.svg"),
  cross: withPrefix("/icons/cross.svg"),
  menu: withPrefix("/icons/menu.svg"),
  minus_slim: withPrefix("/icons/minus-slim.svg"),
  minus: withPrefix("/icons/minus.svg"),
  play: withPrefix("/icons/play.svg"),
  plus_slim: withPrefix("/icons/plus-slim.svg"),
  plus: withPrefix("/icons/plus.svg"),
  favicon: withPrefix("/icons/essen_favicon_32x32.png"),
  ever: withPrefix("/icons/ever-calligraphy.png"),
};
