import React from "react";

import { motion, useAnimation } from "framer-motion";

import * as styles from "./styles.module.scss";

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  styleKey?: string;
  disabled?: boolean;
  filled?: boolean;
  dark?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  small?: boolean;
  withAnimation?: boolean;
  icon?:
    | "icon-arrow-down"
    | "icon-arrow-left"
    | "icon-arrow-right"
    | "icon-arrow-up-right"
    | "icon-plus"
    | "icon-minus";
}

export const Button = ({
  styleKey,
  disabled,
  filled,
  dark,
  secondary,
  tertiary,
  small,
  icon,
  onClick,
  className,
  withAnimation = true,
  ...rest
}: ButtonProps) => {
  const classes = [styles.button];
  const iconAnimation = useAnimation();

  const buttonAnimationVariants = {
    hidden: {
      opacity: 0,
      scale: 0.98,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  const textAnimationVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  const firstIconAnimationVariants = {
    firstIconHidden: (i) => ({
      opacity: 0,
      left: i === "icon-arrow-up-right" ? -20 : i === "icon-arrow-right" ? -20 : 0,
      top: i === "icon-arrow-up-right" ? 20 : i === "icon-arrow-down" ? -20 : 0,
      rotate: 0,
    }),
    firstIconVisible: (i) => ({
      opacity: i === "icon-plus" ? 0 : 1,
      left: 0,
      top: 0,
      rotate: 0,
    }),
    firstIconHover: (i) => ({
      opacity: 0,
      left: i === "icon-arrow-up-right" ? 20 : i === "icon-arrow-right" ? 20 : 0,
      top: i === "icon-arrow-up-right" ? -20 : i === "icon-arrow-down" ? 20 : 0,
      rotate: i === "icon-plus" ? 90 : 0,
    }),
  };
  const secondIconAnimationVariants = {
    secondIconHidden: (i) => ({
      opacity: i === "icon-plus" ? 1 : 0,
      left: i === "icon-arrow-up-right" ? -20 : i === "icon-arrow-right" ? -20 : 0,
      top:
        i === "icon-arrow-up-right"
          ? 20
          : i === "icon-arrow-down"
          ? -20
          : i === "icon-plus"
          ? -2
          : 0,
      rotate: 0,
    }),
    secondIconVisible: (i) => ({
      opacity: 1,
      left: 0,
      top: i === "icon-plus" ? -2 : 0,
      rotate: i === "icon-plus" ? 90 : 0,
    }),
    secondIconHover: (i) => ({
      opacity: i === "icon-plus" ? 1 : 0,
      left: i === "icon-arrow-up-right" ? 20 : i === "icon-arrow-right" ? 20 : 0,
      top:
        i === "icon-arrow-up-right"
          ? -20
          : i === "icon-arrow-down"
          ? 20
          : i === "icon-plus"
          ? -2
          : 0,
      rotate: 0,
    }),
  };

  const buttonOnHoverStart = () => {
    iconAnimation.start("firstIconHover");
    iconAnimation.start("secondIconVisible");
    setTimeout(() => {
      iconAnimation.start("firstIconHidden");
    }, 300);
  };

  const buttonOnHoverEnd = () => {
    iconAnimation.start("secondIconHover");
    iconAnimation.start("firstIconVisible");
    setTimeout(() => {
      iconAnimation.start("secondIconHidden");
      iconAnimation.start("firstIconVisible");
    }, 300);
  };

  if (className) {
    classes.push(className);
  }

  if (styleKey) {
    classes.push(styles[styleKey]);
  }

  if (disabled) {
    classes.push(styles.disabled);
  }

  if (filled) {
    classes.push(styles.filled);
  }

  if (small) {
    classes.push(styles.small);
  }

  if (dark) {
    classes.push(styles.dark);
  }

  if (secondary) {
    classes.push(styles.secondary);
  }

  if (tertiary) {
    classes.push(styles.tertiary);
  }

  return (
    <motion.button
      initial={withAnimation ? "hidden" : "visible"}
      whileInView="visible"
      viewport={{ once: true }}
      variants={withAnimation ? buttonAnimationVariants : null}
      onClick={disabled ? undefined : onClick}
      className={classes.join(" ")}
      onHoverStart={() => buttonOnHoverStart()}
      onHoverEnd={() => buttonOnHoverEnd()}
      onViewportEnter={() => {
        iconAnimation.start("firstIconVisible");
        iconAnimation.start("secondIconHidden");
      }}
      {...rest}
    >
      <motion.span
        className={styles.buttonContent}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={textAnimationVariants}
      >
        {rest.children}
      </motion.span>
      {icon && (
        <span className={styles.buttonIconHolder}>
          <motion.span
            initial={"firstIconHidden"}
            animate={iconAnimation}
            variants={firstIconAnimationVariants}
            className={styles.buttonIcon}
            custom={icon}
          >
            {icon ? <i className={icon}></i> : null}
          </motion.span>
          <motion.span
            initial={"secondIconHıdden"}
            animate={iconAnimation}
            variants={secondIconAnimationVariants}
            className={styles.buttonIcon}
            custom={icon}
          >
            {icon ? <i className={icon}></i> : null}
          </motion.span>
        </span>
      )}
    </motion.button>
  );
};
