// extracted by mini-css-extract-plugin
export var arrow = "DraggableCard-module--arrow--5751d";
export var arrow_no = "DraggableCard-module--arrow_no--298cb";
export var arrow_yes = "DraggableCard-module--arrow_yes--275d5";
export var draggableCard = "DraggableCard-module--draggable-card--181fa";
export var flex = "DraggableCard-module--flex--f5551";
export var flex_1 = "DraggableCard-module--flex_1--ace39";
export var flex_center = "DraggableCard-module--flex_center--ec547";
export var flex_center_col = "DraggableCard-module--flex_center_col--0c2ef";
export var flex_col = "DraggableCard-module--flex_col--8c543";
export var flex_column = "DraggableCard-module--flex_column--31df3";
export var flex_fix_center_col = "DraggableCard-module--flex_fix_center_col--e2a5d";
export var flex_fixed_center_col = "DraggableCard-module--flex_fixed_center_col--71287";
export var flex_row = "DraggableCard-module--flex_row--2232f";
export var imageAnimationHolder = "DraggableCard-module--image-animation-holder--1dc75";
export var imageHolder = "DraggableCard-module--image-holder--0a2a8";
export var linkAnimation = "DraggableCard-module--link-animation--4f8f4";
export var rotating = "DraggableCard-module--rotating--5d207";
export var swiperWrapper = "DraggableCard-module--swiper-wrapper--5c2ba";