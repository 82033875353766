import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { navigate } from "gatsby";

import { ContextStore, NavigationStore } from "~stores";
import { ILanguage, ITranslations } from "~models";

import * as styles from "./LanguageSwitcher.module.scss";
import { getCurrentLanguage } from "~libs/i18n";

interface Props {
  theme: "dark" | "light";
  contextStore?: ContextStore;
  navigationStore?: NavigationStore;
}

export const LanguageSwitcher = inject(
  "contextStore",
  "navigationStore"
)(
  observer(({ theme, contextStore, navigationStore }: Props) => {
    const [languages, setLanguages] = useState<ILanguage[]>([]);
    const [defaultLanguage, setDefaultLanguage] = useState<ILanguage>(null);
    const currentLanguage = getCurrentLanguage();
    const [pageTranslations, setPageTranslations] = useState<ITranslations[]>([]);
    const [switchAction, setSwitchAction] = useState<boolean>(false);
    const [switchHelper, setSwitchHelper] = useState<boolean>(false);

    useEffect(() => {
      setLanguages([...navigationStore.languages]);
      setDefaultLanguage(navigationStore.defaultLanguage);
      if (contextStore.data) {
        if (contextStore.data.wpPage && contextStore.data.wpPage.translations) {
          setPageTranslations([...contextStore.data.wpPage.translations]);
        }
        if (contextStore.data.wpInsight && contextStore.data.wpInsight.translations) {
          setPageTranslations([...contextStore.data.wpInsight.translations]);
        }
        if (contextStore.data.wpCase && contextStore.data.wpCase.translations) {
          setPageTranslations([...contextStore.data.wpCase.translations]);
        }
      }
      if (
        contextStore.renderedLanguage !== "" &&
        contextStore.renderedLanguage !== currentLanguage
      ) {
        setSwitchAction(true);
        setTimeout(() => {
          setSwitchHelper(true);
        });
      }

      contextStore.setRenderedLanguage(currentLanguage);
    }, []);

    const changeLanguage = (language: ILanguage) => {
      let baseUrl = language.slug === defaultLanguage.slug ? "/" : `/${language.slug}`;
      if (language.slug !== currentLanguage) {
        let url =
          pageTranslations.length &&
          pageTranslations
            .map((translation) => translation.language)
            .find((lang) => lang.slug === language.slug).slug === language.slug
            ? pageTranslations
                .find((translation) => translation.language.slug === language.slug)
                .uri.replace("/home/", "/")
                .replace("/home-sv/", "/")
            : baseUrl;
        navigate(url);
      }
    };

    return (
      <div className={styles.languageSwitcherHolder}>
        {languages.length ? (
          <>
            <ul
              className={`${theme === "light" ? styles.light : ""} ${
                styles.languageSwitcher
              }`}
            >
              {languages.map((language) => (
                <li
                  key={language.slug}
                  onClick={() => changeLanguage(language)}
                  className={`${currentLanguage === language.slug && styles.active}`}
                >
                  {language.code}
                </li>
              ))}
            </ul>
            <span
              className={`${styles.indicator} ${
                currentLanguage === "sv" ? styles.second : ""
              } ${switchAction ? styles.sw : ""} ${switchHelper ? styles.swh : ""}`}
            ></span>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  })
);
