// Image grouping properties
export const firstGroupSizes = [22, 21, 30, 34, 22, 21, 18, 30];
export const firstGroupSizesMobile = [45, 40, 45, 60, 40, 40, 42, 60];

export const secondGroupSizes = [22, 24, 18, 34, 30, 25, 19];
export const secondGroupSizesMobile = [45, 50, 35, 60, 50, 58, 45];

export const thirdGroupSizes = [30, 20, 35, 21, 20, 19, 35];
export const thirdGroupSizesMobile = [60, 40, 65, 38, 40, 40, 60];

export const firstRowGrouping = [
  [0, 1],
  [2, 4],
  [5, 7],
];
export const secondRowGrouping = [
  [0, 0],
  [1, 3],
  [4, 6],
];

// zIndex sequences
export const zIndexOne = [1, 3, 3, 1, 1, 1, 1, 3];
export const zIndexTwo = [1, 1, 3, 3, 1, 3, 1];
export const zIndexThree = [1, 3, 1, 1, 3, 3, 1];
