import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { motion } from "framer-motion";
import scrollTo from "gatsby-plugin-smoothscroll";

import { IPartnerPortfolio } from "~models";
import { Card, Col, Container, Label, Row } from "~components";
import { UIStore } from "~stores";
import { PartnerCitation, PartnerLogos } from "~shared/sections";
import { staggerChildren, staggerChildrenItem, fadeInItem } from "~libs/framer/variants";

import * as style from "./PartnerPortfolio.module.scss";

interface Props {
  section: IPartnerPortfolio;
  uiStore?: UIStore;
}

export const PartnerPortfolio = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const {
      sectionText,
      tags,
      citationsAndLogos,
      defaultCitationsAndLogos,
      transition,
      cases,
      startWithLandscape,
    } = section;

    const [activeTag, setActiveTag] = useState("ALL WORK");

    const tagClickHandle = (e) => {
      setActiveTag(e.target.getAttribute("data-name"));
    };

    const filterTags = (e) => {
      setActiveTag(e);
      scrollTo("#filterable-labels", "center");
    };

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };
    return (
      <>
        <motion.section
          data-sectionname="PartnerPortfolio"
          onViewportEnter={() => changeBackgroundColor()}
          viewport={{ margin: "-50% -50%" }}
        >
          <>
            <Container fluid>
              <Row>
                <Col>
                  <motion.h1
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={staggerChildren}
                    className={`${style.sectionText} h3`}
                  >
                    {sectionText?.split("<br/>").map((t, index) => (
                      <motion.span
                        variants={staggerChildrenItem}
                        custom={Number(index) + 1}
                        key={index}
                        dangerouslySetInnerHTML={{ __html: t }}
                      />
                    ))}
                  </motion.h1>
                </Col>
              </Row>
              <Row className={style.tagHolder}>
                <Col xl={12} md={10} sm={12}>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    id={"filterable-labels"}
                    variants={fadeInItem}
                  >
                    <Label
                      data-name="ALL WORK"
                      key={"all"}
                      text={"ALL WORK"}
                      isActive={activeTag === "ALL WORK"}
                      onClick={tagClickHandle}
                    />
                    {tags?.map(
                      ({ tag }, index) =>
                        tag.tagFields.tagName !== "no tag" && (
                          <Label
                            key={index}
                            data-name={tag.tagFields.tagName}
                            text={tag.tagFields.tagName}
                            isActive={activeTag === tag.tagFields.tagName}
                            onClick={tagClickHandle}
                          />
                        )
                    )}
                  </motion.div>
                </Col>
              </Row>
              <div className={style.portfolioHolder}>
                {cases.map((node, index) =>
                  (node?.caseFields?.casePortfolioFields?.text !== "" &&
                    [
                      ...node.caseFields.caseHeader.tag.map((e) => e.tagFields.tagName),
                    ].includes(activeTag)) ||
                  (node?.caseFields?.casePortfolioFields?.text !== "" &&
                    activeTag === "ALL WORK") ? (
                    <div
                      className={`${style.column}
                      ${
                        activeTag !== "ALL WORK"
                          ? style.allPortrait
                          : style.withLandscapeElements
                      }
                      ${!startWithLandscape && style.startWithPortrait}
                      `}
                      key={index}
                    >
                      <Card
                        key={index}
                        imageOrVideo={
                          node?.caseFields?.casePortfolioFields
                            .hasDifferentImageForFilter && activeTag !== "ALL WORK"
                            ? node?.caseFields?.casePortfolioFields
                                ?.secondaryImageOrVideoGroup
                            : node?.caseFields?.casePortfolioFields?.imageOrVideoGroup
                        }
                        title={node?.caseFields?.caseHeader?.partner}
                        description={node?.caseFields?.casePortfolioFields?.text}
                        link={{
                          target: null,
                          title: "",
                          url: node.caseFields.caseSettings.disableCaseLink
                            ? ""
                            : node?.uri,
                        }}
                        tag={
                          node.caseFields.caseHeader.tag.length
                            ? node.caseFields.caseHeader.tag.map(
                                (e) => e.tagFields.tagName
                              )
                            : [""]
                        }
                        imageAlign={
                          node?.caseFields?.casePortfolioFields?.imageOrVideoGroup
                            ?.imageAlign
                        }
                        clickableLabel={true}
                        clickLabelToExecute={(tag) => filterTags(tag)}
                      />
                    </div>
                  ) : null
                )}
              </div>
            </Container>
          </>
        </motion.section>
        {activeTag === "ALL WORK" && (
          <>
            {defaultCitationsAndLogos.citation && (
              <PartnerCitation section={defaultCitationsAndLogos.citation} />
            )}

            {defaultCitationsAndLogos.logos && (
              <PartnerLogos section={defaultCitationsAndLogos} />
            )}
          </>
        )}
        {citationsAndLogos?.map(({ tagContent }, index) => {
          return (
            tagContent.tag.tagFields.tagName === activeTag && (
              <div key={index}>
                <PartnerCitation section={tagContent.partnerCitation} />

                <PartnerLogos section={{ logos: tagContent.partnersLogos }} />
              </div>
            )
          );
        })}
      </>
    );
  })
);
