import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { inject, observer } from "mobx-react";
import { motion, useScroll, useTransform } from "framer-motion";

import { Container, Row, Col, Button } from "~components";
import { UIStore } from "~stores";

import * as style from "./CenteredLogoHeader.module.scss";
import { getCurrentLanguage } from "~libs/i18n";

interface Props {
  uiStore?: UIStore;
  toggleButtonTitle?: string;
}

export const CenteredLogoHeader = inject("uiStore")(
  observer(({ uiStore }: Props) => {
    const { windowWidth, windowHeight, isEqualOrGreatherThanBreakpoint } = uiStore;

    const [currentLanguage, setCurrentLanguage] = useState("");
    const [lastScrollTop, setLastScrollTop] = useState<number>(0);
    const [scrollUp, setScrollUp] = useState<boolean>(false);

    useEffect(() => {
      setCurrentLanguage(getCurrentLanguage());
    }, []);

    const { scrollY } = useScroll();

    const logoSlideWidth = useTransform(
      scrollY,
      [0, windowHeight / 2 - 100],
      [windowWidth, isEqualOrGreatherThanBreakpoint("lg") ? 120 : 100]
    );

    const buttonTopMargin = useTransform(
      scrollY,
      [0, windowHeight - 184],
      [windowHeight - 184, 0]
    );

    const listenToScroll = () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        if (scrollUp) {
          setScrollUp(false);
        }
      } else {
        if (!scrollUp) {
          setScrollUp(true);
        }
      }

      setLastScrollTop(st <= 0 ? 0 : st);
    };

    useEffect(() => {
      window.addEventListener("scroll", listenToScroll);
      return () => window.removeEventListener("scroll", listenToScroll);
    }, [lastScrollTop]);

    return (
      <header
        className={`
          ${style.customHeader}
          ${
            isEqualOrGreatherThanBreakpoint("md")
              ? lastScrollTop > windowHeight - 184
                ? scrollUp
                  ? style.showHeaderWithBg
                  : style.hideHeader
                : ""
              : lastScrollTop > windowHeight - 184
              ? scrollUp
                ? style.showHeaderWithBg
                : style.hideHeader
              : ""
          }
        `}
      >
        <Container fluid>
          <Row>
            <Col>
              <div className={style.customHeaderInner}>
                <div className={style.logo}>
                  <Link to={currentLanguage === "sv" ? "/sv" : "/"}>
                    <motion.div
                      style={{
                        maxWidth: logoSlideWidth,
                      }}
                    >
                      <img src="/images/essen-logo-dark.svg" alt="Essen International" />
                    </motion.div>
                  </Link>
                </div>
                <motion.div
                  className={style.buttonHolder}
                  style={{
                    translateY: buttonTopMargin,
                  }}
                >
                  <Link to={currentLanguage === "sv" ? "/sv" : "/"}>
                    <Button
                      className={style.btn}
                      small={true}
                      secondary
                      icon={"icon-arrow-right"}
                    >
                      ESSENINTERNATIONAL.COM
                    </Button>
                  </Link>
                </motion.div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    );
  })
);
