import React from "react";
import { Provider } from "mobx-react";
import { initStore, Store } from "~stores/Store";
import { withMenu } from "~libs/graphql/hoc/withMenu";
import { IMenu, ILanguage, IPage, IPageContext } from "~models";
import { initTranslations } from "~libs/i18n";

interface Props {
  store: Store;
  menu: IMenu[];
  languages: ILanguage[];
  defaultLanguage: ILanguage;
  data?: {
    wpPage?: IPage;
    wpInsight?: IPage;
    wpCase?: IPage;
  };
  pageContext: IPageContext;
}

export const withStore = (Comp) =>
  withMenu(
    class extends React.Component<Props> {
      store: Store;

      constructor(props: Props) {
        super(props);
        this.store = initStore();
        this.initStores();
      }

      initStores = async () => {
        initTranslations(this.props.pageContext.language || "en");

        const { defaultLanguage, languages, menu, data, pageContext } = this.props;

        this.store.contextStore.context = pageContext;
        this.store.contextStore.menu = menu;
        this.store.navigationStore.languages = languages;
        this.store.navigationStore.defaultLanguage = defaultLanguage;
        this.store.contextStore.data = data;
      };

      componentDidMount() {
        this.store.onMount();
      }

      componentWillUnmount() {
        this.store.onUnmount();
      }

      render() {
        return (
          <>
            <Provider {...this.store}>
              <Comp {...this.props} />
            </Provider>
          </>
        );
      }
    }
  );
