import React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { inject, observer } from "mobx-react";
import { GatsbyImage } from "gatsby-plugin-image";

import { VideoElement, Container, Row, Col, Button, Label, Divider } from "~components";
import { UIStore } from "~stores";
import { ILatestInsight } from "~models";
import {
  imageAnimationVariants,
  imageHolderAnimationVariants,
  staggerChildren,
  staggerChildrenItem,
} from "~libs/framer/variants";

import * as style from "./LatestInsight.module.scss";
import { tx } from "~libs/i18n";

interface Props {
  section: ILatestInsight;
  uiStore?: UIStore;
}

const HighlightedItem = ({
  title,
  text,
  caption,
  tag,
  slug,
  imageOrVideoGroup,
  contentColor,
}) => (
  <>
    <Col md={6} lg={5}>
      <figure>
        <Link to={slug}>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={imageHolderAnimationVariants}
            className={style.imageAnimationHolder}
          >
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={imageAnimationVariants}
              className={style.imageHolder}
            >
              {imageOrVideoGroup.imageOrVideoSelect === "image" && (
                <GatsbyImage
                  image={
                    imageOrVideoGroup.image?.localFile.childImageSharp.gatsbyImageData
                  }
                  style={{ width: "100%" }}
                  alt={imageOrVideoGroup.image?.caption || ""}
                />
              )}
              {imageOrVideoGroup.imageOrVideoSelect === "video" && (
                <VideoElement
                  url={
                    imageOrVideoGroup.type === "vimeo"
                      ? imageOrVideoGroup.videoUrl
                      : imageOrVideoGroup.videoFile.mediaItemUrl
                  }
                  poster={imageOrVideoGroup.videoPoster}
                  controls={imageOrVideoGroup.videoSettings.controls}
                  loop={imageOrVideoGroup.videoSettings.loop}
                  freeAspectRatio={true}
                />
              )}
            </motion.div>
          </motion.div>
        </Link>
      </figure>
    </Col>
    <Col lg={5} md={6} className={"offset-lg-1"}>
      <figcaption className={style.content}>
        <motion.div
          className={style.contentUp}
          variants={staggerChildren}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.8 }}
        >
          <motion.div variants={staggerChildrenItem} className={style.imageHolder}>
            <small style={{ color: contentColor }}>{caption}</small>
            <Label text={tag?.tagFields.tagName}></Label>
          </motion.div>
          <motion.h2
            dangerouslySetInnerHTML={{ __html: title }}
            style={{ color: contentColor }}
            variants={staggerChildrenItem}
          ></motion.h2>
        </motion.div>
        <motion.aside
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.8 }}
          variants={staggerChildren}
          className={style.contentBottom}
        >
          <motion.p
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ color: contentColor }}
            variants={staggerChildrenItem}
          ></motion.p>
          <motion.div variants={staggerChildrenItem}>
            <Link to={slug}>
              <Button icon={"icon-arrow-right"}>{tx("general.readMore")}</Button>
            </Link>
          </motion.div>
        </motion.aside>
      </figcaption>
    </Col>
  </>
);

export const LatestInsight = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { helperTitle, latest, transition } = section;
    const { contentColor } = uiStore;

    const sortedInsights = latest.sort(
      (a, b) =>
        Date.parse(b.latestFields.latestSettings.date) -
        Date.parse(a.latestFields.latestSettings.date)
    );

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="LatestInsight"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
      >
        <Container fluid className={style.hrHolderFirst}>
          <Row>
            <Col>
              <Divider dark={false} />
            </Col>
          </Row>
        </Container>
        <Container fluid className={style.container}>
          {latest &&
            sortedInsights.map((e, i) => (
              <React.Fragment key={i}>
                <Row className={`${latest.length > 1 ? style.itemRow : ""}`}>
                  <HighlightedItem
                    title={e.latestFields.latestSettings.title}
                    text={e.latestFields.latestSettings.description}
                    caption={i === 0 ? helperTitle : e.latestFields.latestSettings.season}
                    tag={e.latestFields.latestSettings.universalTag}
                    imageOrVideoGroup={e.latestFields.latestSettings.imageOrVideoGroup}
                    contentColor={contentColor}
                    slug={e.uri}
                  />
                </Row>
              </React.Fragment>
            ))}
        </Container>
        <Container fluid>
          <Row>
            <Col>
              <Divider dark={false} />
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
