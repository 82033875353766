// extracted by mini-css-extract-plugin
export var arrow = "ScrollVideo-module--arrow--0a937";
export var arrow_no = "ScrollVideo-module--arrow_no--0ed73";
export var arrow_yes = "ScrollVideo-module--arrow_yes--716c4";
export var cardContent = "ScrollVideo-module--card-content--3beaa";
export var cardContentInnerHolder = "ScrollVideo-module--card-content-inner-holder--23613";
export var container = "ScrollVideo-module--container--74c78";
export var firstBgHelper = "ScrollVideo-module--firstBgHelper--cecef";
export var flex = "ScrollVideo-module--flex--38130";
export var flex_1 = "ScrollVideo-module--flex_1--d026c";
export var flex_center = "ScrollVideo-module--flex_center--1ef33";
export var flex_center_col = "ScrollVideo-module--flex_center_col--66dc1";
export var flex_col = "ScrollVideo-module--flex_col--d6a7c";
export var flex_column = "ScrollVideo-module--flex_column--16aba";
export var flex_fix_center_col = "ScrollVideo-module--flex_fix_center_col--ca03c";
export var flex_fixed_center_col = "ScrollVideo-module--flex_fixed_center_col--b7ba0";
export var flex_row = "ScrollVideo-module--flex_row--54ae9";
export var imageAnimationHolder = "ScrollVideo-module--image-animation-holder--23c2d";
export var linkAnimation = "ScrollVideo-module--link-animation--3ba57";
export var parallaxFigure = "ScrollVideo-module--parallax-figure--83c89";
export var rotating = "ScrollVideo-module--rotating--1286b";
export var secondBgHelper = "ScrollVideo-module--second-bg-helper--bb8f3";
export var swiperWrapper = "ScrollVideo-module--swiper-wrapper--083d8";