// extracted by mini-css-extract-plugin
export var active = "Switcher-module--active--53c2c";
export var arrow = "Switcher-module--arrow--53c11";
export var arrow_no = "Switcher-module--arrow_no--65546";
export var arrow_yes = "Switcher-module--arrow_yes--c1cdb";
export var flex = "Switcher-module--flex--73f47";
export var flex_1 = "Switcher-module--flex_1--e1922";
export var flex_center = "Switcher-module--flex_center--2b041";
export var flex_center_col = "Switcher-module--flex_center_col--07032";
export var flex_col = "Switcher-module--flex_col--a9df6";
export var flex_column = "Switcher-module--flex_column--338d9";
export var flex_fix_center_col = "Switcher-module--flex_fix_center_col--5fa03";
export var flex_fixed_center_col = "Switcher-module--flex_fixed_center_col--50455";
export var flex_row = "Switcher-module--flex_row--bd646";
export var imageAnimationHolder = "Switcher-module--image-animation-holder--6d5a1";
export var indicator = "Switcher-module--indicator--498c1";
export var linkAnimation = "Switcher-module--link-animation--ef090";
export var options = "Switcher-module--options--6b243";
export var rotating = "Switcher-module--rotating--036ca";
export var swiperWrapper = "Switcher-module--swiper-wrapper--46801";
export var switcher = "Switcher-module--switcher--d3152";