import React, { useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import { IAddresses } from "~models";
import { Container, Row, Col, CustomCursor } from "~components";
import {
  imageAnimationVariants,
  imageHolderAnimationVariants,
  staggerChildren,
  staggerChildrenItem,
} from "~libs/framer/variants";

import * as style from "./Addresses.module.scss";
import { inject, observer } from "mobx-react";
import { UIStore } from "~stores";

interface CardProps {
  cityName: string;
  time: string;
  address: string;
  uiStore?: UIStore;
}

const LocationCard = inject("uiStore")(
  observer(({ cityName, time, address, uiStore }: CardProps) => {
    const { setCustomCursorEvent } = uiStore;

    const { isEqualOrGreatherThanBreakpoint } = uiStore;

    return (
      <motion.div
        className={style.locationCard}
        variants={staggerChildrenItem}
        onMouseEnter={() => setCustomCursorEvent(true)}
        onMouseLeave={() => setCustomCursorEvent(false)}
      >
        {isEqualOrGreatherThanBreakpoint("lg") && (
          <div className={style.customCursorHolder}>
            <CustomCursor text={"(" + time.toLocaleUpperCase() + ")"} type="text" />
          </div>
        )}
        <h3 dangerouslySetInnerHTML={{ __html: cityName }} className="body-large" />
        <motion.address
          dangerouslySetInnerHTML={{ __html: address.toLocaleUpperCase() }}
          className="p"
        />
        <time className="p">{"( " + time.toLocaleUpperCase() + " )"}</time>
      </motion.div>
    );
  })
);

interface Props {
  section: IAddresses;
}

export const Addresses = ({ section }: Props) => {
  const { locations, portraitFigure, landscapeFigure } = section;

  const [currentDate, setCurrentDate] = useState(new Date());

  const timeZones = [
    { name: "cet", timeZone: "Europe/Stockholm" },
    { name: "est", timeZone: "America/New_York" },
    { name: "pst", timeZone: "America/Los_Angeles" },
  ];

  const convertToDate = (timezone) => {
    let tz = timeZones.filter((t) => t.name === timezone);
    return tz[0].timeZone;
  };

  const tick = () => {
    setCurrentDate(new Date());
  };

  useEffect(() => {
    const theTimer = setInterval(() => tick(), 1000);
    return () => clearInterval(theTimer);
  }, []);

  return (
    <section data-sectionname="Addresses">
      <Container fluid>
        <Row className={style.figureRow}>
          <Col md={8} className={style.imageHolder}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={imageHolderAnimationVariants}
              className={style.imageAnimationHolder}
            >
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={imageAnimationVariants}
              >
                <GatsbyImage
                  image={landscapeFigure?.localFile.childImageSharp.image}
                  style={{ width: "100%" }}
                  alt={""}
                />
              </motion.div>
            </motion.div>
          </Col>
          <Col md={4} className={style.imageHolder}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={imageHolderAnimationVariants}
              className={style.imageAnimationHolder}
            >
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={imageAnimationVariants}
              >
                <GatsbyImage
                  image={portraitFigure?.localFile.childImageSharp.image}
                  style={{ width: "100%" }}
                  alt={""}
                />
              </motion.div>
            </motion.div>
          </Col>
        </Row>
        <Row>
          <Col>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={staggerChildren}
              className={style.locationCardHolder}
            >
              {locations.map((city, index) => (
                <LocationCard
                  cityName={city.city}
                  key={index}
                  time={`${
                    currentDate
                      .toLocaleString("en-En", {
                        timeZone: convertToDate(city.timeZone),
                        hour12: false,
                      })
                      .split(", ")[1]
                  } ${city.timeZone}`}
                  address={city.address}
                />
              ))}
            </motion.div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
