export interface IFlatListMenu {
  locations: string[];
  menuItems: {
    items: {
      id: string;
      parentId: string;
      label: string;
      path: string;
      menuFields?: {
        type: "normal" | "button";
      };
    }[];
  };
}
export interface IMenu {
  location: string;
  items: IMenuItem[];
}
export interface IMenuItem {
  id: string;
  label: string;
  path: string;
  menuFields?: {
    type: "normal" | "button";
  };
  open?: boolean;
  items?: IMenuItem[];
  parentId: string;
}

export const createMenu = (data: IFlatListMenu) => {
  const normalizedMenuItems = data.menuItems.items.map((item) => ({
    ...item,
    path: item.path?.replace("/home/", "/"),
  }));

  const parents = normalizedMenuItems
    .filter((item) => !item.parentId)
    .reduce((acc, curr) => {
      acc[curr.id] = { ...curr, items: [] };
      return acc;
    }, {} as { [key: string]: IMenuItem });

  normalizedMenuItems
    .filter((d) => d.parentId)
    .forEach((child) => {
      if (parents[child.parentId]) {
        parents[child.parentId].items.push(child);
      }
    });
  return {
    location: data.locations.find((l) => !!l),
    items: Object.values(parents),
  } as IMenu;
};
