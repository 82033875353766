import React from "react";

import { Container, Row, Col, Card } from "~components";
import { IExploreMore } from "~models";

import * as style from "./ExploreMore.module.scss";

interface Props {
  section: IExploreMore;
}

export const ExploreMore = ({ section }: Props) => {
  const { title, partners, isReversed, isLightText } = section;

  return (
    <section data-sectionname="ExploreMore">
      <Container fluid>
        {title && (
          <Row>
            <Col>
              <h4
                className={style.title}
                dangerouslySetInnerHTML={{ __html: title }}
              ></h4>
            </Col>
          </Row>
        )}
        <div className={`${style.cardHolder} ${isReversed ? style.isReversed : ""}`}>
          {partners?.map((partner, index) => (
            <div key={index} className={style.card}>
              <Card
                key={index}
                imageOrVideo={partner.partnerGroup.imageOrVideoGroup}
                title={partner.partnerGroup.partner}
                description={partner.partnerGroup.text}
                link={partner.partnerGroup.link}
                portrait={index !== 0}
                tag={
                  partner.partnerGroup.tag.length
                    ? partner.partnerGroup.tag.map((e) => e.tagFields.tagName)
                    : [""]
                }
                isLightText={isLightText}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};
