import React, { Component } from "react";
import { ISections } from "~models";
import { CaseSections } from "~views/Sections";
import { SectionsContainer } from "~views/shared/components";

interface TemplateProps {
  template: string;
  sections: ISections;
  bgColor?: string;
}

export class CaseTemplate extends Component<TemplateProps> {
  render() {
    const { template, sections, bgColor } = this.props;
    switch (template) {
      case "page_builder":
        return (
          <SectionsContainer bgColor={bgColor} template="page_builder">
            <CaseSections sections={sections} />
          </SectionsContainer>
        );
      default:
        return null;
    }
  }
}
