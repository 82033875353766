// extracted by mini-css-extract-plugin
export var arrow = "NewsBanner-module--arrow--f62ef";
export var arrow_no = "NewsBanner-module--arrow_no--fb67c";
export var arrow_yes = "NewsBanner-module--arrow_yes--134f3";
export var biggerLayout = "NewsBanner-module--bigger-layout--c3f5e";
export var biggerText = "NewsBanner-module--bigger-text--5dcd5";
export var bold = "NewsBanner-module--bold--cca8a";
export var flex = "NewsBanner-module--flex--8b406";
export var flex_1 = "NewsBanner-module--flex_1--add7f";
export var flex_center = "NewsBanner-module--flex_center--7edcb";
export var flex_center_col = "NewsBanner-module--flex_center_col--bbcdf";
export var flex_col = "NewsBanner-module--flex_col--d166a";
export var flex_column = "NewsBanner-module--flex_column--8eab7";
export var flex_fix_center_col = "NewsBanner-module--flex_fix_center_col--154e5";
export var flex_fixed_center_col = "NewsBanner-module--flex_fixed_center_col--5b90b";
export var flex_row = "NewsBanner-module--flex_row--61d1c";
export var imageAnimationHolder = "NewsBanner-module--image-animation-holder--f717a";
export var italic = "NewsBanner-module--italic--04161";
export var linkAnimation = "NewsBanner-module--link-animation--85493";
export var marquee = "NewsBanner-module--marquee--641c2";
export var rotating = "NewsBanner-module--rotating--80011";
export var swiperWrapper = "NewsBanner-module--swiper-wrapper--10ea4";
export var textHolder = "NewsBanner-module--text-holder--b368c";