import React from "react";
import { motion } from "framer-motion";

import { IPolicyList } from "~models";
import { Container, Row, Col } from "~components";
import { fadeInItem } from "~libs/framer/variants";

import * as style from "./PolicyList.module.scss";

interface Props {
  title: string;
  text: string;
}

const PolicyListItem = ({ title, text }: Props) => {
  return (
    <div className={style.policyHolder}>
      <Row>
        <Col xl={3}>
          <motion.h4
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeInItem}
            custom={0}
            className={style.policyTitle}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Col>
        <Col xl={6} className={"offset-xl-1"}>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeInItem}
            custom={0.4}
            className={style.policyContent}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Col>
      </Row>
    </div>
  );
};

export interface PolicyListProps {
  section: IPolicyList;
}

export const PolicyList = ({ section }: PolicyListProps) => {
  const { policies } = section;

  return (
    <section data-sectionname="PolicyList">
      <Container fluid>
        <Row>
          <Col>
            {policies.map((policy, index) => (
              <PolicyListItem
                key={index}
                title={policy.policyGroup.policyTitle}
                text={policy.policyGroup.policyText}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
