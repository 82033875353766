export default {
  translation: {
    general: {
      loadMore: "LOAD MORE",
      readMore: "READ MORE",
      mailTo: "Email",
    },
    notFound: {
      line1: "This page",
      line2: "does not",
      line3: "exist",
      extra: "Please don't rage,<br /><i>just go back to the start page</i>",
      button: "HEAD BACK",
    },
    newsletter: {
      thankYou: "THANK YOU FOR SUBSCRIBING!",
      already: "ALREADY SUBSCRIBED! CLICK TO TRY AGAIN",
      problem: "SERVER PROBLEM! CLICK TO TRY AGAIN",
      signUp: "NEWSLETTER SIGN UP",
    },
    cursor: {
      readMore: "READ MORE",
      viewWork: "VIEW WORK",
    },
    insights: {
      readTime: "MINUTE READ",
    },
    whitePaperForm: {
      firstName: "FIRST NAME *",
      lastName: "LAST NAME *",
      companyName: "COMPANY NAME *",
      emailAddress: "EMAIL ADDRESS *",
      newsletter:
        "YES, I WOULD LIKE TO RECEIVE MORE ENLIGHTENING WHITE PAPERS FROM ESSEN",
      privacy:
        "YES, I HAVE READ AND AGREE TO THE <a href='/privacy-policy'>PRIVACY POLICY</a>",
      button: "SUBMIT",
    },
    draggableCards: {
      stack: "STACK",
      spread: "SPREAD",
    },
    services: {
      readMore: "READ MORE",
      close: "CLOSE",
    },
  },
};
