import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { inject, observer } from "mobx-react";
import scrollTo from "gatsby-plugin-smoothscroll";

import { Container, Row, Col, Button } from "~components";
import { UIStore } from "~stores";

import * as style from "./CustomHeader.module.scss";

interface Props {
  uiStore?: UIStore;
  showForm: boolean;
  toggleButtonTitle?: string;
}

export const CustomHeader = inject("uiStore")(
  observer(({ uiStore, showForm, toggleButtonTitle }: Props) => {
    const {
      isEqualOrGreatherThanBreakpoint,
      toggleWhitePaperForm,
      whitePaperFormVisible,
      windowWidth,
      breakpoint,
      windowPageYOffset,
    } = uiStore;

    const [initialScroll, setInitialScroll] = useState(500);
    const [headerVisible, setHeaderVisible] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState<number>(0);
    const [headerScrollTop, setHeaderScrollTop] = useState<number>(initialScroll);

    const listenToScroll = () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > initialScroll) {
        setHeaderScrollTop(0);
      } else {
        // NOTE: bug
        if (st === 1) {
          st = 0;
        }
        setHeaderScrollTop(initialScroll - st);
      }

      setLastScrollTop(st <= 0 ? 0 : st);
    };

    const onButtonClicked = () => {
      if (lastScrollTop > initialScroll) {
        if (!whitePaperFormVisible) {
          toggleWhitePaperForm();
        }
        setTimeout(() => scrollTo("#download", "start"), 100);
      } else {
        toggleWhitePaperForm();
      }
    };

    useEffect(() => {
      if (typeof window !== "undefined" && window) {
        if (window.location.hash === "#download") {
          toggleWhitePaperForm();
        }
      }

      setTimeout(() => {
        setHeaderVisible(true);
      }, 250);
    }, []);

    useEffect(() => {
      let initPosition =
        windowWidth < 992
          ? windowPageYOffset > 300
            ? 300 - windowPageYOffset
            : 300
          : windowPageYOffset > 500
          ? 500 - windowPageYOffset
          : 500;

      setInitialScroll(initPosition);
    }, [breakpoint]);

    useEffect(() => {
      window.addEventListener("scroll", listenToScroll);
      return () => window.removeEventListener("scroll", listenToScroll);
    }, [lastScrollTop]);

    return (
      <header
        className={`${style.customHeader} ${!headerVisible ? style.hidden : ""}`}
        style={{ top: `${headerScrollTop}px` }}
      >
        <Container fluid>
          <Row>
            <Col>
              <div className={style.customHeaderInner}>
                <Link to={"/"} className={style.logo}>
                  <img src="/images/essen-logo-light.svg" alt="" />
                </Link>
                {showForm.toString() === "true" ? (
                  <div className={style.buttonHolder}>
                    {isEqualOrGreatherThanBreakpoint("sm") ? (
                      <Button
                        icon={whitePaperFormVisible ? "icon-minus" : "icon-plus"}
                        filled={true}
                        onClick={() => onButtonClicked()}
                        className={style.customButton}
                      >
                        {toggleButtonTitle}
                      </Button>
                    ) : (
                      <p className={style.mobileButton} onClick={() => onButtonClicked()}>
                        {toggleButtonTitle}{" "}
                        <span>
                          <i
                            className={whitePaperFormVisible ? "icon-minus" : "icon-plus"}
                          ></i>
                        </span>
                      </p>
                    )}
                  </div>
                ) : showForm.toString() === "false" ? (
                  <Link
                    to={headerScrollTop > 0 ? "/" : ""}
                    className={style.arrowLink}
                    onClick={(e) => (headerScrollTop > 0 ? null : e.preventDefault())}
                  >
                    <button
                      className={style.scrollToTop}
                      onClick={() => scrollTo("#news-banner")}
                    >
                      <div className={style.textWrapper}>
                        <span
                          style={{
                            transform: `${
                              headerScrollTop > 0 ? "translateY(0)" : "translateY(-20px)"
                            }`,
                          }}
                        >
                          VISIT ESSEN'S HOMEPAGE <br />
                          BACK TO TOP
                        </span>
                      </div>
                      <i
                        className={"icon-arrow-right"}
                        style={{
                          transform: `${
                            headerScrollTop > 0 ? "rotate(-45deg)" : "rotate(-90deg)"
                          }`,
                        }}
                      ></i>
                    </button>
                  </Link>
                ) : (
                  <></>
                )}
              </div>
              <h1 className={style.hidden}>BRANDING AS A PORTFOLIO SERVICE</h1>
            </Col>
          </Row>
        </Container>
      </header>
    );
  })
);
