// extracted by mini-css-extract-plugin
export var arrow = "ImageOrVideo-module--arrow--7fb73";
export var arrow_no = "ImageOrVideo-module--arrow_no--f220a";
export var arrow_yes = "ImageOrVideo-module--arrow_yes--df187";
export var doubleSize = "ImageOrVideo-module--double-size--23b4b";
export var flex = "ImageOrVideo-module--flex--0014d";
export var flex_1 = "ImageOrVideo-module--flex_1--1ec9e";
export var flex_center = "ImageOrVideo-module--flex_center--20fdf";
export var flex_center_col = "ImageOrVideo-module--flex_center_col--7fb46";
export var flex_col = "ImageOrVideo-module--flex_col--58178";
export var flex_column = "ImageOrVideo-module--flex_column--44aa5";
export var flex_fix_center_col = "ImageOrVideo-module--flex_fix_center_col--c8129";
export var flex_fixed_center_col = "ImageOrVideo-module--flex_fixed_center_col--91ddc";
export var flex_row = "ImageOrVideo-module--flex_row--b715d";
export var full = "ImageOrVideo-module--full--d1f08";
export var fullException = "ImageOrVideo-module--full-exception--60d1c";
export var halfSize = "ImageOrVideo-module--half-size--5b23a";
export var imageAnimationHolder = "ImageOrVideo-module--image-animation-holder--364a6";
export var imageHolder = "ImageOrVideo-module--image-holder--b1cf1";
export var linkAnimation = "ImageOrVideo-module--link-animation--eac03";
export var rotating = "ImageOrVideo-module--rotating--9b36c";
export var swiperWrapper = "ImageOrVideo-module--swiper-wrapper--0032b";