import React from "react";
import { motion } from "framer-motion";

import { IGifAndTextScatter } from "~models";
import { Container, Row, Col } from "~components";
import { staggerChildren, staggerChildrenItem } from "~libs/framer/variants";

import * as style from "./GifAndTextScatter.module.scss";

interface GifAndTextProps {
  gifName: string | "apple" | "banana" | "cupcake" | "computer" | "lamp" | "binoculars";
  title: string;
  text: string;
}

const GifAndText = ({ gifName, title, text }: GifAndTextProps) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      variants={staggerChildren}
    >
      <motion.img
        className={style.gif}
        src={`/images/${gifName}.gif`}
        alt={gifName}
        variants={staggerChildrenItem}
      />
      <motion.h4 variants={staggerChildrenItem}>{title}</motion.h4>
      <motion.p
        variants={staggerChildrenItem}
        dangerouslySetInnerHTML={{ __html: text }}
      ></motion.p>
    </motion.div>
  );
};

interface Props {
  section: IGifAndTextScatter;
}

export const GifAndTextScatter = ({ section }: Props) => {
  const { gifsAndTexts } = section;

  const gifOrder = ["apple", "banana", "cupcake", "computer", "lamp", "binoculars"];

  return (
    <section data-sectionname="GifAndTextScatter">
      <Container>
        {gifsAndTexts.map((gifAndText, index) => (
          <Row key={index} className={style.cardRow}>
            <Col
              xl={4}
              md={6}
              className={`${
                index % 2 === 0 ? "offset-xl-1" : "offset-md-6 offset-xl-7"
              } ${style.cardHolder}`}
            >
              <GifAndText
                gifName={gifOrder[index]}
                title={gifAndText.gifAndTextGroup.title}
                text={gifAndText.gifAndTextGroup.text}
              />
            </Col>
          </Row>
        ))}
      </Container>
    </section>
  );
};
