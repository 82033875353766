import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import { IContentBody } from "~models";
import { Container, Row, Col, VideoElement, Button } from "~components";
import {
  fadeInItem,
  imageAnimationVariants,
  imageHolderAnimationVariants,
} from "~libs/framer/variants";

import * as style from "./ContentBody.module.scss";
import { Link } from "gatsby";

interface ContentProps {
  number: string;
  title: string;
  text: string;
}

const Content = ({ number, title, text }: ContentProps) => {
  return (
    <>
      <Col
        xs={12}
        md={8}
        lg={6}
        className={`offset-md-2 offset-lg-3 ${style.bodyContentWrapper}`}
      >
        <div key={number}>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={`${style.title} body-large`}
            dangerouslySetInnerHTML={{ __html: title }}
            variants={fadeInItem}
            custom={0.2}
          />
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={style.content}
            dangerouslySetInnerHTML={{ __html: text }}
            variants={fadeInItem}
            custom={0.4}
          />
        </div>
      </Col>
    </>
  );
};

interface Props {
  section: IContentBody;
}

export const ContentBody = ({ section }: Props) => {
  const {
    titleAndTextList,
    contentFigure,
    finalFigure,
    content,
    highlightedContent,
    backButton,
  } = section;

  return (
    <section data-sectionname="ContentBody">
      <Container fluid>
        {highlightedContent && (
          <Row>
            <Col xs={12} md={8} lg={6} className={style.descriptionWrapper}>
              <motion.p
                className={`body-large ${style.highlighted}`}
                dangerouslySetInnerHTML={{ __html: highlightedContent }}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInItem}
                custom={0.4}
              />
            </Col>
          </Row>
        )}
        {content && (
          <Row>
            <Col
              xs={12}
              md={8}
              lg={6}
              className={`offset-md-2 offset-lg-3 ${style.bodyContentWrapper}`}
            >
              <motion.p
                className={style.normalContent}
                dangerouslySetInnerHTML={{ __html: content }}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInItem}
                custom={0.4}
              />
            </Col>
          </Row>
        )}
        {(contentFigure.image || contentFigure.videoUrl) && (
          <Row>
            <Col className={style.imageWrapper}>
              <figure className={style.figure}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={imageHolderAnimationVariants}
                  className={style.imageAnimationHolder}
                >
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={imageAnimationVariants}
                  >
                    {contentFigure.imageOrVideoSelect === "image" && (
                      <GatsbyImage
                        image={contentFigure.image?.localFile.childImageSharp.image}
                        style={{ width: "100%" }}
                        alt={""}
                      />
                    )}
                    {contentFigure.imageOrVideoSelect === "video" && (
                      <VideoElement
                        url={
                          contentFigure.type === "vimeo"
                            ? contentFigure.videoUrl
                            : contentFigure.videoFile.mediaItemUrl
                        }
                        poster={contentFigure.videoPoster}
                        controls={contentFigure.videoSettings.controls}
                        customControls={contentFigure.videoSettings.customControls}
                        loop={contentFigure.videoSettings.loop}
                        freeAspectRatio={true}
                      />
                    )}
                  </motion.div>
                </motion.div>
              </figure>
            </Col>
          </Row>
        )}
        {titleAndTextList &&
          titleAndTextList.map((titleAndText, index) => (
            <Row key={index} className={style.contentRow}>
              <Content
                number={`0${Number(index) + 1}`}
                title={titleAndText.title}
                text={titleAndText.text}
              />
            </Row>
          ))}
        <Row>
          <div id={finalFigure.imageOrVideoSelect === "video" ? "latestVideo" : null} />
          <Col className={style.imageWrapper}>
            {(finalFigure.image || finalFigure.videoUrl) && (
              <figure className={`${style.figure} ${style.finalFigure}`}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={imageHolderAnimationVariants}
                  className={style.imageAnimationHolder}
                >
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={imageAnimationVariants}
                  >
                    {finalFigure.imageOrVideoSelect === "image" && (
                      <GatsbyImage
                        image={finalFigure.image?.localFile.childImageSharp.image}
                        style={{ width: "100%" }}
                        alt={""}
                      />
                    )}
                    {finalFigure.imageOrVideoSelect === "video" && (
                      <VideoElement
                        url={
                          finalFigure.type === "vimeo"
                            ? finalFigure.videoUrl
                            : finalFigure.videoFile.mediaItemUrl
                        }
                        poster={finalFigure.videoPoster}
                        controls={finalFigure.videoSettings.controls}
                        customControls={finalFigure.videoSettings.customControls}
                        loop={finalFigure.videoSettings.loop}
                        freeAspectRatio={true}
                      />
                    )}
                  </motion.div>
                </motion.div>
              </figure>
            )}
          </Col>
        </Row>
        {backButton && (
          <Row className={style.backButton}>
            <Link to={backButton.url}>
              <Button icon="icon-arrow-right" secondary>
                <span
                  dangerouslySetInnerHTML={{
                    __html: backButton.title,
                  }}
                />
              </Button>
            </Link>
          </Row>
        )}
      </Container>
    </section>
  );
};
