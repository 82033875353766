// extracted by mini-css-extract-plugin
export var accordionContent = "Services-module--accordion-content--5a719";
export var accordionHeading = "Services-module--accordion-heading--d68b8";
export var accordionItem = "Services-module--accordion-item--12510";
export var arrow = "Services-module--arrow--58670";
export var arrow_no = "Services-module--arrow_no--d4a48";
export var arrow_yes = "Services-module--arrow_yes--5739f";
export var flex = "Services-module--flex--96745";
export var flex_1 = "Services-module--flex_1--76316";
export var flex_center = "Services-module--flex_center--56667";
export var flex_center_col = "Services-module--flex_center_col--69ab9";
export var flex_col = "Services-module--flex_col--0ec42";
export var flex_column = "Services-module--flex_column--486b1";
export var flex_fix_center_col = "Services-module--flex_fix_center_col--c71c3";
export var flex_fixed_center_col = "Services-module--flex_fixed_center_col--ef3b8";
export var flex_row = "Services-module--flex_row--0880a";
export var hr = "Services-module--hr--70373";
export var imageAnimationHolder = "Services-module--image-animation-holder--ee7dd";
export var light = "Services-module--light--76a8d";
export var linkAnimation = "Services-module--link-animation--608c0";
export var open = "Services-module--open--177e4";
export var rotating = "Services-module--rotating--af931";
export var sectionTitle = "Services-module--section-title--cd182";
export var swiperWrapper = "Services-module--swiper-wrapper--be913";
export var whiteSpace = "Services-module--white-space--ab603";