import React from "react";

import { Container, Row, Col } from "~components";
import { INewsBanner } from "~models";

import * as style from "./NewsBanner.module.scss";

interface Props {
  section: INewsBanner;
}

const NewsElement = ({
  newsItem,
}: {
  newsItem: { newsText: string; styleKey: string };
}) => (
  <span
    className={`${newsItem.styleKey === "italic" ? style.italic : ""} ${
      newsItem.styleKey === "bold" ? style.bold : ""
    }`}
  >
    • {newsItem.newsText}
    {"  "}
  </span>
);

export const NewsBanner = ({ section }: Props) => {
  const { news, isBigger } = section;

  return (
    <section
      data-sectionname="NewsBanner"
      className={`${isBigger ? style.biggerLayout : ""}`}
      id="news-banner"
    >
      <Container fluid>
        <Row>
          <Col>
            <p
              className={`${style.textHolder} body-large ${
                isBigger ? style.biggerText : ""
              }`}
            >
              {[...Array(2)].fill("").map((e, i) => (
                <small key={i}>
                  {[...Array(2)]
                    .fill("")
                    .map((f, j) =>
                      news.map((n, index) => <NewsElement key={index} newsItem={n} />)
                    )}
                </small>
              ))}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
