// extracted by mini-css-extract-plugin
export var arrow = "LowerHeader-module--arrow--1372a";
export var arrow_no = "LowerHeader-module--arrow_no--9ef30";
export var arrow_yes = "LowerHeader-module--arrow_yes--71439";
export var button = "LowerHeader-module--button--b182b";
export var figcaption = "LowerHeader-module--figcaption--459ac";
export var figure = "LowerHeader-module--figure--59c38";
export var flex = "LowerHeader-module--flex--af116";
export var flex_1 = "LowerHeader-module--flex_1--e6d2e";
export var flex_center = "LowerHeader-module--flex_center--32cd1";
export var flex_center_col = "LowerHeader-module--flex_center_col--494f0";
export var flex_col = "LowerHeader-module--flex_col--f812e";
export var flex_column = "LowerHeader-module--flex_column--e5837";
export var flex_fix_center_col = "LowerHeader-module--flex_fix_center_col--99808";
export var flex_fixed_center_col = "LowerHeader-module--flex_fixed_center_col--23a7d";
export var flex_row = "LowerHeader-module--flex_row--c486e";
export var gif = "LowerHeader-module--gif--a910f";
export var imageAnimationHolder = "LowerHeader-module--image-animation-holder--d0e1f";
export var imageHolder = "LowerHeader-module--image-holder--d2452";
export var linkAnimation = "LowerHeader-module--link-animation--717d6";
export var rotating = "LowerHeader-module--rotating--d43d1";
export var swiperWrapper = "LowerHeader-module--swiper-wrapper--b1e80";
export var text = "LowerHeader-module--text--d9dc3";
export var title = "LowerHeader-module--title--972af";