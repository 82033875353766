import i18next from "i18next";
// import { initTranslations } from "./init"

/**
 * Translates text.
 *
 * @param key The i18n key.
 * @param vars Additional values sure to replace.
 */
export const tx = (key: string, vars?: string | object, fallback?: string) => {
  // NOTE: ??
  // initTranslations()
  const result = (key ? i18next.t(key, vars) : null) as string;
  if (result === key && typeof fallback !== "undefined") {
    return fallback;
  }
  return result;
};

export const txP = (
  prefix: string,
  key: string,
  vars?: string | object,
  fallback?: any
) => (key ? tx(`${prefix}.${key}`, vars, fallback) : null);

export const getCurrentLanguage = () => i18next.language;

export const getLanguages = () => i18next.languages;
