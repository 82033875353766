import React from "react";
import * as style from "./styles.module.scss";

export interface CustomVideoControlProps {
  playPause: boolean;
  playVideo: (e) => void;
  pauseVideo: (e) => void;
  handleSeekChange: (e: any) => void;
  progressBar: number;
  videoCurrentTime: number;
  isMuted: boolean;
  isDark: boolean;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomVideoControls = ({
  playPause,
  playVideo,
  pauseVideo,
  handleSeekChange,
  progressBar,
  videoCurrentTime,
  isMuted,
  isDark,
  setIsMuted,
}: CustomVideoControlProps) => {
  const formartTimeVideo = (hours, mins, secds) => {
    let time;
    if (hours < 1) {
      hours = "";
    }
    if (hours < 10 && hours != "") {
      hours = "0" + hours + ":";
    }
    if (mins < 10) {
      mins = "0" + mins;
    }
    if (secds < 10) {
      secds = "0" + secds;
    }
    return (time = `${hours}${mins}:${secds}`);
  };

  const renderCurrentTime = () => {
    let hours, mins, secds, time;
    hours = Math.floor(videoCurrentTime / 3600);
    mins = Math.floor(videoCurrentTime / 60);
    secds = Math.floor(videoCurrentTime - mins * 60);
    return (time = formartTimeVideo(hours, mins, secds));
  };
  return (
    <div
      className={`${style.customControls} custom-controls ${isDark ? style.dark : ""}`}
    >
      <div
        className={`${style.customControlsButtons}`}
        onClick={(e) => (!playPause ? playVideo(e) : pauseVideo(e))}
      >
        <span className="p">{playPause ? "PAUSE" : "PLAY"}</span>
      </div>
      <div
        className={`${style.customControlsButtons}`}
        onClick={(e) => [e.stopPropagation(), setIsMuted(!isMuted)]}
      >
        <span className="p">{isMuted ? "UNMUTE" : "MUTE"}</span>
      </div>
      <input
        type="range"
        className={style.customControlsSeekerInput}
        min={0}
        max={10000}
        onChange={(e) => handleSeekChange(e)}
        value={progressBar}
      />
      <span className={`${style.customControlsCurrentTime}`}>{renderCurrentTime()}</span>
    </div>
  );
};
