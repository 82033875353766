import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { inject, observer } from "mobx-react";
import {
  motion,
  useAnimation,
  useScroll,
  useTransform,
  AnimationControls,
} from "framer-motion";

import { Col, Container, LanguageSwitcher, Row, Sidebar } from "~views/shared/components";
import { ContextStore, NavigationStore, UIStore } from "~stores";
import {
  menuIconItemAnimationVariants,
  menuIconAnimationsVariants,
  hiddenNavigationAnimationVariants,
  hiddenNavigationContainerAnimationVariants,
  customLogoAnimationVariants,
  duplicatedLogoAnimationVariants,
  mainLogoAnimationVariants,
  logoSlideAnimationVariants,
} from "~libs/framer/menuVariants";
import { getCurrentLanguage } from "~libs/i18n";

import * as style from "./Header.module.scss";

interface Props {
  uiStore?: UIStore;
  navigationStore?: NavigationStore;
  contextStore?: ContextStore;
}

export const Header = inject(
  "uiStore",
  "navigationStore",
  "contextStore"
)(
  observer(({ uiStore, navigationStore, contextStore }: Props) => {
    const menuItems = navigationStore.headerMenu.items || [];
    const { breakpoint, canLogoSlideAnimate, isEqualOrGreatherThanBreakpoint } = uiStore;
    const { location } = navigationStore;
    const { data } = contextStore;

    const [currentLanguage, setCurrentLanguage] = useState("");
    const [isHomePage, setHomePage] = useState(false);
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [lastScrollTop, setLastScrollTop] = useState<number>(0);
    const [scrollUp, setScrollUp] = useState<boolean>(false);
    const [isMenuHover, setMenuHover] = useState(false);
    const [sidebar, setSidebar] = useState(false);

    const hiddenNavigationAnimation = useAnimation();
    const hiddenNavigationContainerAnimation = useAnimation();
    const menuIconAnimation = useAnimation();
    const logoAnimation = useAnimation();
    const logoSlideAnimation = useAnimation();

    const hiddenNavigationElement = useRef(null);
    const [hiddenNavigationRenderWidth, setHiddenNavigationRenderWidth] = useState(0);

    const { scrollY } = useScroll();

    const logoSlideWidth = useTransform(
      scrollY,
      [2, 172],
      [
        isEqualOrGreatherThanBreakpoint("xxl")
          ? "800px"
          : isEqualOrGreatherThanBreakpoint("md")
          ? "400px"
          : "100px",
        "100px",
      ]
    );

    useEffect(() => {
      let cl = getCurrentLanguage();
      setCurrentLanguage(cl);
      const languageBasedLocation = cl === "en" ? "/" : "/" + cl + "/";
      setHomePage(location.pathname === languageBasedLocation);
      if (hiddenNavigationElement.current.children.length) {
        let total = 0;
        for (let i of hiddenNavigationElement.current.children) {
          total += i.clientWidth;
        }
        setHiddenNavigationRenderWidth(
          total + (hiddenNavigationElement.current.children.length - 2) * 41 + 20
        );
      }
    }, []);

    useEffect(() => {
      uiStore.allowLogoSlideAnimate(!isHomePage);
      if (sidebar) {
        document.body.classList.remove("overflow_unset");
        document.body.classList.add("overflow_hidden");
      } else {
        document.body.classList.remove("overflow_hidden");
        document.body.classList.add("overflow_unset");
      }
    }, [sidebar, isHomePage]);

    useEffect(() => {
      isHomePage ? logoAnimation.start("bigger") : logoAnimation.start("visible");
    }, [logoSlideWidth, breakpoint, isHomePage]);

    useEffect(() => {
      window.addEventListener("scroll", listenToScroll);
      if (isHomePage && lastScrollTop <= 500) {
        menuIconAnimation.start("visible");
        hiddenNavigationAnimation.start("hidden");
        hiddenNavigationContainerAnimation.start("hidden");
      }
      if (isHomePage && isEqualOrGreatherThanBreakpoint("md")) {
        if (lastScrollTop > 270) {
          uiStore.setHeaderTheme("dark");
        } else {
          uiStore.setHeaderTheme(uiStore.defaultHeaderTheme);
        }
      } else {
        if (lastScrollTop > 70) {
          uiStore.setHeaderTheme("dark");
        } else {
          uiStore.setHeaderTheme(uiStore.defaultHeaderTheme);
        }
      }
      return () => window.removeEventListener("scroll", listenToScroll);
    }, [lastScrollTop, isHomePage, breakpoint]);

    const triggerLogoAnimation = () => {
      logoSlideAnimation.start("mainLogoSlided");
      logoSlideAnimation.start("duplicatedLogoVisible");

      setTimeout(() => {
        logoSlideAnimation.start("duplicatedLogoInitial");
        logoSlideAnimation.start("mainLogoInitial");
      }, 5000);
    };

    const openMenu = () => {
      hiddenNavigationAnimation.start("visible");
      hiddenNavigationContainerAnimation.start("visible");
      menuIconAnimation.start("hidden");
      setMenuHover(true);

      canLogoSlideAnimate && triggerLogoAnimation();
    };

    const closeMenu = () => {
      setMenuHover(false);
      menuIconAnimation.start("visible");
      hiddenNavigationAnimation.start("hidden");
      hiddenNavigationContainerAnimation.start("hidden");
    };

    const listenToScroll = () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        if (scrollUp) {
          setScrollUp(false);
        }
      } else {
        if (!scrollUp) {
          setScrollUp(true);
        }
      }

      setLastScrollTop(st <= 0 ? 0 : st);
    };

    useEffect(() => {
      // TODO: remove setTimeout function
      // const theNewAnimationSubscription = theNewAnimation.subscribe()
      setTimeout(() => {
        setLoaded(true);
      }, 440);
    }, []);

    return (
      <header className={`${style.header} ${sidebar ? style.withSidebar : ""}`}>
        <div
          className={`${style.headerHolder} ${
            isHomePage && isEqualOrGreatherThanBreakpoint("md")
              ? lastScrollTop > 270
                ? scrollUp
                  ? style.showHeaderWithBg
                  : style.hideHeader
                : ""
              : lastScrollTop > 70
              ? scrollUp
                ? style.showHeaderWithBg
                : style.hideHeader
              : ""
          } ${style[uiStore.headerTheme]}`}
          onMouseLeave={() => closeMenu()}
        >
          <Container fluid={true}>
            <Row>
              <Col>
                <div
                  className={`${style.headerInner} ${isLoaded ? style.isLoaded : ""} ${
                    !canLogoSlideAnimate ? style.hideDuplicatedLogo : ""
                  }`}
                >
                  <motion.div
                    initial={"hidden"}
                    animate={logoAnimation}
                    variants={customLogoAnimationVariants}
                    className={style.mainLogo}
                  >
                    <Link
                      to={currentLanguage === "en" ? "/" : "/" + currentLanguage + "/"}
                      style={{
                        width: "100%",
                        pointerEvents: isHomePage ? "none" : "all",
                      }}
                      className={style.mainLogoLink}
                    >
                      <motion.div
                        className={style.logoExceptionHolder}
                        initial={"visible"}
                        animate={logoSlideAnimation}
                        variants={logoSlideAnimationVariants}
                        style={{
                          maxWidth: isHomePage ? logoSlideWidth : "100px",
                        }}
                      >
                        <Logo type={"light"} animation={logoSlideAnimation} />
                        <Logo type={"dark"} animation={logoSlideAnimation} />
                      </motion.div>
                    </Link>
                  </motion.div>
                  <div className={style.menu} onMouseEnter={() => openMenu()}>
                    <div className={style.menuHolder}>
                      <ul className={style.visibleMenu}>
                        {menuItems && menuItems.length > 3
                          ? menuItems
                              .slice(0, -3)
                              .map((item) => (
                                <NavigationItem
                                  key={item.id}
                                  id={item.id}
                                  url={item.path}
                                  label={item.label}
                                  currentPath={
                                    data && data.wpPage ? data.wpPage.link : ""
                                  }
                                />
                              ))
                          : menuItems.map((item) => (
                              <NavigationItem
                                key={item.id}
                                id={item.id}
                                url={item.path}
                                label={item.label}
                                currentPath={data && data.wpPage ? data.wpPage.link : ""}
                              />
                            ))}
                      </ul>
                      <motion.ul
                        className={style.hiddenMenu}
                        initial={"hidden"}
                        animate={hiddenNavigationContainerAnimation}
                        variants={hiddenNavigationContainerAnimationVariants}
                        custom={hiddenNavigationRenderWidth}
                        ref={hiddenNavigationElement}
                      >
                        {menuItems &&
                          menuItems.length > 3 &&
                          menuItems
                            .slice(-3)
                            .map((nav, index) => (
                              <HiddenNavigationItem
                                key={nav.id}
                                url={nav.path}
                                label={nav.label}
                                id={nav.id}
                                currentPath={data && data.wpPage ? data.wpPage.link : ""}
                                index={index}
                                animation={hiddenNavigationAnimation}
                              />
                            ))}
                      </motion.ul>
                      <MenuIcon
                        animation={menuIconAnimation}
                        theme={uiStore.headerTheme}
                      />
                    </div>
                    {menuItems && (
                      <div className={style.languageSwitcherHolder}>
                        <LanguageSwitcher theme={uiStore.headerTheme} />
                      </div>
                    )}
                  </div>
                  <div className={`${style.mobileMenu} ${style[uiStore.headerTheme]}`}>
                    <i className={"icon-menu"} onClick={() => setSidebar(true)}></i>
                  </div>
                </div>
                <Sidebar menuItems={menuItems} setSidebar={setSidebar} isOpen={sidebar} />
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    );
  })
);

interface NavigationItemProps {
  id: string;
  label: string;
  url: string;
  currentPath: string;
  index?: number;
  animation?: AnimationControls;
}

interface MenuIconProps {
  theme: "light" | "dark";
  animation: AnimationControls;
}

interface LogoProps {
  type: "light" | "dark";
  animation?: AnimationControls;
}

const NavigationItem = ({ url, label, id, currentPath }: NavigationItemProps) => (
  <motion.li
    key={id}
    initial={{
      opacity: 0,
      x: 20,
    }}
    animate={{
      opacity: 1,
      x: 0,
    }}
  >
    <Link to={url}>
      <span
        className={
          currentPath.includes(url) && currentPath !== "/" && currentPath !== ""
            ? style.underline
            : ""
        }
      >
        {label.toUpperCase()}
      </span>
    </Link>
  </motion.li>
);

const HiddenNavigationItem = ({
  url,
  label,
  id,
  currentPath,
  index,
  animation,
}: NavigationItemProps) => (
  <motion.li
    key={id}
    initial={"hidden"}
    animate={animation}
    variants={hiddenNavigationAnimationVariants}
    custom={index}
  >
    <Link to={url}>
      <span
        className={
          currentPath.includes(url) && currentPath !== "/" && currentPath !== ""
            ? style.underline
            : ""
        }
      >
        {label.toUpperCase()}
      </span>
    </Link>
  </motion.li>
);

const MenuIcon = ({ theme, animation }: MenuIconProps) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        x: 20,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
    >
      <motion.div
        className={`${style.menuIcon} ${style[theme]}`}
        initial={"visible"}
        animate={animation}
        variants={menuIconAnimationsVariants}
      >
        <motion.div variants={menuIconItemAnimationVariants} custom={1} />
        <motion.div variants={menuIconItemAnimationVariants} custom={2} />
        <motion.div variants={menuIconItemAnimationVariants} custom={3} />
      </motion.div>
    </motion.div>
  );
};

const Logo = ({ type, animation }: LogoProps) => {
  return (
    <div className={type === "dark" ? style.logoDark : style.logoLight}>
      <motion.img
        initial={"mainLogoVisible"}
        variants={mainLogoAnimationVariants}
        animate={animation}
        src={"/images/essen-logo-" + type + ".svg"}
        alt={"Essen International Logo"}
        width="800px"
        height="800px"
      />
      <motion.img
        initial={"duplicatedLogoHidden"}
        variants={duplicatedLogoAnimationVariants}
        animate={animation}
        src={"/images/essen-logo-" + type + ".svg"}
        alt={"Essen International Logo"}
        width="800px"
        height="800px"
      />
    </div>
  );
};
