// extracted by mini-css-extract-plugin
export var arrow = "SlideCarousel-module--arrow--8ba5f";
export var arrow_no = "SlideCarousel-module--arrow_no--23815";
export var arrow_yes = "SlideCarousel-module--arrow_yes--40644";
export var bigger = "SlideCarousel-module--bigger--f0621";
export var biggerMargins = "SlideCarousel-module--bigger-margins--7771c";
export var caption = "SlideCarousel-module--caption--9f1fc";
export var captionWrapper = "SlideCarousel-module--caption-wrapper--f281f";
export var cardCategory = "SlideCarousel-module--card-category--95b2b";
export var cardDescription = "SlideCarousel-module--card-description--e35fd";
export var carouselContainer = "SlideCarousel-module--carousel-container--2685f";
export var description = "SlideCarousel-module--description--5b927";
export var flex = "SlideCarousel-module--flex--83e71";
export var flex_1 = "SlideCarousel-module--flex_1--6a04c";
export var flex_center = "SlideCarousel-module--flex_center--1b546";
export var flex_center_col = "SlideCarousel-module--flex_center_col--8da6b";
export var flex_col = "SlideCarousel-module--flex_col--db645";
export var flex_column = "SlideCarousel-module--flex_column--16135";
export var flex_fix_center_col = "SlideCarousel-module--flex_fix_center_col--da304";
export var flex_fixed_center_col = "SlideCarousel-module--flex_fixed_center_col--43d9f";
export var flex_row = "SlideCarousel-module--flex_row--bc9cf";
export var header = "SlideCarousel-module--header--db808";
export var imageAnimationHolder = "SlideCarousel-module--image-animation-holder--ba619";
export var imageFigure = "SlideCarousel-module--image-figure--8668e";
export var landscape = "SlideCarousel-module--landscape--5451b";
export var light = "SlideCarousel-module--light--705c5";
export var linkAnimation = "SlideCarousel-module--link-animation--71557";
export var portrait = "SlideCarousel-module--portrait--83cec";
export var rotating = "SlideCarousel-module--rotating--38996";
export var secondRowHolder = "SlideCarousel-module--second-row-holder--2f5a8";
export var sliderHolder = "SlideCarousel-module--slider-holder--5fb34";
export var square = "SlideCarousel-module--square--68ead";
export var swiperSlide = "SlideCarousel-module--swiper-slide--07153";
export var swiperWrapper = "SlideCarousel-module--swiper-wrapper--2199f";
export var titleHolder = "SlideCarousel-module--title-holder--fce5f";
export var video = "SlideCarousel-module--video--503dc";
export var withInformation = "SlideCarousel-module--with-information--a4019";
export var withLink = "SlideCarousel-module--with-link--f2454";
export var withPaddingBottom = "SlideCarousel-module--with-padding-bottom--c40bf";