import React from "react";
import { motion } from "framer-motion";
import { inject, observer } from "mobx-react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container, Row, Col } from "~components";
import { UIStore } from "~stores";
import { ITextAndImage } from "~models";
import {
  imageAnimationVariants,
  imageHolderAnimationVariants,
  staggerChildren,
  staggerChildrenItem,
} from "~libs/framer/variants";

import * as style from "./TextAndImage.module.scss";

interface Props {
  section: ITextAndImage;
  uiStore?: UIStore;
}

export const TextAndImage = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { sectionTitle, sectionText, image, styleKey, transition } = section;
    const { contentColor } = uiStore;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="TextAndImage"
        className={`${styleKey === "figure-right" ? style.imageRight : ""}`}
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
      >
        <Container fluid>
          <Row className={style.figureRow}>
            <Col
              md={`${styleKey === "figure-top" ? 12 : 6}`}
              lg={`${styleKey === "figure-top" ? 12 : 5}`}
              className={`
                ${styleKey === "figure-right" ? "offset-md-1" : ""}`}
            >
              <motion.figure
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={imageHolderAnimationVariants}
                className={style.imageAnimationHolder}
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={imageAnimationVariants}
                >
                  <GatsbyImage
                    image={image?.localFile.childImageSharp.image}
                    style={{ width: "100%" }}
                    alt={image?.caption || ""}
                  />
                </motion.div>
              </motion.figure>
            </Col>
            <Col
              md={`${styleKey === "figure-top" ? 12 : 5}`}
              lg={`${styleKey === "figure-top" ? 12 : 4}`}
              className={`${
                styleKey === "figure-left"
                  ? "offset-lg-2"
                  : styleKey === "figure-right"
                  ? "offset-lg-1"
                  : styleKey === "figure-top"
                  ? style.figureTop
                  : ""
              }`}
            >
              <motion.div
                className={style.content}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={staggerChildren}
              >
                {sectionTitle && (
                  <motion.h4
                    variants={staggerChildrenItem}
                    dangerouslySetInnerHTML={{ __html: sectionTitle }}
                    style={{ color: contentColor }}
                  />
                )}
                <motion.div
                  variants={staggerChildrenItem}
                  dangerouslySetInnerHTML={{ __html: sectionText }}
                  style={{ color: contentColor }}
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
