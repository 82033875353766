// extracted by mini-css-extract-plugin
export var arrow = "LatestInsight-module--arrow--d92fd";
export var arrow_no = "LatestInsight-module--arrow_no--dc46f";
export var arrow_yes = "LatestInsight-module--arrow_yes--bf4bd";
export var container = "LatestInsight-module--container--236dd";
export var content = "LatestInsight-module--content--8d67a";
export var contentBottom = "LatestInsight-module--content-bottom--94f2c";
export var contentUp = "LatestInsight-module--content-up--9e9c9";
export var flex = "LatestInsight-module--flex--1c3c7";
export var flex_1 = "LatestInsight-module--flex_1--b4a1c";
export var flex_center = "LatestInsight-module--flex_center--07836";
export var flex_center_col = "LatestInsight-module--flex_center_col--17ecb";
export var flex_col = "LatestInsight-module--flex_col--fb4ee";
export var flex_column = "LatestInsight-module--flex_column--63515";
export var flex_fix_center_col = "LatestInsight-module--flex_fix_center_col--3e036";
export var flex_fixed_center_col = "LatestInsight-module--flex_fixed_center_col--ced13";
export var flex_row = "LatestInsight-module--flex_row--13071";
export var hrHolderFirst = "LatestInsight-module--hr-holder-first--e8917";
export var imageAnimationHolder = "LatestInsight-module--image-animation-holder--e76be";
export var itemRow = "LatestInsight-module--item-row--6e256";
export var linkAnimation = "LatestInsight-module--link-animation--71d04";
export var rotating = "LatestInsight-module--rotating--0905b";
export var swiperWrapper = "LatestInsight-module--swiper-wrapper--1f7bb";