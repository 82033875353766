import React from "react";
import { motion } from "framer-motion";
import { observer, inject } from "mobx-react";

import { ICaseHeader } from "~models";
import { Container, Row, Col, Label } from "~components";
import { UIStore } from "~stores";
import { fadeInItem } from "~libs/framer/variants";

import * as style from "./CaseHeader.module.scss";

interface Props {
  section: ICaseHeader;
  uiStore?: UIStore;
}

export const CaseHeader = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { sectionTitle, tag, partner, transition } = section;
    const { contentColor } = uiStore;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="CaseHeader"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
      >
        <Container fluid>
          <Row>
            <Col>
              <motion.h3
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInItem}
                custom={0}
                dangerouslySetInnerHTML={{ __html: sectionTitle }}
                style={{ color: contentColor }}
              />
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInItem}
                custom={0.1}
                className={style.titleHolder}
                style={{ color: contentColor }}
              >
                <h1 style={{ color: contentColor }}>{partner.toUpperCase()}</h1>
                <div className={style.tagHolder}>
                  {tag.length &&
                    tag.map((e, i) => (
                      <Label
                        key={i}
                        text={e.tagFields.tagName}
                        isWhite={uiStore.headerTheme === "light"}
                      ></Label>
                    ))}
                </div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
