// extracted by mini-css-extract-plugin
export var alignCenter = "styles-module--align-center--6ff19";
export var alignLeft = "styles-module--align-left--5c353";
export var alignRight = "styles-module--align-right--d4456";
export var arrow = "styles-module--arrow--6ad0f";
export var arrow_no = "styles-module--arrow_no--6de5a";
export var arrow_yes = "styles-module--arrow_yes--1cee4";
export var card = "styles-module--card--dfdc2";
export var cardHeading = "styles-module--card-heading--eefc2";
export var cardImage = "styles-module--card-image--e6ec4";
export var content = "styles-module--content--75892";
export var flex = "styles-module--flex--542fd";
export var flex_1 = "styles-module--flex_1--df8dd";
export var flex_center = "styles-module--flex_center--25e06";
export var flex_center_col = "styles-module--flex_center_col--f167a";
export var flex_col = "styles-module--flex_col--2674d";
export var flex_column = "styles-module--flex_column--37ac4";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--1ed73";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--42ab9";
export var flex_row = "styles-module--flex_row--eab16";
export var imageAnimationHolder = "styles-module--image-animation-holder--f3e58";
export var lightText = "styles-module--light-text--7cebc";
export var linkAnimation = "styles-module--link-animation--732f7";
export var rotating = "styles-module--rotating--66c74";
export var swiperWrapper = "styles-module--swiper-wrapper--8c13e";
export var tagHolder = "styles-module--tag-holder--3c3a5";