// extracted by mini-css-extract-plugin
export var arrow = "CenteredLogoHeader-module--arrow--bd02e";
export var arrow_no = "CenteredLogoHeader-module--arrow_no--b74f9";
export var arrow_yes = "CenteredLogoHeader-module--arrow_yes--82ac8";
export var btn = "CenteredLogoHeader-module--btn--4f835";
export var buttonHolder = "CenteredLogoHeader-module--button-holder--c8483";
export var customHeader = "CenteredLogoHeader-module--custom-header--056dc";
export var customHeaderInner = "CenteredLogoHeader-module--custom-header-inner--d8ad5";
export var flex = "CenteredLogoHeader-module--flex--20dca";
export var flex_1 = "CenteredLogoHeader-module--flex_1--f9440";
export var flex_center = "CenteredLogoHeader-module--flex_center--57816";
export var flex_center_col = "CenteredLogoHeader-module--flex_center_col--bc16e";
export var flex_col = "CenteredLogoHeader-module--flex_col--efca6";
export var flex_column = "CenteredLogoHeader-module--flex_column--3d2b4";
export var flex_fix_center_col = "CenteredLogoHeader-module--flex_fix_center_col--4a99f";
export var flex_fixed_center_col = "CenteredLogoHeader-module--flex_fixed_center_col--ffa30";
export var flex_row = "CenteredLogoHeader-module--flex_row--f7f11";
export var hideHeader = "CenteredLogoHeader-module--hide-header--810ef";
export var imageAnimationHolder = "CenteredLogoHeader-module--image-animation-holder--e1fbf";
export var linkAnimation = "CenteredLogoHeader-module--link-animation--7249e";
export var logo = "CenteredLogoHeader-module--logo--869aa";
export var rotating = "CenteredLogoHeader-module--rotating--c1fe3";
export var showHeaderWithBg = "CenteredLogoHeader-module--show-header-with-bg--4eb24";
export var swiperWrapper = "CenteredLogoHeader-module--swiper-wrapper--f3b9c";