// extracted by mini-css-extract-plugin
export var arrow = "ContentBody-module--arrow--a7668";
export var arrow_no = "ContentBody-module--arrow_no--0b924";
export var arrow_yes = "ContentBody-module--arrow_yes--fc347";
export var backButton = "ContentBody-module--backButton--f43ee";
export var bodyContentWrapper = "ContentBody-module--body-content-wrapper--377a6";
export var content = "ContentBody-module--content--f1e44";
export var contentRow = "ContentBody-module--content-row--9eb62";
export var descriptionWrapper = "ContentBody-module--description-wrapper--444db";
export var figure = "ContentBody-module--figure--4a548";
export var finalFigure = "ContentBody-module--final-figure--01da2";
export var flex = "ContentBody-module--flex--34f3f";
export var flex_1 = "ContentBody-module--flex_1--19825";
export var flex_center = "ContentBody-module--flex_center--76c65";
export var flex_center_col = "ContentBody-module--flex_center_col--d4fb2";
export var flex_col = "ContentBody-module--flex_col--f0a23";
export var flex_column = "ContentBody-module--flex_column--a734a";
export var flex_fix_center_col = "ContentBody-module--flex_fix_center_col--09a19";
export var flex_fixed_center_col = "ContentBody-module--flex_fixed_center_col--1f7e2";
export var flex_row = "ContentBody-module--flex_row--4efd6";
export var highlighted = "ContentBody-module--highlighted--608fc";
export var imageAnimationHolder = "ContentBody-module--image-animation-holder--7c5a0";
export var imageWrapper = "ContentBody-module--image-wrapper--801e9";
export var linkAnimation = "ContentBody-module--link-animation--05c84";
export var normalContent = "ContentBody-module--normal-content--3ebb3";
export var number = "ContentBody-module--number--f4319";
export var rotating = "ContentBody-module--rotating--c72f4";
export var swiperWrapper = "ContentBody-module--swiper-wrapper--0305e";
export var title = "ContentBody-module--title--064cd";