import React from "react";
import { inject, observer } from "mobx-react";
import { motion } from "framer-motion";

import { Container, Row, Col } from "~components";
import { IBodyText } from "~models";
import { UIStore } from "~stores";
import { fadeInItem } from "~libs/framer/variants";

import * as style from "./BodyText.module.scss";

interface Props {
  section: IBodyText;
  uiStore?: UIStore;
}

export const BodyText = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { titleAndText, transition } = section;
    const { contentColor } = uiStore;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="BodyText"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
      >
        <Container>
          <Row>
            <Col md={10} xl={6} className={"offset-md-1 offset-xl-3"}>
              {titleAndText?.map((tat, index) => (
                <div className={style.titleAndTextHolder} key={index}>
                  <motion.h4
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={fadeInItem}
                    custom={0}
                    dangerouslySetInnerHTML={{ __html: tat.titleAndTextGroup.title }}
                    className={"body-large"}
                    style={{ color: contentColor }}
                  />
                  <motion.p
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={fadeInItem}
                    custom={0.1}
                    dangerouslySetInnerHTML={{ __html: tat.titleAndTextGroup.text }}
                    style={{ color: contentColor }}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
