import React from "react";
import { motion } from "framer-motion";
import { inject, observer } from "mobx-react";

import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "~components";
import { IHeavyContent } from "~models";

import * as style from "./HeavyContent.module.scss";
import {
  fadeInItem,
  imageAnimationVariants,
  imageHolderAnimationVariants,
} from "~libs/framer/variants";
import {
  horizontalTitleHolderAnimationVariants,
  horizontalTitleAnimationVariants,
} from "~libs/framer/variants";
import { UIStore } from "~stores";

interface Props {
  section: IHeavyContent;
  uiStore?: UIStore;
}

export const HeavyContent = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const {
      introTitle,
      introTitleMobile,
      title,
      content,
      firstFigure,
      secondFigure,
      additionalContent,
    } = section;

    const { windowWidth } = uiStore;

    return (
      <section data-sectionname="HeavyContent">
        <Container fluid>
          {introTitle && (
            <Row>
              <Col>
                <div className={style.titleHolder}>
                  <motion.h2
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.6 }}
                    variants={horizontalTitleHolderAnimationVariants}
                    className={`${style.title} h1`}
                  >
                    {windowWidth > 539
                      ? introTitle
                          .split("|")
                          .map((t, index) => (
                            <motion.span
                              variants={horizontalTitleAnimationVariants}
                              custom={index}
                              key={index}
                              dangerouslySetInnerHTML={{ __html: t }}
                            />
                          ))
                      : introTitleMobile
                          .split("|")
                          .map((t, index) => (
                            <motion.span
                              variants={horizontalTitleAnimationVariants}
                              custom={index}
                              key={index}
                              dangerouslySetInnerHTML={{ __html: t }}
                            />
                          ))}
                  </motion.h2>
                </div>
              </Col>
            </Row>
          )}
          <Row className={style.contentHolderRow}>
            <Col className="offset-md-4" md={8}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInItem}
                className={style.contentHolder}
              >
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                <p dangerouslySetInnerHTML={{ __html: content }} />
              </motion.div>
            </Col>
          </Row>
          <Row className={style.imageHolderRow}>
            <Col md={4} lg={5}>
              <motion.figure
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={imageHolderAnimationVariants}
                className={style.imageAnimationHolder}
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={imageAnimationVariants}
                >
                  <GatsbyImage
                    image={firstFigure?.localFile.childImageSharp.image}
                    style={{ width: "100%" }}
                    alt={firstFigure?.caption || ""}
                  />
                </motion.div>
              </motion.figure>
            </Col>
            <Col md={7} lg={6} className="offset-md-1">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInItem}
                className={`${style.contentHolder} ${style.desktop}`}
              >
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                <p dangerouslySetInnerHTML={{ __html: content }} />
              </motion.div>
              <motion.figure
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={imageHolderAnimationVariants}
                className={style.imageAnimationHolder}
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={imageAnimationVariants}
                >
                  <GatsbyImage
                    image={secondFigure?.localFile.childImageSharp.image}
                    style={{ width: "100%" }}
                    alt={secondFigure?.caption || ""}
                  />
                </motion.div>
              </motion.figure>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInItem}
                dangerouslySetInnerHTML={{ __html: additionalContent }}
                className={style.additionalContent}
              />
            </Col>
          </Row>
        </Container>
      </section>
    );
  })
);
