// extracted by mini-css-extract-plugin
export var arrow = "Addresses-module--arrow--6c8a8";
export var arrow_no = "Addresses-module--arrow_no--1b8b3";
export var arrow_yes = "Addresses-module--arrow_yes--3d322";
export var customCursorHolder = "Addresses-module--custom-cursor-holder--ea94b";
export var figureRow = "Addresses-module--figure-row--bfae5";
export var flex = "Addresses-module--flex--891ab";
export var flex_1 = "Addresses-module--flex_1--797ec";
export var flex_center = "Addresses-module--flex_center--26658";
export var flex_center_col = "Addresses-module--flex_center_col--e4cbf";
export var flex_col = "Addresses-module--flex_col--ab814";
export var flex_column = "Addresses-module--flex_column--74ad2";
export var flex_fix_center_col = "Addresses-module--flex_fix_center_col--fd67e";
export var flex_fixed_center_col = "Addresses-module--flex_fixed_center_col--355d0";
export var flex_row = "Addresses-module--flex_row--d0467";
export var imageAnimationHolder = "Addresses-module--image-animation-holder--1b54c";
export var imageHolder = "Addresses-module--image-holder--ce476";
export var linkAnimation = "Addresses-module--link-animation--cc11a";
export var locationCard = "Addresses-module--location-card--471eb";
export var locationCardHolder = "Addresses-module--location-card-holder--2dfc1";
export var rotating = "Addresses-module--rotating--34549";
export var swiperWrapper = "Addresses-module--swiper-wrapper--d4125";