// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--08b39";
export var arrow_no = "styles-module--arrow_no--1f72d";
export var arrow_yes = "styles-module--arrow_yes--6c5ee";
export var button = "styles-module--button--675f3";
export var buttonIcon = "styles-module--button-icon--2a3c7";
export var buttonIconHolder = "styles-module--button-icon-holder--61e5f";
export var dark = "styles-module--dark--7cbd4";
export var disabled = "styles-module--disabled--5d4dc";
export var filled = "styles-module--filled--9f3c3";
export var flex = "styles-module--flex--6e581";
export var flex_1 = "styles-module--flex_1--65803";
export var flex_center = "styles-module--flex_center--bb9c6";
export var flex_center_col = "styles-module--flex_center_col--1fd3b";
export var flex_col = "styles-module--flex_col--668c2";
export var flex_column = "styles-module--flex_column--ca5a6";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--57b73";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--f5254";
export var flex_row = "styles-module--flex_row--88f40";
export var imageAnimationHolder = "styles-module--image-animation-holder--1bac6";
export var linkAnimation = "styles-module--link-animation--41ef6";
export var rotating = "styles-module--rotating--54aca";
export var secondary = "styles-module--secondary--ef86b";
export var small = "styles-module--small--d5e68";
export var swiperWrapper = "styles-module--swiper-wrapper--0c2b2";
export var tertiary = "styles-module--tertiary--bcfd1";