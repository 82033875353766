import React, { useRef, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Col, Container, Row } from "~components";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  fadeInItem,
  horizontalTitleHolderAnimationVariants,
  horizontalTitleAnimationVariants,
} from "~libs/framer/variants";
import { IImage, ISlideCarousel } from "~models";
import { UIStore } from "~stores";

import * as style from "./SlideCarousel.module.scss";
import { Link } from "gatsby";

interface SlideCarouselProps {
  section: ISlideCarousel;
  uiStore?: UIStore;
}

interface AutoSlideCarouselProps {
  figures: {
    category?: {
      id: string;
      title: string;
    }[];
    description?: string;
    aspect: "landscape" | "portrait" | "square";
    imageOrVideoGroup: {
      imageOrVideoSelect: "image" | "video";
      image: IImage;
      videoUrl: string;
    };
    caption: string;
    link: string;
  }[];
  reversed: boolean;
  uiStore?: UIStore;
}

const AutoSlideCarousel = inject("uiStore")(
  observer(({ figures, uiStore, reversed }: AutoSlideCarouselProps) => {
    const { isEqualOrGreatherThanBreakpoint, windowWidth } = uiStore;

    const defaultAnimationDuration = 80;
    const helperImageCount = 7;

    const [elementWidth, setElementWidth] = useState(null);
    const [elementHeight, setElementHeight] = useState(0);
    const [startingPoint, setStartingPoint] = useState(0);
    const [touchElement, setTouchElement] = useState(null);

    const arrangedFigures = [
      ...figures.slice(-helperImageCount),
      ...figures,
      ...figures.slice(0, helperImageCount),
    ];
    const gap = isEqualOrGreatherThanBreakpoint("md") ? 24 : 18;
    const totalSliderWidth = elementWidth + figures.length * gap;

    const elementRef = useRef(null);
    const sliderAnimation = useAnimation();
    const x = useMotionValue(-startingPoint);
    const sliderAnimationVariants = {
      x: reversed
        ? -startingPoint - totalSliderWidth
        : -startingPoint + windowWidth + gap + totalSliderWidth,
      transition: {
        duration: defaultAnimationDuration,
        ease: "linear",
      },
    };

    useEffect(() => {
      elementRef.current?.childNodes.forEach((node: HTMLElement, index: number) => {
        if (index >= helperImageCount && index < figures.length + helperImageCount) {
          setElementWidth((width) => width + node.offsetWidth);
        }
        if (reversed) {
          if (index === helperImageCount) {
            setStartingPoint(node.offsetLeft);
          }
        } else {
          if (index === helperImageCount + figures.length) {
            setStartingPoint(node.offsetLeft);
          }
        }
      });

      setElementHeight(elementRef.current.offsetHeight);
    }, []);

    useEffect(() => {
      x.set(reversed ? -startingPoint : -startingPoint + windowWidth + gap);
    }, [startingPoint, reversed, windowWidth]);

    const startWithCustomizedSpeed = () => {
      sliderAnimation.stop();
      const current = x.get();

      if (reversed) {
        if (current > -startingPoint) {
          x.set(-1 * (startingPoint + totalSliderWidth) + (current + startingPoint));
          startWithCustomizedSpeed();
        } else {
          const calc =
            ((totalSliderWidth + (current + startingPoint)) * defaultAnimationDuration) /
            totalSliderWidth;
          sliderAnimation.start({
            x: -startingPoint - totalSliderWidth,
            transition: { duration: calc, ease: "linear" },
          });
        }
      } else {
        if (current < -startingPoint + windowWidth + gap) {
          x.set(current + totalSliderWidth);
          startWithCustomizedSpeed();
        } else {
          // TODO: DETAY PROBLEMLERDEN BIRISI
          const calc =
            ((totalSliderWidth + (-current - startingPoint + windowWidth + gap)) *
              defaultAnimationDuration) /
            totalSliderWidth;
          // 5
          sliderAnimation.start({
            x: -startingPoint + windowWidth + gap + totalSliderWidth,
            transition: { duration: calc, ease: "linear" },
          });
        }
      }
    };

    const stopAnimation = () => {
      startWithCustomizedSpeed();
      setTimeout(() => {
        sliderAnimation.stop();
      }, 10);
    };

    const restartAnimation = () => {
      const current = x.get();
      if (reversed) {
        sliderAnimation.set({
          x:
            current < -startingPoint - totalSliderWidth
              ? current + totalSliderWidth
              : -startingPoint,
        });
        sliderAnimation.start(sliderAnimationVariants);
      } else {
        sliderAnimation.set({
          x:
            current > -startingPoint + windowWidth + gap + totalSliderWidth
              ? current - totalSliderWidth
              : -startingPoint + windowWidth + gap,
        });
        sliderAnimation.start(sliderAnimationVariants);
      }
    };

    const handleDragEnd = (e) => {
      // HANDLE DRAG END
      const offsetTop = elementRef.current.getBoundingClientRect().top;
      if (e.clientY < offsetTop || e.clientY > offsetTop + elementHeight) {
        startWithCustomizedSpeed();
      } else {
        isEqualOrGreatherThanBreakpoint("md")
          ? stopAnimation()
          : startWithCustomizedSpeed();
      }
    };

    return (
      <motion.div
        onViewportEnter={() => startWithCustomizedSpeed()}
        onViewportLeave={() => stopAnimation()}
        onHoverStart={() => stopAnimation()}
        onHoverEnd={() => startWithCustomizedSpeed()}
        onTouchStart={() => stopAnimation()}
        onTouchEnd={() => startWithCustomizedSpeed()}
      >
        <motion.div
          ref={elementRef}
          className={`${style.sliderHolder}`}
          drag="x"
          dragElastic={0}
          dragMomentum={false}
          animate={sliderAnimation}
          onAnimationComplete={() => restartAnimation()}
          onDragEnd={(e: MouseEvent) => handleDragEnd(e)}
          style={{
            x: x,
            cursor: "grab",
          }}
        >
          {arrangedFigures.length > 0 &&
            arrangedFigures.map((element, index) =>
              element.imageOrVideoGroup.imageOrVideoSelect === "image" ? (
                <div
                  className={`${
                    element.aspect === "portrait"
                      ? style.portrait
                      : element.aspect === "landscape"
                      ? style.landscape
                      : style.square
                  } ${style.imageFigure}`}
                  key={element.imageOrVideoGroup.image.id + index}
                  onTouchStart={() =>
                    setTouchElement(element.imageOrVideoGroup.image.id + index)
                  }
                  onTouchEnd={() => setTouchElement(null)}
                >
                  <GatsbyImage
                    draggable={false}
                    image={
                      element.imageOrVideoGroup.image.localFile.childImageSharp.image
                    }
                    alt={
                      element.imageOrVideoGroup.image.localFile.childImageSharp.alt
                        ? element.imageOrVideoGroup.image.localFile.childImageSharp.alt
                        : element.caption || ""
                    }
                    onLoad={() => {}}
                  />
                  <p
                    style={{
                      opacity:
                        !isEqualOrGreatherThanBreakpoint("lg") &&
                        touchElement === element.imageOrVideoGroup.image.id + index
                          ? 1
                          : null,
                    }}
                    className={`${style.caption} ${
                      element.link && element.link.length > 0 ? style.withLink : ""
                    } ${
                      element.category || element.description ? style.withInformation : ""
                    }`}
                  >
                    {element.link && element.link.length > 0 ? (
                      <Link to={element.link} className={style.link}>
                        <span>
                          {element.caption} <i className={"icon-arrow-right"} />
                        </span>
                      </Link>
                    ) : (
                      <div className={style.captionWrapper}>
                        <span>{element.caption}</span>
                        {element.category &&
                          element.category.map((cat) => {
                            return (
                              <span key={cat.id} className={style.cardCategory}>
                                {cat.title}
                              </span>
                            );
                          })}
                      </div>
                    )}
                    {element.description && (
                      <p className={style.cardDescription}>{element.description}</p>
                    )}
                  </p>
                </div>
              ) : (
                <React.Fragment key={element.imageOrVideoGroup.videoUrl + index}>
                  <video
                    controls={false}
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    width={"100%"}
                    height={"100%"}
                    playsInline={true}
                  >
                    <source src={element.imageOrVideoGroup.videoUrl} />
                  </video>
                </React.Fragment>
              )
            )}
        </motion.div>
      </motion.div>
    );
  })
);

export const SlideCarousel = inject("uiStore")(
  observer(({ section, uiStore }: SlideCarouselProps) => {
    const { isEqualOrGreatherThanBreakpoint } = uiStore;
    const {
      title,
      description,
      figures,
      textColor,
      paddingBottom,
      hasSecondSlider,
      secondRowFigures,
      backgroundColor,
      biggerMargins,
    } = section;

    return (
      <section
        data-sectionname="SlideCarousel"
        className={`
          ${paddingBottom ? style.withPaddingBottom : ""}
          ${textColor === "light" ? style.light : style.dark}
          ${biggerMargins && style.biggerMargins}
        `}
        style={{
          backgroundColor: backgroundColor ?? "",
        }}
      >
        {(title || description) && (
          <Container className={style.container} fluid>
            <Row className={style.header}>
              {title && (
                <Col className={style.titleHolder}>
                  {isEqualOrGreatherThanBreakpoint("md") ? (
                    <motion.h2
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      variants={fadeInItem}
                      custom={0.2}
                      dangerouslySetInnerHTML={{ __html: title.replace("<br />", "") }}
                      className="h1"
                    />
                  ) : (
                    <motion.h2
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      variants={horizontalTitleHolderAnimationVariants}
                    >
                      {title.split("<br />").map((t, i) => {
                        return (
                          <motion.span
                            key={t}
                            variants={horizontalTitleAnimationVariants}
                            custom={i}
                            style={{
                              textAlign: i % 2 === 0 ? "right" : "left",
                            }}
                          >
                            <small dangerouslySetInnerHTML={{ __html: t }} />
                          </motion.span>
                        );
                      })}
                    </motion.h2>
                  )}
                </Col>
              )}
              <Col
                xl={title ? 6 : 12}
                className={`
              ${title ? "offset-xl-3" : ""} 
              ${style.description} 
              ${title ? "" : style.bigger}`}
              >
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={fadeInItem}
                  custom={0.2}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Col>
            </Row>
          </Container>
        )}
        <AutoSlideCarousel figures={figures} reversed={false} key={0} />
        {hasSecondSlider && (
          <div className={style.secondRowHolder}>
            <AutoSlideCarousel figures={secondRowFigures} reversed={true} key={1} />
          </div>
        )}
      </section>
    );
  })
);
