// extracted by mini-css-extract-plugin
export var allPortrait = "PartnerPortfolio-module--all-portrait--bcdd4";
export var arrow = "PartnerPortfolio-module--arrow--32ab1";
export var arrow_no = "PartnerPortfolio-module--arrow_no--13a30";
export var arrow_yes = "PartnerPortfolio-module--arrow_yes--2acae";
export var column = "PartnerPortfolio-module--column--0f87c";
export var flex = "PartnerPortfolio-module--flex--35f9e";
export var flex_1 = "PartnerPortfolio-module--flex_1--3fc74";
export var flex_center = "PartnerPortfolio-module--flex_center--fb6ab";
export var flex_center_col = "PartnerPortfolio-module--flex_center_col--058a6";
export var flex_col = "PartnerPortfolio-module--flex_col--1b56f";
export var flex_column = "PartnerPortfolio-module--flex_column--d546c";
export var flex_fix_center_col = "PartnerPortfolio-module--flex_fix_center_col--0d6c4";
export var flex_fixed_center_col = "PartnerPortfolio-module--flex_fixed_center_col--9b5b9";
export var flex_row = "PartnerPortfolio-module--flex_row--1d9ee";
export var imageAnimationHolder = "PartnerPortfolio-module--image-animation-holder--d6b11";
export var linkAnimation = "PartnerPortfolio-module--link-animation--1bb02";
export var portfolioHolder = "PartnerPortfolio-module--portfolio-holder--c714c";
export var rotating = "PartnerPortfolio-module--rotating--373c8";
export var sectionText = "PartnerPortfolio-module--section-text--aac4c";
export var startWithPortrait = "PartnerPortfolio-module--start-with-portrait--3accd";
export var swiperWrapper = "PartnerPortfolio-module--swiper-wrapper--d8dee";
export var tagHolder = "PartnerPortfolio-module--tag-holder--12e0c";
export var withLandscapeElements = "PartnerPortfolio-module--with-landscape-elements--06aea";