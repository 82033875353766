import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { tx } from "~libs/i18n";
import addToMailchimp from "gatsby-plugin-mailchimp";

import { IWhitePaperForm } from "~models";
import { Container, Row, Col, Button } from "~components";
import { UIStore } from "~stores";

import * as style from "./WhitePaperForm.module.scss";

interface Props {
  section: IWhitePaperForm;
  uiStore?: UIStore;
}

export const WhitePaperForm = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { formTitle, file } = section;
    const { whitePaperFormVisible, toggleWhitePaperForm } = uiStore;
    const [shake, setShake] = useState(false);

    const [formValues, setFormValues] = useState({
      name: "",
      lastName: "",
      companyName: "",
      email: "",
      privacy: false,
      newsletter: false,
    });

    const handleSubmit = (e) => {
      e.preventDefault();

      if (!formValues.privacy) {
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 400);
      }

      if (
        formValues.privacy &&
        formValues.name.length &&
        formValues.lastName.length &&
        formValues.companyName.length &&
        formValues.email.length
      ) {
        if (formValues.newsletter) {
          addToMailchimp(formValues.email, {
            FNAME: formValues.name,
            LNAME: formValues.lastName,
            CNAME: formValues.companyName,
          }).then((data) => {
            console.log(data);
          });
        }
        if (typeof window !== "undefined" && window) {
          var save = document.createElement("a");
          save.href = file.mediaItemUrl;
          save.download = file.mediaItemUrl;
          save.target = "_blank";
          document.body.appendChild(save);
          save.click();
          document.body.removeChild(save);
          toggleWhitePaperForm();
          window.scrollTo(0, 0);
          setFormValues({
            name: "",
            lastName: "",
            companyName: "",
            email: "",
            privacy: false,
            newsletter: false,
          });
        }
      }
    };

    return (
      <section
        data-sectionname="WhitePaperForm"
        className={`${style.whitePaperForm} ${
          whitePaperFormVisible ? style.visible : ""
        }`}
        id="download"
      >
        <Container fluid className={style.container}>
          <Row>
            <Col>
              <div className={style.innerHolder}>
                <Row>
                  <Col lg={10} md={12} className="offset-lg-1">
                    <h4
                      className={style.title}
                      dangerouslySetInnerHTML={{ __html: formTitle }}
                    />
                    <form onSubmit={handleSubmit}>
                      <div className={style.formItems}>
                        <div>
                          <input
                            type="text"
                            name="name"
                            value={formValues.name}
                            placeholder={tx("whitePaperForm.firstName")}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                [e.target.name]:
                                  e.target.value.length! >= 35
                                    ? formValues.name
                                    : e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            name="lastName"
                            value={formValues.lastName}
                            placeholder={tx("whitePaperForm.lastName")}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                [e.target.name]:
                                  e.target.value.length! >= 35
                                    ? formValues.lastName
                                    : e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            name={"companyName"}
                            value={formValues.companyName}
                            placeholder={tx("whitePaperForm.companyName")}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                [e.target.name]:
                                  e.target.value.length! >= 55
                                    ? formValues.companyName
                                    : e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div>
                          <input
                            type="email"
                            name="email"
                            value={formValues.email}
                            placeholder={tx("whitePaperForm.emailAddress")}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                [e.target.name]:
                                  e.target.value.length! >= 55
                                    ? formValues.email
                                    : e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className={style.checkboxHolder}>
                        <div className={`${style.checkbox}`} tabIndex={0}>
                          <input
                            type="checkbox"
                            name="newsletter"
                            id="newsletter"
                            checked={formValues.newsletter}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                [e.target.name]: e.target.checked,
                              })
                            }
                          />
                          <span></span>
                          <label htmlFor="newsletter">
                            {tx("whitePaperForm.newsletter")}
                          </label>
                        </div>
                        <div
                          className={`${style.checkbox} ${shake ? style.required : ""}`}
                          tabIndex={0}
                        >
                          <input
                            type="checkbox"
                            name="privacy"
                            id="privacy"
                            checked={formValues.privacy}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                [e.target.name]: e.target.checked,
                              })
                            }
                          />
                          <span></span>
                          <label
                            htmlFor="privacy"
                            dangerouslySetInnerHTML={{
                              __html: tx("whitePaperForm.privacy"),
                            }}
                          ></label>
                        </div>
                      </div>
                      <div className={style.buttonHolder}>
                        <Button icon={"icon-arrow-right"} filled={true} type="submit">
                          {tx("whitePaperForm.button")}
                        </Button>
                      </div>
                    </form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  })
);
