import React from "react";
import { inject, observer } from "mobx-react";
import { motion } from "framer-motion";

import { Container, Row, Col, Divider } from "~components";
import { UIStore } from "~stores";
import { ICompanyStatistics } from "~models";
import { staggerChildren, staggerChildrenItem } from "~libs/framer/variants";

import * as style from "./CompanyStatistics.module.scss";

interface StatisticBoxProps {
  number: string;
  title: string;
  text: string;
  isBigger: boolean;
  index?: number;
}

const StatisticBox = ({ number, title, text, isBigger, index }: StatisticBoxProps) => {
  return (
    <motion.div
      className={style.statisticBox}
      variants={staggerChildren}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <motion.h2
        variants={staggerChildrenItem}
        className={number.includes("|") ? style.withAnimation : ""}
        style={{
          marginRight: index === 4 ? "14px" : "0",
          height: !isBigger ? "48px" : "100px",
        }}
      >
        {number.split("|").map((num, index) =>
          (num.startsWith("[") && num.endsWith("]")) ||
          (num.startsWith("(") && num.endsWith(")")) ? (
            <span
              key={index}
              style={{
                height: !isBigger ? "48px" : "100px",
                width: !isBigger ? "28px" : "60px",
              }}
            >
              <motion.small
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={{
                  hidden: {
                    y: num.startsWith("(")
                      ? isBigger
                        ? -24 * (num.slice(1, num.length - 1).length - 1)
                        : -50 * (num.slice(1, num.length - 1).length - 1)
                      : 0,
                  },
                  visible: {
                    y:
                      (!isBigger ? -48 : -100) *
                      (num.slice(1, num.length - 1).length - 1),
                    transition: {
                      duration: 1.6,
                      ease: [0.22, 0, 0.18, 1],
                    },
                  },
                }}
              >
                {num
                  .slice(1, num.length - 1)
                  .split("")
                  .map((n, nIndex) => (
                    <i key={nIndex}>{n}</i>
                  ))}
              </motion.small>
            </span>
          ) : (
            <span key={index} className={style.freeWidth}>
              {num}
            </span>
          )
        )}
      </motion.h2>
      <motion.p variants={staggerChildrenItem} className={"body-large"}>
        {title}
      </motion.p>
      <motion.p variants={staggerChildrenItem}>{text}</motion.p>
    </motion.div>
  );
};

export interface CompanyStatisticsProps {
  section: ICompanyStatistics;
  uiStore?: UIStore;
}

export const CompanyStatistics = inject("uiStore")(
  observer(({ section, uiStore }: CompanyStatisticsProps) => {
    const { statistics } = section;
    const { isEqualOrGreatherThanBreakpoint } = uiStore;

    return (
      <section data-sectionname="CompanyStatistics">
        <Container fluid>
          <Row>
            <Col>
              <Divider dark={true} />
            </Col>
          </Row>
          <Row className={style.boxRow}>
            {statistics.map((statistic, index) => (
              <Col key={index} md={6} lg={4}>
                <StatisticBox
                  number={statistic.statisticGroup.number}
                  title={statistic.statisticGroup.type}
                  text={statistic.statisticGroup.text}
                  isBigger={isEqualOrGreatherThanBreakpoint("xl")}
                  index={index}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col>
              <Divider dark={true} />
            </Col>
          </Row>
        </Container>
      </section>
    );
  })
);
