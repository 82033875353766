import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";

import { IOpenPositions } from "~models";
import { ILink } from "~models/Link";
import { Button, Col, Container, Row } from "~components";
import { fadeInItem } from "~libs/framer/variants";
import { tx } from "~libs/i18n";

import * as style from "./OpenPositions.module.scss";

interface ItemProps {
  linkTitle: string;
  link: ILink;
  city: string;
  position: string;
}

const PositionItem = ({ link, linkTitle, city, position }: ItemProps) => {
  return (
    <div className={style.content}>
      <div>
        <h4>{position}</h4>
        <p>{city.toUpperCase()}</p>
      </div>
      <span className={style.linkHolder}>
        <Link to={link?.url} target={"_blank"}>
          <Button dark={true} icon={"icon-arrow-up-right"} withAnimation={false}>
            {linkTitle}
          </Button>
        </Link>
      </span>
    </div>
  );
};

interface Props {
  section: IOpenPositions;
}

export const OpenPositions = ({ section }: Props) => {
  const { sectionTitle, openPositionsList } = section;

  const limit = 20;

  const [visiblePositions, setVisiblePositions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let calculateVisible = limit * currentPage;

    let visible = openPositionsList
      ? [
          ...openPositionsList.filter((position, index) => {
            return index < calculateVisible;
          }),
        ]
      : [];

    setVisiblePositions([...visible]);
  }, [currentPage]);

  return (
    <section data-sectionname="OpenPositions" id="open-positions">
      <Container fluid>
        <Row>
          <Col>
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeInItem}
              className={`body-large ${style.title}`}
            >
              {sectionTitle}
            </motion.p>
          </Col>
        </Row>
        <motion.div>
          {visiblePositions.map((openPosition, index) => (
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.4 }}
              variants={fadeInItem}
              custom={0.2}
              key={openPosition.openPositionGroup.position}
            >
              <Row key={index} className={style.positionRow}>
                <Col>
                  <PositionItem
                    link={openPosition.openPositionGroup.linkButton.buttonLink}
                    linkTitle={openPosition.openPositionGroup.linkButton.buttonText}
                    city={openPosition.openPositionGroup.city}
                    position={openPosition.openPositionGroup.position}
                  ></PositionItem>
                </Col>
              </Row>
            </motion.div>
          ))}
        </motion.div>
        {openPositionsList && visiblePositions.length < openPositionsList.length && (
          <Row>
            <Col className={style.buttonHolder}>
              <Button
                secondary={true}
                dark={false}
                filled={false}
                icon={"icon-plus"}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                {tx("general.loadMore")}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};
