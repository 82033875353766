// extracted by mini-css-extract-plugin
export var additionalContent = "HeavyContent-module--additional-content--e2608";
export var arrow = "HeavyContent-module--arrow--d80ad";
export var arrow_no = "HeavyContent-module--arrow_no--b86e5";
export var arrow_yes = "HeavyContent-module--arrow_yes--dde8d";
export var contentHolder = "HeavyContent-module--content-holder--0f649";
export var contentHolderRow = "HeavyContent-module--content-holder-row--999d9";
export var desktop = "HeavyContent-module--desktop--963ea";
export var flex = "HeavyContent-module--flex--b97fc";
export var flex_1 = "HeavyContent-module--flex_1--c010e";
export var flex_center = "HeavyContent-module--flex_center--0b83d";
export var flex_center_col = "HeavyContent-module--flex_center_col--31cc9";
export var flex_col = "HeavyContent-module--flex_col--98c62";
export var flex_column = "HeavyContent-module--flex_column--bd7f9";
export var flex_fix_center_col = "HeavyContent-module--flex_fix_center_col--06409";
export var flex_fixed_center_col = "HeavyContent-module--flex_fixed_center_col--35aa6";
export var flex_row = "HeavyContent-module--flex_row--9b4aa";
export var imageAnimationHolder = "HeavyContent-module--image-animation-holder--fe54e";
export var imageHolderRow = "HeavyContent-module--image-holder-row--e6e85";
export var linkAnimation = "HeavyContent-module--link-animation--be247";
export var rotating = "HeavyContent-module--rotating--2d544";
export var swiperWrapper = "HeavyContent-module--swiper-wrapper--80146";
export var title = "HeavyContent-module--title--83678";