import React from "react";
import { motion } from "framer-motion";
import { inject, observer } from "mobx-react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container, Row, Col, VideoElement } from "~components";
import { IImageOrVideo } from "~models";
import { UIStore } from "~stores";
import {
  imageAnimationVariants,
  imageHolderAnimationVariants,
} from "~libs/framer/variants";

import * as style from "./ImageOrVideo.module.scss";

interface Props {
  section: IImageOrVideo;
  uiStore?: UIStore;
}

export const ImageOrVideo = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { imageOrVideoGroup, styleKey, transition, styleKeyImageHeight } = section;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="ImageOrVideo"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
        className={`${styleKey === "full_bleed" ? style.fullException : ""}`}
      >
        <Container fluid>
          <Row>
            <Col>
              <div className={`${styleKey === "full_bleed" ? style.full : ""}`}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={imageHolderAnimationVariants}
                  className={style.imageAnimationHolder}
                >
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={imageAnimationVariants}
                  >
                    {imageOrVideoGroup.imageOrVideoSelect === "image" && (
                      <div
                        className={`
                    ${style.imageHolder} 
                    ${styleKeyImageHeight === "double_size" ? style.doubleSize : ""}
                    ${styleKeyImageHeight === "half_size" ? style.halfSize : ""}
                  }`}
                      >
                        <GatsbyImage
                          image={imageOrVideoGroup.image?.localFile.childImageSharp.image}
                          style={{ width: "100%" }}
                          alt={imageOrVideoGroup.image?.caption}
                        />
                      </div>
                    )}
                    {imageOrVideoGroup.imageOrVideoSelect !== "image" && (
                      <div
                        className={`${style.imageHolder}
                      ${styleKeyImageHeight === "double_size" ? style.doubleSize : ""}
                      ${styleKeyImageHeight === "half_size" ? style.halfSize : ""}
                    `}
                      >
                        <div
                          className={`${style.imageHolder} ${
                            styleKey === "full_bleed" ? style.full : ""
                          }`}
                        >
                          <VideoElement
                            url={
                              imageOrVideoGroup.type === "vimeo"
                                ? imageOrVideoGroup.videoUrl
                                : imageOrVideoGroup.videoFile.mediaItemUrl
                            }
                            poster={imageOrVideoGroup.videoPoster}
                            controls={imageOrVideoGroup.videoSettings.controls}
                            loop={imageOrVideoGroup.videoSettings.loop}
                            customControls={
                              imageOrVideoGroup.videoSettings.customControls
                            }
                            isDark={imageOrVideoGroup.videoSettings.customControlsTheme}
                            freeAspectRatio={true}
                          />
                        </div>
                      </div>
                    )}
                  </motion.div>
                </motion.div>
              </div>
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
