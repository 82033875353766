import React from "react";

import { IUpperFooter } from "~models";
import { Container, Row, Col, Button } from "~components";
import { UIStore } from "~stores";
import { staggerChildren, staggerChildrenItem, fadeInItem } from "~libs/framer/variants";
import { Link } from "gatsby";
import { motion } from "framer-motion";

import * as style from "./UpperFooter.module.scss";

interface Props {
  section: IUpperFooter;
  uiStore?: UIStore;
}

const AnimatedButton = ({ button, isDark }) => (
  <motion.div
    variants={fadeInItem}
    custom={0.4}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
  >
    <Link to={button.buttonLink?.url}>
      <Button icon={"icon-arrow-right"} filled={isDark} secondary={!isDark}>
        {button.buttonText}
      </Button>
    </Link>
  </motion.div>
);

const SectionText = ({ text, description }: { text: string; description?: string }) => (
  <>
    <motion.h4
      className={style.sectionText}
      variants={staggerChildren}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 1 }}
    >
      {text.split("<br/>").map((t, index) => (
        <motion.span
          variants={staggerChildrenItem}
          key={index}
          dangerouslySetInnerHTML={{ __html: t }}
        />
      ))}
    </motion.h4>
    {description && (
      <motion.p
        className={style.description}
        variants={fadeInItem}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        {description}
      </motion.p>
    )}
  </>
);

export const UpperFooter = ({ section }: Props) => {
  const { sectionText, description, linkButton, secondLinkButton, theme } = section;
  return (
    <section
      data-sectionname="UpperFooter"
      className={`${theme === "light" ? style.light : ""}`}
    >
      <Container>
        <Row>
          <Col md={10} className={"offset-md-1"}>
            <SectionText text={sectionText} description={description} />
          </Col>
        </Row>
        {linkButton && (
          <Row className={style.buttonRow}>
            <Col>
              <div className={style.buttonHolder}>
                <AnimatedButton button={linkButton} isDark={theme === "dark"} />
                {secondLinkButton &&
                  secondLinkButton.buttonLink &&
                  secondLinkButton.buttonText && (
                    <AnimatedButton button={secondLinkButton} isDark={theme === "dark"} />
                  )}
                {description && description.endsWith("OUR CEO ALEX HOLMÉN") && (
                  <AnimatedButton
                    isDark={theme === "dark"}
                    button={{
                      buttonText: "CONNECT",
                      buttonLink: {
                        target: "",
                        title: "CONNECT",
                        url: "https://www.linkedin.com/in/alex-holm%C3%A9n-57bb21b/",
                      },
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};
