import React, { Component } from "react";
import { ISections } from "~models";
import {
  ImageOrVideo,
  ImageSlider,
  PartnerCitation,
  UpperFooter,
  BodyText,
  CaseHeader,
  ImageRow,
  IntroductiveText,
  Portal,
  ProjectScope,
  ScrollVideo,
} from "~sections";

interface SectionsProps {
  sections?: ISections;
}

export class CaseSections extends Component<SectionsProps> {
  render() {
    const { sections } = this.props;
    const renderedSections = (sections || []).map((section, i) => {
      switch (section?.__typename) {
        case "WpCase_Casefields_CaseHeader":
          return <CaseHeader key={section.__typename + i} section={section} />;
        case "WpCase_Casefields_Sections_ImageOrVideo":
          return <ImageOrVideo key={section.__typename + i} section={section} />;
        case "WpCase_Casefields_Sections_IntroductiveText":
          return <IntroductiveText key={section.__typename + i} section={section} />;
        case "WpCase_Casefields_Sections_BodyText":
          return <BodyText key={section.__typename + i} section={section} />;
        case "WpCase_Casefields_Sections_ProjectScope":
          return <ProjectScope key={section.__typename + i} section={section} />;
        case "WpCase_Casefields_Sections_ImageRow":
          return <ImageRow key={section.__typename + i} section={section} />;
        case "WpCase_Casefields_Sections_ImageSlider":
          return (
            <ImageSlider
              key={section.__typename + i}
              section={section}
              isInCaseDetail={true}
            />
          );
        case "WpCase_Casefields_Sections_PartnerCitation":
          return <PartnerCitation key={section.__typename + i} section={section} />;
        case "WpCase_Casefields_Portal":
          return <Portal key={section.__typename + i} section={section} />;
        case "WpCase_Casefields_UpperFooter":
          return <UpperFooter key={section.__typename + i} section={section} />;
        case "WpCase_Casefields_Sections_ScrollVideo":
          return <ScrollVideo key={section.__typename + i} section={section} />;
        default:
          return <div key={i.toString()} />;
      }
    });
    return renderedSections;
  }
}
