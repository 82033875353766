// extracted by mini-css-extract-plugin
export var arrow = "LeadershipContacts-module--arrow--16f11";
export var arrow_no = "LeadershipContacts-module--arrow_no--ab128";
export var arrow_yes = "LeadershipContacts-module--arrow_yes--4a9f3";
export var contact = "LeadershipContacts-module--contact--e4818";
export var contactHolder = "LeadershipContacts-module--contact-holder--3b698";
export var email = "LeadershipContacts-module--email--1d389";
export var flex = "LeadershipContacts-module--flex--b44cc";
export var flex_1 = "LeadershipContacts-module--flex_1--c447a";
export var flex_center = "LeadershipContacts-module--flex_center--85b8f";
export var flex_center_col = "LeadershipContacts-module--flex_center_col--7cbcf";
export var flex_col = "LeadershipContacts-module--flex_col--62b54";
export var flex_column = "LeadershipContacts-module--flex_column--175ae";
export var flex_fix_center_col = "LeadershipContacts-module--flex_fix_center_col--5cb9c";
export var flex_fixed_center_col = "LeadershipContacts-module--flex_fixed_center_col--42750";
export var flex_row = "LeadershipContacts-module--flex_row--de3a5";
export var imageAnimationHolder = "LeadershipContacts-module--image-animation-holder--a08a0";
export var link = "LeadershipContacts-module--link--a99f2";
export var linkAnimation = "LeadershipContacts-module--link-animation--ae25c";
export var rotating = "LeadershipContacts-module--rotating--24460";
export var swiperWrapper = "LeadershipContacts-module--swiper-wrapper--d3379";
export var title = "LeadershipContacts-module--title--0294c";