// extracted by mini-css-extract-plugin
export var arrow = "TextAndImage-module--arrow--f7179";
export var arrow_no = "TextAndImage-module--arrow_no--2c7a5";
export var arrow_yes = "TextAndImage-module--arrow_yes--d24c8";
export var content = "TextAndImage-module--content--d7b9d";
export var figureRow = "TextAndImage-module--figure-row--e301e";
export var figureTop = "TextAndImage-module--figure-top--8d03e";
export var flex = "TextAndImage-module--flex--95a50";
export var flex_1 = "TextAndImage-module--flex_1--7d194";
export var flex_center = "TextAndImage-module--flex_center--890fe";
export var flex_center_col = "TextAndImage-module--flex_center_col--4fd4f";
export var flex_col = "TextAndImage-module--flex_col--f23a8";
export var flex_column = "TextAndImage-module--flex_column--424b8";
export var flex_fix_center_col = "TextAndImage-module--flex_fix_center_col--e8285";
export var flex_fixed_center_col = "TextAndImage-module--flex_fixed_center_col--ff42a";
export var flex_row = "TextAndImage-module--flex_row--39405";
export var imageAnimationHolder = "TextAndImage-module--image-animation-holder--d8693";
export var imageRight = "TextAndImage-module--image-right--40fdb";
export var linkAnimation = "TextAndImage-module--link-animation--4be5f";
export var rotating = "TextAndImage-module--rotating--e9c11";
export var swiperWrapper = "TextAndImage-module--swiper-wrapper--0bd56";