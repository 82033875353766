// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--cc469";
export var arrow_no = "styles-module--arrow_no--7d298";
export var arrow_yes = "styles-module--arrow_yes--89352";
export var buttonHolder = "styles-module--button-holder--5848f";
export var cookieBanner = "styles-module--cookie-banner--d5ad5";
export var cookieBannerInner = "styles-module--cookie-banner-inner--770da";
export var cookieBannerLink = "styles-module--cookie-banner-link--4bef9";
export var flex = "styles-module--flex--483b1";
export var flex_1 = "styles-module--flex_1--65601";
export var flex_center = "styles-module--flex_center--8bf5d";
export var flex_center_col = "styles-module--flex_center_col--0c322";
export var flex_col = "styles-module--flex_col--9511b";
export var flex_column = "styles-module--flex_column--9230c";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--63e0f";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--b7cc0";
export var flex_row = "styles-module--flex_row--a7f21";
export var imageAnimationHolder = "styles-module--image-animation-holder--fde0f";
export var linkAnimation = "styles-module--link-animation--5b275";
export var rotating = "styles-module--rotating--6fcb3";
export var swiperWrapper = "styles-module--swiper-wrapper--e3333";