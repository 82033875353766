import React from "react";
import { motion } from "framer-motion";
import { inject, observer } from "mobx-react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import { VideoElement, CustomCursor } from "~components";
import { IImageOrVideoGroup } from "~models";
import { fadeInItem } from "~libs/framer/variants";
import { tx } from "~libs/i18n";
import { UIStore } from "~stores";

import * as style from "./GridCard.module.scss";

interface GridCardProps {
  title: string;
  imageOrVideo?: IImageOrVideoGroup;
  link?: string;
  children?: React.ReactNode;
  uiStore?: UIStore;
}

export const GridCard = inject("uiStore")(
  observer(({ title, imageOrVideo, link, children, uiStore }: GridCardProps) => {
    const { setCustomCursorEvent, isEqualOrGreatherThanBreakpoint } = uiStore;

    return (
      <motion.div
        className={style.gridCard}
        variants={fadeInItem}
        custom={0}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <div
          onMouseEnter={() =>
            setCustomCursorEvent(isEqualOrGreatherThanBreakpoint("lg") && link && true)
          }
          onMouseLeave={() => setCustomCursorEvent(false)}
        >
          <figure>
            {imageOrVideo.imageOrVideoSelect === "image" && (
              <div className={style.imageHolder}>
                <GatsbyImage
                  image={imageOrVideo.image?.localFile.childImageSharp.image}
                  style={{ width: "100%" }}
                  alt={imageOrVideo?.image.caption || ""}
                />
              </div>
            )}
            {imageOrVideo.imageOrVideoSelect === "video" && (
              <div className={style.imageHolder}>
                <VideoElement
                  url={
                    imageOrVideo.type === "vimeo"
                      ? imageOrVideo.videoUrl
                      : imageOrVideo.videoFile.mediaItemUrl
                  }
                  poster={imageOrVideo.videoPoster}
                  controls={imageOrVideo.videoSettings.controls}
                  loop={imageOrVideo.videoSettings.loop}
                  freeAspectRatio={false}
                />
              </div>
            )}
          </figure>
          <h3>{title}</h3>
          {children && <div>{children}</div>}
          {link && <Link to={link} />}
          {link && <CustomCursor text={tx("cursor.viewWork")} />}
        </div>
      </motion.div>
    );
  })
);
