import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import { motion, useViewportScroll, useTransform } from "framer-motion";

import { UIStore } from "~stores";

import * as style from "./ScatteredGallery.module.scss";
import { Button, Col, Container, Row } from "~views/shared/components";
import { IScatteredGallery } from "~models";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import {
  firstGroupSizes,
  firstGroupSizesMobile,
  secondGroupSizes,
  secondGroupSizesMobile,
  thirdGroupSizes,
  thirdGroupSizesMobile,
  firstRowGrouping,
  secondRowGrouping,
  zIndexOne,
  zIndexTwo,
  zIndexThree,
} from "~libs/utils/scatterImages";

interface ScatteredGalleryProps {
  uiStore?: UIStore;
  section: IScatteredGallery;
}

export const ScatteredGallery = inject("uiStore")(
  observer(({ uiStore, section }: ScatteredGalleryProps) => {
    const {
      title,
      mobileTitle,
      firstGroupImages,
      secondGroupImages,
      thirdGroupImages,
      subtitle,
      linkButton,
      transition,
    } = section;
    const { isEqualOrGreatherThanBreakpoint } = uiStore;

    // Calculate section height
    const groupingHolderRef = useRef(null);
    const [sectionHeight, setSectionHeight] = useState(0);

    // Randomize image group to show
    const [num, setNum] = useState(null);
    useEffect(() => {
      function randomNumberInRange(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      setNum(randomNumberInRange(0, 2));
      // setNum(1);
    }, []);

    useEffect(() => {
      if (groupingHolderRef.current) {
        groupingHolderRef.current.childNodes.forEach((node) => {
          setSectionHeight((height) => height + node.offsetHeight);
        });
      }
    }, [num]);

    // Construct the image groupings
    const randomConstruct = [
      {
        group: 1,
        images: firstGroupImages,
        grouping: firstRowGrouping,
        sizes: isEqualOrGreatherThanBreakpoint("xl")
          ? firstGroupSizes
          : firstGroupSizesMobile,
        zIndex: zIndexOne,
      },
      {
        group: 2,
        images: secondGroupImages,
        grouping: secondRowGrouping,
        sizes: isEqualOrGreatherThanBreakpoint("xl")
          ? secondGroupSizes
          : secondGroupSizesMobile,
        zIndex: zIndexTwo,
      },
      {
        group: 3,
        images: thirdGroupImages,
        grouping: secondRowGrouping,
        sizes: isEqualOrGreatherThanBreakpoint("xl")
          ? thirdGroupSizes
          : thirdGroupSizesMobile,
        zIndex: zIndexThree,
      },
    ];

    // Parallax helper func
    const { scrollYProgress } = useViewportScroll();
    const transform = (travel: number) =>
      useTransform(scrollYProgress, [0, 1], [0, travel]);

    // The images component
    const ImageElement = ({ index, image, sizes, zIndex }) => {
      return (
        <motion.div
          style={{
            zIndex: zIndex[index],
            y: transform(
              index % 2 === 0
                ? 100
                : isEqualOrGreatherThanBreakpoint("xl")
                ? 2600
                : isEqualOrGreatherThanBreakpoint("md")
                ? 500
                : 200
            ),
            rotate: transform(index % 2 === 0 ? -30 : 30),
          }}
        >
          <GatsbyImage
            style={{
              width: `${sizes[index]}vw`,
            }}
            image={image.image.localFile.childImageSharp.image}
            alt={"test"}
          />
        </motion.div>
      );
    };

    // Assign and return images per row
    const ScatteredImages = ({ group, images, grouping, sizes, zIndex }) => {
      const returnImages = (images) => {
        const wrapper = {};
        const result = [];

        for (let i = 0; i < 3; i++) {
          wrapper[`row${i}`] = [];
        }
        images.map((image, i: number) => {
          grouping.map((range: [number, number], idx: number) => {
            if (i >= range[0] && i <= range[1]) {
              wrapper[`row${idx}`].push(
                <ImageElement
                  index={i}
                  image={image}
                  sizes={sizes}
                  zIndex={zIndex}
                  key={i}
                />
              );
            }
          });
        });

        for (let i = 0; i < Object.keys(wrapper).length; i++) {
          result.push(
            <div key={i} className={style.imageHolders}>
              {wrapper[`row${i}`]}
            </div>
          );
        }

        return result;
      };

      return (
        <div
          ref={groupingHolderRef}
          style={{
            paddingBottom: isEqualOrGreatherThanBreakpoint("xl")
              ? `calc(${sectionHeight}px - 100vh)`
              : `calc(100vh - 500px)`,
          }}
          className={`${style.groupingHolder} ${
            group === 1
              ? style.firstGroupHolder
              : group === 2
              ? style.secondGroupHolder
              : style.thirdGroupHolder
          }`}
        >
          {returnImages(images)}
        </div>
      );
    };

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="ScatteredGallery"
        onViewportEnter={() => changeBackgroundColor()}
      >
        <Container fluid>
          <Row>
            <Col className={style.container}>
              <motion.div className={style.stickyTitle}>
                {num !== null && (
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: isEqualOrGreatherThanBreakpoint("sm") ? title : mobileTitle,
                    }}
                    className={style.title}
                  />
                )}
              </motion.div>
              <motion.div className={style.stickyButton}>
                <Link to={linkButton.url}>
                  <Button dark icon="icon-arrow-down">
                    {linkButton.title}
                  </Button>
                </Link>
              </motion.div>
              {num !== null && (
                <ScatteredImages
                  group={randomConstruct[num].group}
                  images={randomConstruct[num].images}
                  grouping={randomConstruct[num].grouping}
                  sizes={randomConstruct[num].sizes}
                  zIndex={randomConstruct[num].zIndex}
                />
              )}
            </Col>
            <Col md={8} className={`offset-md-2 ${style.subtitle}`}>
              <p className={`body-large`}>{subtitle}</p>
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
