import React from "react";
import { motion, useAnimation } from "framer-motion";
import { inject, observer } from "mobx-react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";

import { Container, Row, Col } from "~components";
import { IImageSlider } from "~models";
import {
  sliderButtonAnimationVariants,
  sliderImageAnimationVariants,
  imageHolderAnimationVariants,
} from "~libs/framer/variants";
import { UIStore } from "~stores";

import * as style from "./ImageSlider.module.scss";
import "swiper/scss";

interface Props {
  section: IImageSlider;
  uiStore?: UIStore;
  isInCaseDetail?: boolean;
}

export const ImageSlider = inject("uiStore")(
  observer(({ section, uiStore, isInCaseDetail }: Props) => {
    const { images, transition } = section;
    const imageAnimation = useAnimation();

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="ImageSlider"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
      >
        <Container fluid className="bigger-container-exception">
          <Row>
            <Col>
              <div className={style.sliderHolder}>
                <Swiper
                  spaceBetween={24}
                  slidesPerView={3}
                  loop={true}
                  onSliderMove={() => {
                    imageAnimation.start("dragging");
                    imageAnimation.start("buttonHidden");
                  }}
                  onSlideChangeTransitionEnd={() => {
                    imageAnimation.start("dragEnd");
                    imageAnimation.start("buttonVisible");
                  }}
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className={`${style.imageHolder} ${
                          image.imageGroup.styleKey === "style_2"
                            ? style.portrait
                            : style.landscape
                        }`}
                      >
                        <motion.div
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          animate={imageAnimation}
                          variants={imageHolderAnimationVariants}
                          className={style.imageAnimationHolder}
                        >
                          <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            animate={imageAnimation}
                            variants={
                              isInCaseDetail
                                ? imageHolderAnimationVariants
                                : sliderImageAnimationVariants
                            }
                            className={style.figureHolder}
                          >
                            <GatsbyImage
                              image={
                                image.imageGroup.image?.localFile.childImageSharp.image
                              }
                              style={{ width: "100%" }}
                              alt={image.imageGroup.image?.caption}
                            />
                          </motion.div>
                        </motion.div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <motion.div
                  initial={"buttonVisible"}
                  animate={imageAnimation}
                  variants={sliderButtonAnimationVariants}
                  className={style.sliderDragButton}
                >
                  <i className={"icon-arrow-left"}></i>
                  <small>DRAG</small>
                  <i className={"icon-arrow-right"}></i>
                </motion.div>
              </div>
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
