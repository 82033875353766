import React, { Component } from "react";
import { ISections } from "~models";
import { ContentHeading, ContentBody, UpperFooter } from "~sections";

interface SectionProps {
  sections?: ISections;
  details: {
    tag: string;
    season: string;
    type: string;
  };
}

export class LatestSections extends Component<SectionProps> {
  render() {
    const { sections, details } = this.props;
    const renderedSections = (sections || []).map((section, i) => {
      switch (section?.__typename) {
        case "WpLatest_Latestfields_Sections_ContentHeading":
          return (
            <ContentHeading
              key={section.__typename + i}
              section={section}
              details={details}
            />
          );
        case "WpLatest_Latestfields_Sections_ContentBody":
          return <ContentBody key={section.__typename + i} section={section} />;
        case "WpLatest_Latestfields_Sections_UpperFooter":
          return <UpperFooter key={section.__typename + i} section={section} />;
        default:
          return <div key={i.toString()} />;
      }
    });
    return renderedSections;
  }
}
