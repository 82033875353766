import React, { useEffect, useState } from "react";
import { Illustrations } from "~libs/assets/illustrations";
import { motion } from "framer-motion";

export * from "./DraggableCard";

import * as style from "./DraggableCard.module.scss";

interface Props {
  title: string;
  description: string;
  gif: string;
}

export const DraggableCard = ({ title, description, gif }: Props) => {
  const [useGif, setUseGif] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setUseGif(true);
    }, 1000);
  }, []);
  return (
    <div className={style.draggableCard}>
      <div className={style.imageHolder}>
        <video
          muted
          loop
          playsInline
          autoPlay
          src={useGif ? `${Illustrations.illustration[gif]}` : ""}
        ></video>
        {/* <img
          src={useGif ? `/images/${gif}.gif` : ""}
          alt={gif}
          style={{ display: useGif ? "block" : "none" }}
        /> */}
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};
