import React, { Component } from "react";
import { graphql } from "gatsby";
import { ILocation, ISingleWordpressPage } from "~models";
import { withStore } from "~libs/mobx";
import { Template } from "~views/templates";
import "~libs/graphql";
import { inject, observer } from "mobx-react";
import { Seo } from "~views/shared/components";
import { Footer, Header, CustomHeader, CenteredLogoHeader } from "~views/layouts";
import { NavigationStore, UIStore, ContextStore } from "~stores";

interface Props {
  pageContext: any;
  location?: ILocation;
  data: {
    wpPage: ISingleWordpressPage;
  };
  contextStore: ContextStore;
  navigationStore?: NavigationStore;
  uiStore?: UIStore;
}

@inject("contextStore", "navigationStore", "uiStore")
@observer
class PageTemplate extends Component<Props> {
  constructor(props) {
    super(props);
    this.props.uiStore.setBackgroundColor(
      this.props.data.wpPage.pageFields.pageSettings.bgColor
    );
    this.props.uiStore.setDefaultBackgroundColor(
      this.props.data.wpPage.pageFields.pageSettings.bgColor || "#ffffff"
    );
  }
  componentDidMount() {
    const { pageContext, contextStore, navigationStore, data, uiStore } = this.props;

    const { initNavigationStore } = navigationStore;
    contextStore.context = pageContext;

    initNavigationStore(location);

    uiStore.setDefaultHeaderTheme(
      data.wpPage.pageFields.pageSettings.headerTemplate.toLowerCase() === "dark"
        ? "dark"
        : "light" || "dark"
    );

    uiStore.setHeaderTheme(
      data.wpPage.pageFields.pageSettings.headerTemplate.toLowerCase() === "dark"
        ? "dark"
        : "light" || "dark"
    );

    setTimeout(() => {
      if (typeof window !== "undefined") {
        window.scrollTo(window.scrollX, window.scrollY + 1);
        window.document.body.classList.remove("overflow_hidden");
      }
    });
  }

  renderTemplate() {
    const { sections } = this.props.data.wpPage.pageFields;
    const {
      pageSettings: { bgColor },
    } = this.props.data.wpPage.pageFields;

    return (
      <Template
        bgColor={this.props.uiStore.backgroundColor}
        template={"page_builder"}
        sections={[...sections]}
      />
    );
  }

  render() {
    const { data, location } = this.props;
    const { wpPage } = data;
    const {
      title,
      seo,
      pageFields: {
        pageSettings: {
          headerTemplate,
          footerTemplate,
          hideBottomRowInFooter,
          showForm,
          toggleButtonTitle,
        },
      },
    } = wpPage;
    const { origin } = location;
    return (
      <>
        <Seo seo={seo} pageTitle={title} origin={origin} />
        {headerTemplate === "Portfolio Service" ? (
          <CustomHeader showForm={showForm} toggleButtonTitle={toggleButtonTitle} />
        ) : headerTemplate === "Centered Logo" ? (
          <CenteredLogoHeader />
        ) : (
          <Header />
        )}
        {this.renderTemplate()}
        <Footer showBottomRowInFooter={!hideBottomRowInFooter} theme={footerTemplate} />
      </>
    );
  }
}

export default withStore(PageTemplate);

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...singleWpPageFragment
    }
  }
`;
