import React from "react";
import { motion } from "framer-motion";

import { ITitleAndText } from "~models";
import { Container, Row, Col } from "~components";
import { fadeInItem } from "~libs/framer/variants";

import * as style from "./TitleAndText.module.scss";

interface Props {
  section: ITitleAndText;
}

export const TitleAndText = ({ section }: Props) => {
  const { sectionTitle, sectionText } = section;

  return (
    <section data-sectionname="TitleAndText">
      <Container fluid>
        <Row>
          <Col lg={9}>
            <motion.h1
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeInItem}
              custom={0}
              className={`${style.title} h3`}
              dangerouslySetInnerHTML={{ __html: sectionTitle }}
            />
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeInItem}
              custom={0.2}
              className={"body-large"}
              dangerouslySetInnerHTML={{ __html: sectionText }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
