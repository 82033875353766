import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import { motion } from "framer-motion";
import scrollTo from "gatsby-plugin-smoothscroll";

import {
  Container,
  Masonry,
  Row,
  Col,
  Label,
  NewsFeedCard,
  InsightHero,
} from "~components";
import { INewsFeed } from "~models";
import { withAllLatests } from "~libs/graphql/hoc/withAllLatests";
import { UIStore } from "~stores";

import { fadeInItem } from "~libs/framer/variants";

import * as style from "./NewsFeed.module.scss";
import { ILatestItem } from "~models/PostTypes";

interface NewsFeedProps {
  section: INewsFeed;
  news: ILatestItem[];
  uiStore?: UIStore;
}

const _NewsFeed = inject("uiStore")(
  observer(({ news, uiStore }: NewsFeedProps) => {
    const { isEqualOrGreatherThanBreakpoint, setCustomCursorEvent } = uiStore;
    const featured = news.filter((e) => e.latestFields.latestSettings.isFeatured);

    const cats = [
      {
        label: "READ ALL",
        title: "all",
      },
      {
        label: "WHITE PAPERS",
        title: "white-paper",
      },
      {
        label: "INSIGHTS",
        title: "insight",
      },
      {
        label: "NEWS",
        title: "news",
      },
    ];

    const [activeCategory, setActiveCategory] = useState(null);
    const [toRender, setToRender] = useState([...news]);
    const anchorRef = useRef(null);

    useEffect(() => {
      const url = window.location.href;

      if (url.includes("#")) {
        setActiveCategory(url.split("#")[1]);
        const parsedUrl = url.split("#")[1];
        categoryClickHandle(parsedUrl, true);
      } else {
        setActiveCategory("all");
      }
    }, []);

    // useEffect(() => {
    //   if (activeCategory) {
    //     if (activeCategory === "news") {
    //       setToRender([]);
    //       setToRender(news);
    //     } else if (activeCategory === "white paper") {
    //       const filterInsight = insights.filter((ele) => {
    //         return ele.insightFields.insightSettings.type === "insight";
    //       });
    //       setToRender([]);
    //       setToRender(filterInsight);
    //     } else if (activeCategory === "insights") {
    //       const filterInsight = insights.filter((ele) => {
    //         return ele.insightFields.insightSettings.type === "quickTakeaway";
    //       });
    //       setToRender([]);
    //       setToRender(filterInsight);
    //     } else {
    //       setToRender([]);
    //       setToRender(merged);
    //     }
    //   }
    // }, [setToRender, activeCategory]);

    // useLayoutEffect(() => {
    //   if (
    //     toRender.length > 0 &&
    //     window.location.href.includes("#") &&
    //     document.getElementById("isAnchored")
    //   ) {
    //     setTimeout(() => {
    //       scrollTo("#isAnchored");
    //     }, 1000);
    //   }
    // }, [toRender]);

    const categoryClickHandle = (e, isAnchored) => {
      if (!isAnchored) {
        setActiveCategory(e.target.getAttribute("data-name"));
        setCustomCursorEvent(false);
      } else {
        setActiveCategory(e);
        setCustomCursorEvent(false);
      }
    };

    return (
      <motion.section data-sectionname="FeedGrid" viewport={{ margin: "-50% -50%" }}>
        {featured && <InsightHero featuredLatests={featured} />}
        <div
          id="isAnchored"
          ref={anchorRef}
          style={{ transform: "translateY(-100px)" }}
        />
        <Container fluid>
          <Row className={style.tagHolder}>
            <Col xl={12} md={10} sm={12}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                id={"filterable-labels"}
                variants={fadeInItem}
              >
                {cats?.map((category) => (
                  <Label
                    key={category.title}
                    data-name={category.title}
                    text={category.label}
                    isActive={activeCategory === category.title}
                    onClick={(e) => categoryClickHandle(e, false)}
                  />
                ))}
              </motion.div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Masonry
                columns={
                  isEqualOrGreatherThanBreakpoint("xxxl")
                    ? 4
                    : isEqualOrGreatherThanBreakpoint("xl")
                    ? 3
                    : isEqualOrGreatherThanBreakpoint("md")
                    ? 2
                    : 1
                }
                gap={24}
                margin={isEqualOrGreatherThanBreakpoint("md") ? 80 : 120}
              >
                {toRender
                  .filter((e) =>
                    activeCategory === "all"
                      ? e.latestFields.latestSettings.isFeatured !== true
                      : activeCategory === e.latestFields.latestSettings.type
                  )
                  .map((e, i) => {
                    return (
                      <NewsFeedCard
                        key={i + "news"}
                        element={e}
                        isFiltered={activeCategory !== "all"}
                        activeCategory={activeCategory}
                      />
                    );
                  })}
              </Masonry>
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);

export const NewsFeed = withAllLatests(_NewsFeed);
