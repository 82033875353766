// extracted by mini-css-extract-plugin
export var arrow = "ImageRow-module--arrow--b73e1";
export var arrow_no = "ImageRow-module--arrow_no--a6f93";
export var arrow_yes = "ImageRow-module--arrow_yes--6b56c";
export var empty = "ImageRow-module--empty--a83d3";
export var figureHolder = "ImageRow-module--figure-holder--0d69e";
export var flex = "ImageRow-module--flex--cdd59";
export var flex_1 = "ImageRow-module--flex_1--35e0e";
export var flex_center = "ImageRow-module--flex_center--4fad1";
export var flex_center_col = "ImageRow-module--flex_center_col--e7eb9";
export var flex_col = "ImageRow-module--flex_col--154ca";
export var flex_column = "ImageRow-module--flex_column--10c25";
export var flex_fix_center_col = "ImageRow-module--flex_fix_center_col--2838b";
export var flex_fixed_center_col = "ImageRow-module--flex_fixed_center_col--9b185";
export var flex_row = "ImageRow-module--flex_row--c4037";
export var imageAnimationHolder = "ImageRow-module--image-animation-holder--7eeed";
export var isReversed = "ImageRow-module--is-reversed--6bb96";
export var leftOverflow = "ImageRow-module--left-overflow--cd74e";
export var linkAnimation = "ImageRow-module--link-animation--7a820";
export var multipleImageHolder = "ImageRow-module--multiple-image-holder--10289";
export var portraitAndLandscape = "ImageRow-module--portrait-and-landscape--aebf5";
export var rightOverflow = "ImageRow-module--right-overflow--0526e";
export var rotating = "ImageRow-module--rotating--da8e0";
export var singleImageHolder = "ImageRow-module--single-image-holder--3d03e";
export var singleSmallLandscape = "ImageRow-module--single-small-landscape--b9e5c";
export var swiperWrapper = "ImageRow-module--swiper-wrapper--f42aa";
export var twoLandscape = "ImageRow-module--two-landscape--6eda2";
export var twoPortrait = "ImageRow-module--two-portrait--f0237";