// extracted by mini-css-extract-plugin
export var arrow = "NewsFeed-module--arrow--4b2a2";
export var arrow_no = "NewsFeed-module--arrow_no--73d3e";
export var arrow_yes = "NewsFeed-module--arrow_yes--db197";
export var buttonHolder = "NewsFeed-module--button-holder--d41ff";
export var flex = "NewsFeed-module--flex--c4380";
export var flex_1 = "NewsFeed-module--flex_1--2795e";
export var flex_center = "NewsFeed-module--flex_center--f46a3";
export var flex_center_col = "NewsFeed-module--flex_center_col--1778b";
export var flex_col = "NewsFeed-module--flex_col--37cb4";
export var flex_column = "NewsFeed-module--flex_column--78782";
export var flex_fix_center_col = "NewsFeed-module--flex_fix_center_col--a2c52";
export var flex_fixed_center_col = "NewsFeed-module--flex_fixed_center_col--93dad";
export var flex_row = "NewsFeed-module--flex_row--02978";
export var imageAnimationHolder = "NewsFeed-module--image-animation-holder--710b6";
export var linkAnimation = "NewsFeed-module--link-animation--18d82";
export var rotating = "NewsFeed-module--rotating--13740";
export var swiperWrapper = "NewsFeed-module--swiper-wrapper--454fb";
export var tagHolder = "NewsFeed-module--tag-holder--c2bbe";