// extracted by mini-css-extract-plugin
export var arrow = "style-module--arrow--7085c";
export var arrow_no = "style-module--arrow_no--a2c0e";
export var arrow_yes = "style-module--arrow_yes--6bb5a";
export var description = "style-module--description--429bc";
export var flex = "style-module--flex--ec992";
export var flex_1 = "style-module--flex_1--b0dcd";
export var flex_center = "style-module--flex_center--d7179";
export var flex_center_col = "style-module--flex_center_col--9691e";
export var flex_col = "style-module--flex_col--5fc44";
export var flex_column = "style-module--flex_column--e9615";
export var flex_fix_center_col = "style-module--flex_fix_center_col--a393e";
export var flex_fixed_center_col = "style-module--flex_fixed_center_col--0ace1";
export var flex_row = "style-module--flex_row--b9290";
export var imageAnimationHolder = "style-module--image-animation-holder--636c3";
export var landscape = "style-module--landscape--d6a5a";
export var linkAnimation = "style-module--link-animation--6b431";
export var linkLike = "style-module--link-like--23636";
export var mobileLink = "style-module--mobile-link--6ce05";
export var newsFeedCard = "style-module--news-feed-card--fcb51";
export var overview = "style-module--overview--df0d8";
export var portrait = "style-module--portrait--b0c38";
export var rotating = "style-module--rotating--44b48";
export var season = "style-module--season--2c3c8";
export var square = "style-module--square--8c1d3";
export var swiperWrapper = "style-module--swiper-wrapper--cd7d7";
export var title = "style-module--title--b2818";