// extracted by mini-css-extract-plugin
export var arrow = "ImageSlider-module--arrow--3cc43";
export var arrow_no = "ImageSlider-module--arrow_no--a6bd3";
export var arrow_yes = "ImageSlider-module--arrow_yes--21ac8";
export var figureHolder = "ImageSlider-module--figure-holder--c60e6";
export var flex = "ImageSlider-module--flex--e6898";
export var flex_1 = "ImageSlider-module--flex_1--0f3bd";
export var flex_center = "ImageSlider-module--flex_center--06ba2";
export var flex_center_col = "ImageSlider-module--flex_center_col--b3cfd";
export var flex_col = "ImageSlider-module--flex_col--779cc";
export var flex_column = "ImageSlider-module--flex_column--89862";
export var flex_fix_center_col = "ImageSlider-module--flex_fix_center_col--9cbb9";
export var flex_fixed_center_col = "ImageSlider-module--flex_fixed_center_col--7a945";
export var flex_row = "ImageSlider-module--flex_row--d7c50";
export var imageAnimationHolder = "ImageSlider-module--image-animation-holder--35ac7";
export var imageHolder = "ImageSlider-module--image-holder--80963";
export var landscape = "ImageSlider-module--landscape--24bb6";
export var linkAnimation = "ImageSlider-module--link-animation--b544a";
export var portrait = "ImageSlider-module--portrait--025b0";
export var rotating = "ImageSlider-module--rotating--5b297";
export var sliderDragButton = "ImageSlider-module--slider-drag-button--9d40f";
export var sliderHolder = "ImageSlider-module--slider-holder--186e5";
export var swiperWrapper = "ImageSlider-module--swiper-wrapper--52b20";