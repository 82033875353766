// extracted by mini-css-extract-plugin
export var arrow = "UpperFooter-module--arrow--48cbe";
export var arrow_no = "UpperFooter-module--arrow_no--4a774";
export var arrow_yes = "UpperFooter-module--arrow_yes--cb06f";
export var buttonHolder = "UpperFooter-module--button-holder--1434e";
export var buttonRow = "UpperFooter-module--button-row--6db7d";
export var description = "UpperFooter-module--description--84cc8";
export var flex = "UpperFooter-module--flex--a4c8c";
export var flex_1 = "UpperFooter-module--flex_1--dff85";
export var flex_center = "UpperFooter-module--flex_center--81ddd";
export var flex_center_col = "UpperFooter-module--flex_center_col--1a163";
export var flex_col = "UpperFooter-module--flex_col--302be";
export var flex_column = "UpperFooter-module--flex_column--14da9";
export var flex_fix_center_col = "UpperFooter-module--flex_fix_center_col--6f721";
export var flex_fixed_center_col = "UpperFooter-module--flex_fixed_center_col--78c93";
export var flex_row = "UpperFooter-module--flex_row--a6ac5";
export var imageAnimationHolder = "UpperFooter-module--image-animation-holder--6efc3";
export var light = "UpperFooter-module--light--68e58";
export var linkAnimation = "UpperFooter-module--link-animation--2d43d";
export var rotating = "UpperFooter-module--rotating--aa924";
export var sectionText = "UpperFooter-module--section-text--094ed";
export var swiperWrapper = "UpperFooter-module--swiper-wrapper--db95d";