// extracted by mini-css-extract-plugin
export var arrow = "PartnerList-module--arrow--d513d";
export var arrow_no = "PartnerList-module--arrow_no--7ff60";
export var arrow_yes = "PartnerList-module--arrow_yes--1013a";
export var buttonRow = "PartnerList-module--button-row--d809d";
export var exceptional = "PartnerList-module--exceptional--0eb37";
export var flex = "PartnerList-module--flex--54a8e";
export var flex_1 = "PartnerList-module--flex_1--a93d8";
export var flex_center = "PartnerList-module--flex_center--05b94";
export var flex_center_col = "PartnerList-module--flex_center_col--32444";
export var flex_col = "PartnerList-module--flex_col--c87d4";
export var flex_column = "PartnerList-module--flex_column--67775";
export var flex_fix_center_col = "PartnerList-module--flex_fix_center_col--97581";
export var flex_fixed_center_col = "PartnerList-module--flex_fixed_center_col--33039";
export var flex_row = "PartnerList-module--flex_row--f80cd";
export var gridCardParagraph = "PartnerList-module--grid-card-paragraph--ec572";
export var gridHolder = "PartnerList-module--grid-holder--03c83";
export var gridRow = "PartnerList-module--grid-row--073e0";
export var imageAnimationHolder = "PartnerList-module--image-animation-holder--a365e";
export var linkAnimation = "PartnerList-module--link-animation--e02c3";
export var natural = "PartnerList-module--natural--5c530";
export var rotating = "PartnerList-module--rotating--de9ca";
export var sectionTextRow = "PartnerList-module--section-text-row--fec1a";
export var small = "PartnerList-module--small--23c4a";
export var swiperWrapper = "PartnerList-module--swiper-wrapper--cbd48";
export var whiteSpace = "PartnerList-module--white-space--4db78";