// extracted by mini-css-extract-plugin
export var arrow = "SustainabilityContent-module--arrow--af9de";
export var arrow_no = "SustainabilityContent-module--arrow_no--29dbc";
export var arrow_yes = "SustainabilityContent-module--arrow_yes--c5199";
export var contentRow = "SustainabilityContent-module--content-row--d7cc8";
export var contentRowSecondaryFont = "SustainabilityContent-module--content-row-secondary-font--1ec7a";
export var dark = "SustainabilityContent-module--dark--3491c";
export var flex = "SustainabilityContent-module--flex--8e27a";
export var flex_1 = "SustainabilityContent-module--flex_1--231ce";
export var flex_center = "SustainabilityContent-module--flex_center--0b0de";
export var flex_center_col = "SustainabilityContent-module--flex_center_col--d293b";
export var flex_col = "SustainabilityContent-module--flex_col--8f03c";
export var flex_column = "SustainabilityContent-module--flex_column--23282";
export var flex_fix_center_col = "SustainabilityContent-module--flex_fix_center_col--0d944";
export var flex_fixed_center_col = "SustainabilityContent-module--flex_fixed_center_col--4c4f7";
export var flex_row = "SustainabilityContent-module--flex_row--103da";
export var hr = "SustainabilityContent-module--hr--1929f";
export var hrException = "SustainabilityContent-module--hr-exception--91b1e";
export var imageAnimationHolder = "SustainabilityContent-module--image-animation-holder--a846d";
export var linkAnimation = "SustainabilityContent-module--link-animation--98387";
export var rotating = "SustainabilityContent-module--rotating--001af";
export var swiperWrapper = "SustainabilityContent-module--swiper-wrapper--aa418";
export var title = "SustainabilityContent-module--title--c4ed7";
export var wider = "SustainabilityContent-module--wider--8561b";