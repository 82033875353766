// extracted by mini-css-extract-plugin
export var arrow = "TitleAndText-module--arrow--01c8d";
export var arrow_no = "TitleAndText-module--arrow_no--bb24f";
export var arrow_yes = "TitleAndText-module--arrow_yes--70217";
export var flex = "TitleAndText-module--flex--47362";
export var flex_1 = "TitleAndText-module--flex_1--bfbe5";
export var flex_center = "TitleAndText-module--flex_center--49baa";
export var flex_center_col = "TitleAndText-module--flex_center_col--0c37d";
export var flex_col = "TitleAndText-module--flex_col--1b297";
export var flex_column = "TitleAndText-module--flex_column--2471c";
export var flex_fix_center_col = "TitleAndText-module--flex_fix_center_col--8419a";
export var flex_fixed_center_col = "TitleAndText-module--flex_fixed_center_col--7436c";
export var flex_row = "TitleAndText-module--flex_row--acaa4";
export var imageAnimationHolder = "TitleAndText-module--image-animation-holder--e8ee4";
export var linkAnimation = "TitleAndText-module--link-animation--4f188";
export var rotating = "TitleAndText-module--rotating--47f66";
export var swiperWrapper = "TitleAndText-module--swiper-wrapper--54792";
export var title = "TitleAndText-module--title--731c0";