// extracted by mini-css-extract-plugin
export var arrow = "GeneralContacts-module--arrow--f0fd6";
export var arrow_no = "GeneralContacts-module--arrow_no--eab4f";
export var arrow_yes = "GeneralContacts-module--arrow_yes--97c16";
export var content = "GeneralContacts-module--content--56ac2";
export var contentHolder = "GeneralContacts-module--content-holder--2fe32";
export var flex = "GeneralContacts-module--flex--d7165";
export var flex_1 = "GeneralContacts-module--flex_1--7dd8b";
export var flex_center = "GeneralContacts-module--flex_center--e31d2";
export var flex_center_col = "GeneralContacts-module--flex_center_col--b5171";
export var flex_col = "GeneralContacts-module--flex_col--e20bd";
export var flex_column = "GeneralContacts-module--flex_column--3826d";
export var flex_fix_center_col = "GeneralContacts-module--flex_fix_center_col--d842d";
export var flex_fixed_center_col = "GeneralContacts-module--flex_fixed_center_col--850b2";
export var flex_row = "GeneralContacts-module--flex_row--84bc3";
export var imageAnimationHolder = "GeneralContacts-module--image-animation-holder--0f08e";
export var linkAnimation = "GeneralContacts-module--link-animation--2c90c";
export var rotating = "GeneralContacts-module--rotating--e3282";
export var swiperWrapper = "GeneralContacts-module--swiper-wrapper--007b5";