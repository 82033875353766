// extracted by mini-css-extract-plugin
export var arrow = "ContentHeading-module--arrow--f4ea7";
export var arrow_no = "ContentHeading-module--arrow_no--fcbaf";
export var arrow_yes = "ContentHeading-module--arrow_yes--6835d";
export var contentContainer = "ContentHeading-module--content-container--522ca";
export var contentHeader = "ContentHeading-module--content-header--60b68";
export var figure = "ContentHeading-module--figure--884f5";
export var flex = "ContentHeading-module--flex--c04ea";
export var flex_1 = "ContentHeading-module--flex_1--51ab1";
export var flex_center = "ContentHeading-module--flex_center--7e702";
export var flex_center_col = "ContentHeading-module--flex_center_col--5b7ed";
export var flex_col = "ContentHeading-module--flex_col--1a768";
export var flex_column = "ContentHeading-module--flex_column--b0a1e";
export var flex_fix_center_col = "ContentHeading-module--flex_fix_center_col--71480";
export var flex_fixed_center_col = "ContentHeading-module--flex_fixed_center_col--a011a";
export var flex_row = "ContentHeading-module--flex_row--db9f9";
export var headerFigureAnimation = "ContentHeading-module--header-figure-animation--7b340";
export var imageAnimationHolder = "ContentHeading-module--image-animation-holder--18de1";
export var linkAnimation = "ContentHeading-module--link-animation--0dcde";
export var rotating = "ContentHeading-module--rotating--ba0d1";
export var rowWrapper = "ContentHeading-module--row-wrapper--2245a";
export var swiperWrapper = "ContentHeading-module--swiper-wrapper--52479";
export var title = "ContentHeading-module--title--1cf11";
export var titleHeader = "ContentHeading-module--title-header--f522d";