import React, { Component } from "react";
import * as styles from "./SectionsContainer.module.scss";
import { inject, observer } from "mobx-react";
import { SectionsContainerStore } from "~stores";
import { withStore } from "~libs/mobx";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  template?: "page_builder";
  sectionsContainerStore?: SectionsContainerStore;
  bgColor?: string;
}

@inject("sectionsContainerStore")
@observer
export class SectionsContainer extends Component<Props> {
  render() {
    const { template, sectionsContainerStore, bgColor, children } = this.props;

    const color = {
      backgroundColor: bgColor || "#ffffff",
    };

    const { hasOpenOverlay } = sectionsContainerStore;

    let classes = [styles.sectionsContainer];

    switch (template) {
      case "page_builder":
        classes.push(styles.pageTemplate);
        break;
    }

    if (hasOpenOverlay) classes.push("hasOpenOverlay");

    return (
      <div style={color} className={classes.join(" ")}>
        {children}
      </div>
    );
  }
}

export default withStore(SectionsContainer);
