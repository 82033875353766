// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--f38ba";
export var arrow_no = "styles-module--arrow_no--475ff";
export var arrow_yes = "styles-module--arrow_yes--6047f";
export var flex = "styles-module--flex--fb82f";
export var flex_1 = "styles-module--flex_1--63391";
export var flex_center = "styles-module--flex_center--49b82";
export var flex_center_col = "styles-module--flex_center_col--259c8";
export var flex_col = "styles-module--flex_col--96fa7";
export var flex_column = "styles-module--flex_column--a0b6c";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--0cd3c";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--236e7";
export var flex_row = "styles-module--flex_row--8e7a6";
export var imageAnimationHolder = "styles-module--image-animation-holder--21a35";
export var linkAnimation = "styles-module--link-animation--0b2f2";
export var rotating = "styles-module--rotating--8abfe";
export var spinner = "styles-module--spinner--ed96b";
export var swiperWrapper = "styles-module--swiper-wrapper--1f130";