// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--94fb9";
export var arrow_no = "styles-module--arrow_no--b4194";
export var arrow_yes = "styles-module--arrow_yes--7175e";
export var flex = "styles-module--flex--c566d";
export var flex_1 = "styles-module--flex_1--ea344";
export var flex_center = "styles-module--flex_center--1b213";
export var flex_center_col = "styles-module--flex_center_col--4e3ff";
export var flex_col = "styles-module--flex_col--ac5f2";
export var flex_column = "styles-module--flex_column--0c8eb";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--02c5a";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--457b5";
export var flex_row = "styles-module--flex_row--f7f41";
export var imageAnimationHolder = "styles-module--image-animation-holder--54640";
export var linkAnimation = "styles-module--link-animation--dca1a";
export var rotating = "styles-module--rotating--187fd";
export var swiperWrapper = "styles-module--swiper-wrapper--e6a2a";
export var videoHolder = "styles-module--video-holder--9e538";
export var videoHolderCustomControlsHolder = "styles-module--video-holder-custom-controls-holder--50377";
export var videoHolderCustomControlsHolderShow = "styles-module--video-holder-custom-controls-holder-show--965d1";
export var videoPlayButton = "styles-module--video-play-button--b00b0";
export var withAspectRatio = "styles-module--with-aspect-ratio--145dd";