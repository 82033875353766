// extracted by mini-css-extract-plugin
export var arrow = "DraggableCards-module--arrow--e6e64";
export var arrow_no = "DraggableCards-module--arrow_no--796f8";
export var arrow_yes = "DraggableCards-module--arrow_yes--3ffb0";
export var buttonHolder = "DraggableCards-module--button-holder--25f45";
export var cardHolder = "DraggableCards-module--card-holder--25847";
export var contentHolder = "DraggableCards-module--content-holder--23bfd";
export var deck = "DraggableCards-module--deck--11971";
export var draggableCards = "DraggableCards-module--draggable-cards--b3c08";
export var flex = "DraggableCards-module--flex--aae2d";
export var flex_1 = "DraggableCards-module--flex_1--a24ad";
export var flex_center = "DraggableCards-module--flex_center--0a17b";
export var flex_center_col = "DraggableCards-module--flex_center_col--a24b3";
export var flex_col = "DraggableCards-module--flex_col--04743";
export var flex_column = "DraggableCards-module--flex_column--b1732";
export var flex_fix_center_col = "DraggableCards-module--flex_fix_center_col--8cd83";
export var flex_fixed_center_col = "DraggableCards-module--flex_fixed_center_col--d0f7a";
export var flex_row = "DraggableCards-module--flex_row--69e57";
export var heading = "DraggableCards-module--heading--72faa";
export var imageAnimationHolder = "DraggableCards-module--image-animation-holder--83751";
export var linkAnimation = "DraggableCards-module--link-animation--b5c11";
export var rotating = "DraggableCards-module--rotating--22402";
export var sliderHolder = "DraggableCards-module--slider-holder--2cbc9";
export var sliderItemHolder = "DraggableCards-module--slider-item-holder--1d2ce";
export var spacing = "DraggableCards-module--spacing--62cb6";
export var swiperWrapper = "DraggableCards-module--swiper-wrapper--3b906";
export var switcherHolder = "DraggableCards-module--switcher-holder--72270";