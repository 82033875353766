// extracted by mini-css-extract-plugin
export var arrow = "Divider-module--arrow--3b8ff";
export var arrow_no = "Divider-module--arrow_no--66be8";
export var arrow_yes = "Divider-module--arrow_yes--f6928";
export var dark = "Divider-module--dark--2008b";
export var divider = "Divider-module--divider--6e5b3";
export var flex = "Divider-module--flex--0bbcd";
export var flex_1 = "Divider-module--flex_1--62c3c";
export var flex_center = "Divider-module--flex_center--06aff";
export var flex_center_col = "Divider-module--flex_center_col--b11e6";
export var flex_col = "Divider-module--flex_col--2d2f1";
export var flex_column = "Divider-module--flex_column--9c480";
export var flex_fix_center_col = "Divider-module--flex_fix_center_col--5c470";
export var flex_fixed_center_col = "Divider-module--flex_fixed_center_col--a6776";
export var flex_row = "Divider-module--flex_row--0d534";
export var imageAnimationHolder = "Divider-module--image-animation-holder--587db";
export var linkAnimation = "Divider-module--link-animation--51916";
export var rotating = "Divider-module--rotating--516f3";
export var swiperWrapper = "Divider-module--swiper-wrapper--cc3a8";