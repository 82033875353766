import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "gatsby";
import { motion } from "framer-motion";

import { IPartnerList } from "~models";
import { Container, Row, Col, Button, Figure, GridCard, Divider } from "~components";
import { UIStore } from "~stores";
import { fadeInItem } from "~libs/framer/variants";

import * as style from "./PartnerList.module.scss";

interface Props {
  section: IPartnerList;
  uiStore?: UIStore;
}

export const PartnerList = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { sectionText, partners, linkButton, transition, type } = section;

    const { isEqualOrGreatherThanBreakpoint } = uiStore;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };
    return (
      <motion.section
        data-sectionname="PartnerList"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{
          amount: isEqualOrGreatherThanBreakpoint("xl")
            ? 0.1
            : isEqualOrGreatherThanBreakpoint("md")
            ? 0.3
            : 0.1,
        }}
        className={`${type === "grid" ? style.grid : ""}`}
      >
        <Container fluid>
          <Row
            className={`${style.sectionTextRow} ${type === "grid" ? style.small : ""}`}
          >
            <Col md={7}>
              <motion.h4
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 1 }}
                variants={fadeInItem}
                custom={0.4}
                dangerouslySetInnerHTML={{ __html: sectionText }}
              />
            </Col>
          </Row>
          {type === "grid" ? (
            <Row className={style.gridRow}>
              <Col>
                <div
                  className={`${style.gridHolder} ${
                    // @ts-ignore
                    partners.length === 4 ? style.exceptional : style.natural
                  }`}
                >
                  {partners.map((partner, i) => (
                    <GridCard
                      key={i}
                      title={partner.partnerGroup.partner}
                      link={
                        partner.partnerGroup.link ? partner.partnerGroup.link.url : null
                      }
                      imageOrVideo={partner.partnerGroup.imageOrVideoGroup}
                    >
                      <p
                        className={style.gridCardParagraph}
                        dangerouslySetInnerHTML={{ __html: partner.partnerGroup.text }}
                      />
                    </GridCard>
                  ))}
                </div>
              </Col>
            </Row>
          ) : (
            <Row className={style.partnersRow}>
              {partners?.map((partner, index) => (
                <Figure
                  key={index}
                  title={partner.partnerGroup.partner}
                  imageOrVideo={partner.partnerGroup.imageOrVideoGroup}
                  tag={
                    partner.partnerGroup.tag.length
                      ? partner.partnerGroup.tag.map((e) => e.tagFields.tagName)
                      : [""]
                  }
                  isOuter={partner.partnerGroup.imageOrVideoGroup.styleKey === "outer"}
                  isLandscape={
                    partner.partnerGroup.imageOrVideoGroup.styleKeyRatio === "landscape"
                  }
                  link={partner.partnerGroup.link}
                  description={partner.partnerGroup.text}
                ></Figure>
              ))}
            </Row>
          )}
          {linkButton && linkButton.buttonText && (
            <Row className={style.buttonRow}>
              <Col>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={fadeInItem}
                >
                  <Link to={linkButton.buttonLink?.url}>
                    <Button icon={"icon-arrow-right"}>{linkButton.buttonText}</Button>
                  </Link>
                </motion.div>
              </Col>
            </Row>
          )}
          {type === "grid" && (
            <Row>
              <div className={style.whiteSpace} />
            </Row>
          )}
          {type === "grid" && (
            <Row>
              <Col>
                <Divider dark />
              </Col>
            </Row>
          )}
        </Container>
      </motion.section>
    );
  })
);
