// extracted by mini-css-extract-plugin
export var arrow = "GridCard-module--arrow--2b26f";
export var arrow_no = "GridCard-module--arrow_no--0d5b6";
export var arrow_yes = "GridCard-module--arrow_yes--c3d63";
export var flex = "GridCard-module--flex--86fe1";
export var flex_1 = "GridCard-module--flex_1--94fda";
export var flex_center = "GridCard-module--flex_center--f22ef";
export var flex_center_col = "GridCard-module--flex_center_col--c97a5";
export var flex_col = "GridCard-module--flex_col--e87d8";
export var flex_column = "GridCard-module--flex_column--f0118";
export var flex_fix_center_col = "GridCard-module--flex_fix_center_col--fb5fb";
export var flex_fixed_center_col = "GridCard-module--flex_fixed_center_col--e2330";
export var flex_row = "GridCard-module--flex_row--977de";
export var gridCard = "GridCard-module--grid-card--a97bf";
export var imageAnimationHolder = "GridCard-module--image-animation-holder--ff368";
export var imageHolder = "GridCard-module--image-holder--2346d";
export var linkAnimation = "GridCard-module--link-animation--7b305";
export var rotating = "GridCard-module--rotating--aca81";
export var swiperWrapper = "GridCard-module--swiper-wrapper--5b628";
export var videoHolder = "GridCard-module--video-holder--0385e";