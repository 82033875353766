// extracted by mini-css-extract-plugin
export var alignCenter = "Portal-module--align-center--02e9a";
export var alignLeft = "Portal-module--align-left--5f7f1";
export var alignRight = "Portal-module--align-right--22c9f";
export var arrow = "Portal-module--arrow--bc059";
export var arrow_no = "Portal-module--arrow_no--5738b";
export var arrow_yes = "Portal-module--arrow_yes--2e1ae";
export var content = "Portal-module--content--a797b";
export var exceptionRow = "Portal-module--exception-row--25d2b";
export var flex = "Portal-module--flex--5ea1a";
export var flex_1 = "Portal-module--flex_1--ef025";
export var flex_center = "Portal-module--flex_center--7c98a";
export var flex_center_col = "Portal-module--flex_center_col--89a0f";
export var flex_col = "Portal-module--flex_col--4cb98";
export var flex_column = "Portal-module--flex_column--de355";
export var flex_fix_center_col = "Portal-module--flex_fix_center_col--38d9d";
export var flex_fixed_center_col = "Portal-module--flex_fixed_center_col--916f0";
export var flex_row = "Portal-module--flex_row--6ac75";
export var imageAnimationHolder = "Portal-module--image-animation-holder--d5c3c";
export var imageHolder = "Portal-module--image-holder--c204d";
export var linkAnimation = "Portal-module--link-animation--69d9b";
export var portalRow = "Portal-module--portal-row--088b3";
export var rotating = "Portal-module--rotating--402ba";
export var swiperWrapper = "Portal-module--swiper-wrapper--aae69";
export var tagHolder = "Portal-module--tag-holder--d3e8d";