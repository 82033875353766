import React from "react";
import { inject, observer } from "mobx-react";

import { UIStore } from "~stores";
import * as styles from "./CustomCursor.module.scss";

interface CustomCursorProps {
  uiStore?: UIStore;
  text: string;
  type?: "text";
}

export const CustomCursor = inject("uiStore")(
  observer(({ text, uiStore, type }: CustomCursorProps) => {
    const { customCursorEvent, mousePosition } = uiStore;

    const { x, y } = mousePosition;
    return (
      customCursorEvent && (
        <div
          style={{ left: `${x + 100}px`, top: `${y + 8}px` }}
          className={`${styles.customCursor} ${type === "text" ? styles.text : ""}`}
        >
          <span>{text}</span>
          <i className={"icon-arrow-right"}></i>
        </div>
      )
    );
  })
);
