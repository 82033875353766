// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--369da";
export var arrow_no = "styles-module--arrow_no--abe5c";
export var arrow_yes = "styles-module--arrow_yes--c8877";
export var customControls = "styles-module--custom-controls--126d6";
export var customControlsButtons = "styles-module--custom-controls-buttons--b0f70";
export var customControlsCurrentTime = "styles-module--custom-controls-current-time--9a739";
export var customControlsSeekerInput = "styles-module--custom-controls-seeker-input--89615";
export var dark = "styles-module--dark--59e28";
export var flex = "styles-module--flex--75010";
export var flex_1 = "styles-module--flex_1--5849e";
export var flex_center = "styles-module--flex_center--a83c7";
export var flex_center_col = "styles-module--flex_center_col--e4ebf";
export var flex_col = "styles-module--flex_col--7329d";
export var flex_column = "styles-module--flex_column--d0783";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--24751";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--92078";
export var flex_row = "styles-module--flex_row--c83cc";
export var imageAnimationHolder = "styles-module--image-animation-holder--c0ecf";
export var linkAnimation = "styles-module--link-animation--bc316";
export var rotating = "styles-module--rotating--59ccd";
export var swiperWrapper = "styles-module--swiper-wrapper--93a85";