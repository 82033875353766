import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { motion, useAnimation } from "framer-motion";

import { Label, VideoElement, CustomCursor } from "~components";
import { inject, observer } from "mobx-react";
import { IImageOrVideoGroup } from "~models";
import { ILink } from "~models/Link";
import {
  fadeInItem,
  imageAnimationVariants,
  imageHolderAnimationVariants,
} from "~libs/framer/variants";
import { tx } from "~libs/i18n";
import { UIStore } from "~stores";

import * as style from "./styles.module.scss";

export interface Props {
  title?: string;
  description?: string;
  imageOrVideo?: IImageOrVideoGroup;
  link?: ILink;
  tag?: string[];
  imageAlign?: string | "left" | "center" | "right";
  clickableLabel?: boolean;
  clickLabelToExecute?: any;
  isLightText?: boolean;
  portrait?: boolean;
  uiStore?: UIStore;
}

export const Card = inject("uiStore")(
  observer(
    ({
      title,
      description,
      imageOrVideo,
      link,
      tag,
      imageAlign,
      clickableLabel,
      clickLabelToExecute,
      isLightText,
      uiStore,
    }: Props) => {
      const cardAnimation = useAnimation();
      const { setCustomCursorEvent } = uiStore;

      return (
        <motion.figure className={style.card}>
          <CustomCursor text={tx("cursor.viewWork")} />
          <motion.div
            onMouseEnter={() => setCustomCursorEvent(true)}
            onMouseLeave={() => setCustomCursorEvent(false)}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            animate={cardAnimation}
            variants={imageHolderAnimationVariants}
            className={style.imageAnimationHolder}
          >
            <motion.div
              className={`
            ${style.image}
        `}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              animate={cardAnimation}
              variants={imageAnimationVariants}
              onHoverStart={() => {
                cardAnimation.start("zoom");
              }}
              onHoverEnd={() => {
                cardAnimation.start("visible");
              }}
            >
              <div style={{ width: "100%" }}>
                {imageOrVideo.imageOrVideoSelect === "image" &&
                  (link && link.url ? (
                    <Link to={link.url}>
                      <GatsbyImage
                        className={`${style.cardImage} ${
                          imageAlign === "right"
                            ? style.alignRight
                            : imageAlign === "left"
                            ? style.alignLeft
                            : style.alignCenter
                        }`}
                        alt={imageOrVideo.image.caption || ""}
                        image={imageOrVideo.image.localFile.childImageSharp.image}
                      />
                    </Link>
                  ) : (
                    <GatsbyImage
                      className={`${style.cardImage} ${
                        imageAlign === "right"
                          ? style.alignRight
                          : imageAlign === "left"
                          ? style.alignLeft
                          : style.alignCenter
                      }`}
                      alt={imageOrVideo?.image?.caption || ""}
                      image={imageOrVideo?.image?.localFile.childImageSharp.image}
                    />
                  ))}
                {imageOrVideo?.imageOrVideoSelect === "video" &&
                  (link && link.url ? (
                    <Link to={link.url}>
                      <div
                        className={`${style.cardImage} ${
                          imageAlign === "right"
                            ? style.alignRight
                            : imageAlign === "left"
                            ? style.alignLeft
                            : style.alignCenter
                        }`}
                      >
                        <VideoElement
                          url={
                            imageOrVideo.type === "vimeo"
                              ? imageOrVideo.videoUrl
                              : imageOrVideo.videoFile.mediaItemUrl
                          }
                          poster={imageOrVideo.videoPoster}
                          controls={imageOrVideo.videoSettings.controls}
                          loop={imageOrVideo.videoSettings.loop}
                          freeAspectRatio={true}
                        />
                      </div>
                    </Link>
                  ) : (
                    <div
                      className={`${style.cardImage} ${
                        imageAlign === "right"
                          ? style.alignRight
                          : imageAlign === "left"
                          ? style.alignLeft
                          : style.alignCenter
                      }`}
                    >
                      <VideoElement
                        url={
                          imageOrVideo.type === "vimeo"
                            ? imageOrVideo.videoUrl
                            : imageOrVideo.videoFile.mediaItemUrl
                        }
                        poster={imageOrVideo.videoPoster}
                        controls={imageOrVideo.videoSettings.controls}
                        loop={imageOrVideo.videoSettings.loop}
                        freeAspectRatio={true}
                      />
                    </div>
                  ))}
              </div>
            </motion.div>
          </motion.div>
          <motion.figcaption
            className={`${style.content} ${isLightText ? style.lightText : ""}`}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            animate={cardAnimation}
            variants={fadeInItem}
            onMouseEnter={() => setCustomCursorEvent(true)}
            onMouseLeave={() => setCustomCursorEvent(false)}
          >
            <div className={style.cardHeading}>
              {title ? (
                <h3 dangerouslySetInnerHTML={{ __html: title.toUpperCase() }}></h3>
              ) : null}
              <div
                className={style.tagHolder}
                onMouseEnter={() => setCustomCursorEvent(false)}
                onMouseLeave={() => setCustomCursorEvent(true)}
              >
                {tag.length &&
                  tag.map((e, i) => (
                    <Label
                      key={i}
                      text={e}
                      onClick={clickableLabel ? () => clickLabelToExecute(e) : null}
                      isClickable={clickableLabel}
                    />
                  ))}
              </div>
            </div>
            {description ? <p>{description}</p> : null}
            {link?.url ? (
              <>
                <Link to={link.url} />
              </>
            ) : null}
          </motion.figcaption>
        </motion.figure>
      );
    }
  )
);
