// extracted by mini-css-extract-plugin
export var arrow = "PolicyList-module--arrow--f5d2e";
export var arrow_no = "PolicyList-module--arrow_no--cfa64";
export var arrow_yes = "PolicyList-module--arrow_yes--335f7";
export var flex = "PolicyList-module--flex--c8a21";
export var flex_1 = "PolicyList-module--flex_1--b3c0a";
export var flex_center = "PolicyList-module--flex_center--8bbbf";
export var flex_center_col = "PolicyList-module--flex_center_col--4ef7e";
export var flex_col = "PolicyList-module--flex_col--6ce3f";
export var flex_column = "PolicyList-module--flex_column--ec3b0";
export var flex_fix_center_col = "PolicyList-module--flex_fix_center_col--9cead";
export var flex_fixed_center_col = "PolicyList-module--flex_fixed_center_col--86f2c";
export var flex_row = "PolicyList-module--flex_row--ed6aa";
export var imageAnimationHolder = "PolicyList-module--image-animation-holder--a88f7";
export var linkAnimation = "PolicyList-module--link-animation--4d63f";
export var policyContent = "PolicyList-module--policy-content--3c2f2";
export var policyHolder = "PolicyList-module--policy-holder--44350";
export var policyTitle = "PolicyList-module--policy-title--028a9";
export var rotating = "PolicyList-module--rotating--0f4c1";
export var swiperWrapper = "PolicyList-module--swiper-wrapper--63709";