import React, { useEffect, useState } from "react";

import * as style from "./Switcher.module.scss";

type Option = {
  label: string;
  action: () => void;
};

interface Props {
  options: Option[];
  initialActiveIndex?: number;
}

export const Switcher = ({ options, initialActiveIndex }: Props) => {
  const [activeSwitch, setActiveSwitch] = useState<number>(initialActiveIndex ?? 0);

  useEffect(() => {
    setActiveSwitch(initialActiveIndex);
  }, [initialActiveIndex]);

  return (
    <div className={style.switcher}>
      <div className={style.options}>
        {options.map((option, index) => (
          <button
            key={index}
            className={activeSwitch === Number(index) ? style.active : ""}
            onClick={() => {
              option.action();
              setActiveSwitch(Number(index));
            }}
          >
            {option.label}
          </button>
        ))}
        <div
          className={style.indicator}
          style={{
            width: `calc(100% / ${options.length})`,
            left: `calc((${activeSwitch} * (100% / ${options.length})) ${
              activeSwitch === 0
                ? " + 2px"
                : activeSwitch === options.length - 1
                ? "- 2px"
                : ""
            })`,
          }}
        ></div>
      </div>
    </div>
  );
};
