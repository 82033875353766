import React from "react";
import * as styles from "./styles.module.scss";

import { motion } from "framer-motion";
import { fadeInItem } from "~libs/framer/variants";

export interface LabelProps extends React.HTMLProps<HTMLLabelElement> {
  text: string;
  isActive?: boolean;
  isWhite?: boolean;
  isClickable?: boolean;
}

export const Label = ({
  text,
  isActive,
  isWhite,
  isClickable,
  onClick,
  className,
  ...rest
}: LabelProps) => {
  const classes = [styles.label];

  if (className) {
    classes.push(className);
  }

  if (isActive) {
    classes.push(styles.active);
  }

  if (isWhite) {
    classes.push(styles.white);
  }

  if (isClickable) {
    classes.push(styles.clickable);
  }

  return text !== "no tag" ? (
    <motion.span
      variants={fadeInItem}
      custom={0}
      onClick={onClick}
      className={classes.join(" ")}
      {...rest}
    >
      {text?.toUpperCase()}
    </motion.span>
  ) : (
    <></>
  );
};
