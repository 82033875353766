import { action, observable } from "mobx";
import { IMenu, IPageContext, IPage } from "~models";

export class ContextStore {
  @observable
  context: IPageContext;

  @observable
  data?: {
    wpPage?: IPage;
    wpInsight?: IPage;
    wpCase?: IPage;
  };

  @observable
  menu?: IMenu[];

  @observable
  renderedLanguage: string = "";

  @action
  setRenderedLanguage = (lang: string) => {
    this.renderedLanguage = lang;
  };
}
