import React, { Component } from "react";
import { ISections } from "~models";
import {
  CompanyStatistics,
  ExploreMore,
  GeneralContacts,
  GifAndTextScatter,
  Hero,
  ImageOrVideo,
  ImageSlider,
  LatestInsight,
  LeadershipContacts,
  LowerHeader,
  News,
  NewsBanner,
  OpenPositions,
  ThreeColumnContent,
  PartnerCitation,
  PartnerList,
  PartnerLogos,
  PartnerPortfolio,
  PolicyList,
  Services,
  StrongMessage,
  SustainabilityContent,
  HeroWithImage,
  TextAndImage,
  TextAndImageCollage,
  TextAndImageScatter,
  TextContent,
  TitleAndText,
  UpperFooter,
  NewsFeed,
  WhitePaperForm,
  SlideCarousel,
  DraggableCards,
  ContentHeading,
  ContentBody,
  Addresses,
  HeavyContent,
} from "~sections";
import { ScatteredGallery } from "~views/shared/sections/ScatteredGallery";

interface SectionsProps {
  sections?: ISections;
}

export class Sections extends Component<SectionsProps> {
  render() {
    const { sections } = this.props;
    // console.log("sections: ", sections);

    const renderedSections = (sections || []).map((section, i) => {
      switch (section?.__typename) {
        case "WpPage_Pagefields_Sections_Hero":
          return <Hero key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_ImageOrVideo":
          return <ImageOrVideo key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_PartnerList":
          return <PartnerList key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_LatestInsight":
          return <LatestInsight key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_TextAndImageScatter":
          return <TextAndImageScatter key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_PartnerPortfolio":
          return <PartnerPortfolio key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_PartnerCitation":
          return <PartnerCitation key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_PartnerLogos":
          return <PartnerLogos key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_TextAndImageCollage":
          return <TextAndImageCollage key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_NewsBanner":
          return <NewsBanner key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_UpperFooter":
          return <UpperFooter key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_LowerHeader":
          return <LowerHeader key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_CompanyStatistics":
          return <CompanyStatistics key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_Services":
          return <Services key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_News":
          return <News key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_ExploreMore":
          return <ExploreMore key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_ThreeColumnContent":
          return <ThreeColumnContent key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_TextAndImage":
          return <TextAndImage key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_GeneralContacts":
          return <GeneralContacts key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_LeadershipContacts":
          return <LeadershipContacts key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_Addresses":
          return <Addresses key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_ImageSlider":
          return <ImageSlider key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_GifAndTextScatter":
          return <GifAndTextScatter key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_OpenPositions":
          return <OpenPositions key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_TitleAndText":
          return <TitleAndText key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_PolicyList":
          return <PolicyList key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_TextContent":
          return <TextContent key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_HeroWithImage":
          return <HeroWithImage key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_SustainabilityContent":
          return <SustainabilityContent key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_ScatteredGallery":
          return <ScatteredGallery key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_NewsFeed":
          return <NewsFeed key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_SlideCarousel":
          return <SlideCarousel key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_WhitePaperForm":
          return <WhitePaperForm key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_DraggableCards":
          return <DraggableCards key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_ContentHeading":
          return <ContentHeading key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_ContentBody":
          return <ContentBody key={section.__typename + i} section={section} />;
        case "WpPage_Pagefields_Sections_HeavyContent":
          return <HeavyContent key={section.__typename + i} section={section} />;
        default:
          return <div key={i.toString()} />;
      }
    });
    return renderedSections;
  }
}
