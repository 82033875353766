const smoothCurve = [0.22, 0, 0.18, 1];

export const customLogoAnimationVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  bigger: {
    opacity: 1,
  },
};

export const logoSlideAnimationVariants = {
  visible: {
    opacity: 1,
  },
  slide: {
    opacity: 0,
  },
};

export const mainLogoAnimationVariants = {
  mainLogoVisible: {
    x: 0,
  },
  mainLogoSlided: {
    x: -120,
    transition: {
      duration: 1,
      ease: [0.22, 0, 0.18, 1],
      delay: 0.6,
    },
  },
  mainLogoInitial: {
    x: 0,
    transition: {
      duration: 0,
    },
  },
};

export const duplicatedLogoAnimationVariants = {
  duplicatedLogoHidden: {
    x: 120,
  },
  duplicatedLogoVisible: {
    x: 0,
    transition: {
      duration: 1,
      ease: [0.22, 0, 0.18, 1],
      delay: 0.6,
    },
  },
  duplicatedLogoInitial: {
    x: 120,
    transition: {
      duration: 0,
    },
  },
};

export const menuIconItemAnimationVariants = {
  hidden: (i) => ({
    x: -40 * i,
    transition: {
      duration: 0.3,
    },
  }),
  visible: {
    x: 0,
  },
};

export const menuIconAnimationsVariants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.6,
      ease: smoothCurve,
    },
  },
  visible: {
    opacity: 1,
  },
};

export const hiddenNavigationAnimationVariants = {
  hidden: {
    opacity: 0,
    x: 20,
    zIndex: -1,
    transition: {
      duration: 0.2,
    },
  },
  visible: () => ({
    opacity: 1,
    x: 0,
    zIndex: 1,
    transition: {
      // delay: (1 / (i + 1)) * 0.25,
      ease: smoothCurve,
      duration: 1.2,
    },
  }),
};

export const hiddenNavigationContainerAnimationVariants = {
  hidden: {
    width: "0",
  },
  visible: (custom) => ({
    width: custom + "px",
    transition: {
      duration: 0.6,
      ease: smoothCurve,
    },
  }),
};
