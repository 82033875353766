import { GatsbyImage } from "gatsby-plugin-image";
import React, { useRef, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { motion } from "framer-motion";

import { Container, Row, Col, VideoElement, Label } from "~components";
import { UIStore } from "~stores";
import scrollTo from "gatsby-plugin-smoothscroll";
import {
  fadeInItem,
  imageAnimationVariants,
  imageHolderAnimationVariants,
} from "~libs/framer/variants";

import * as style from "./ContentHeading.module.scss";
import { IContentHeading } from "~models";

interface Props {
  section: IContentHeading;
  details?: {
    tag: string;
    season: string;
    type: string;
  };
  uiStore?: UIStore;
}

export const ContentHeading = inject("uiStore")(
  observer(({ section, details }: Props) => {
    const { title, imageOrVideoGroup } = section;
    const titleRef = useRef(null);

    useEffect(() => {
      const url = window.location.href;

      if (url.includes("#video")) {
        setTimeout(() => {
          scrollTo("#latestVideo");
        }, 700);
      }
    }, []);

    return (
      <section data-sectionname="ContentHeading">
        <Container fluid className="bigger-container-exception">
          <Row className={style.rowWrapper}>
            {imageOrVideoGroup.imageOrVideoSelect !== "none" && (
              <Col lg={6} xs={12}>
                <figure className={style.figure}>
                  <figcaption />
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={imageHolderAnimationVariants}
                    className={`${style.imageAnimationHolder} ${style.headerFigureAnimation}`}
                  >
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      variants={imageAnimationVariants}
                    >
                      {imageOrVideoGroup.imageOrVideoSelect === "image" && (
                        <GatsbyImage
                          image={imageOrVideoGroup.image?.localFile.childImageSharp.image}
                          style={{ width: "100%" }}
                          alt={imageOrVideoGroup?.image.caption || ""}
                        />
                      )}
                      {imageOrVideoGroup.imageOrVideoSelect === "video" && (
                        <VideoElement
                          url={
                            imageOrVideoGroup.type === "vimeo"
                              ? imageOrVideoGroup.videoUrl
                              : imageOrVideoGroup.videoFile.mediaItemUrl
                          }
                          poster={imageOrVideoGroup.videoPoster}
                          controls={imageOrVideoGroup.videoSettings.controls}
                          loop={imageOrVideoGroup.videoSettings.loop}
                          freeAspectRatio={true}
                        />
                      )}
                    </motion.div>
                  </motion.div>
                </figure>
              </Col>
            )}
            <Col
              xs={12}
              lg={6}
              className={`${style.contentContainer} ${
                imageOrVideoGroup.imageOrVideoSelect === "none" ? "offset-lg-3" : ""
              }`}
            >
              <div className={style.contentHeader}>
                <motion.div
                  className={style.titleHeader}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={fadeInItem}
                  custom={0.4}
                >
                  {details && details.type && <Label text={details.type} />}
                </motion.div>
                <motion.h2
                  dangerouslySetInnerHTML={{ __html: title }}
                  className={style.title}
                  ref={titleRef}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={fadeInItem}
                  custom={0.6}
                />
              </div>
              <motion.div
                className={style.titleHeader}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInItem}
                custom={0.8}
              >
                {details && details.season && <small>{details.season}</small>}
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  })
);
