import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { motion, useAnimation } from "framer-motion";
import { inject, observer } from "mobx-react";

import { VideoElement, CustomCursor } from "~components";
import { IImageOrVideoGroup } from "~models";
import { ILink } from "~models/Link";
import {
  imageAnimationVariants,
  imageHolderAnimationVariants,
  fadeInItem,
} from "~libs/framer/variants";
import { tx } from "~libs/i18n";
import { UIStore } from "~stores";

import * as style from "./styles.module.scss";

export interface FigureProps {
  title?: string;
  description?: string;
  imageOrVideo?: IImageOrVideoGroup;
  link?: ILink;
  tag?: string[];
  isOuter?: boolean;
  isLandscape?: boolean;
  uiStore?: UIStore;
}
export const Figure = inject("uiStore")(
  observer(
    ({
      title,
      description,
      imageOrVideo,
      link,
      tag,
      isOuter,
      isLandscape,
      uiStore,
    }: FigureProps) => {
      const figureAnimation = useAnimation();
      const { setCustomCursorEvent } = uiStore;

      return (
        <motion.figure className={style.figure}>
          <figcaption>
            <div>
              <motion.h5
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                animate={figureAnimation}
                custom={0.2}
                variants={fadeInItem}
              >
                {title.toUpperCase()}
              </motion.h5>
              <div className={style.tagHolder}>
                {tag.length &&
                  tag.map((e, i) => (
                    <motion.label
                      key={i}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      animate={figureAnimation}
                      custom={0.2}
                      variants={fadeInItem}
                      className={style.partnersLabel}
                    >
                      {e.toUpperCase()}
                    </motion.label>
                  ))}
              </div>
            </div>
            <Link className={style.partnersLink} to={link?.url}>
              {/* <CustomCursor text={tx("cursor.viewWork")} theme="dark" /> */}
              <motion.p
                dangerouslySetInnerHTML={{ __html: description }}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                animate={figureAnimation}
                variants={fadeInItem}
                custom={0.4}
                onMouseEnter={() => setCustomCursorEvent(true)}
                onMouseLeave={() => setCustomCursorEvent(false)}
              ></motion.p>
            </Link>
          </figcaption>
          <div
            className={`${isOuter ? style.outer : ""} ${
              isLandscape ? style.landscape : ""
            }`}
          >
            <Link to={link?.url}>
              <CustomCursor text={tx("cursor.viewWork")} />
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                animate={figureAnimation}
                variants={imageHolderAnimationVariants}
                className={style.imageAnimationHolder}
                onMouseEnter={() => setCustomCursorEvent(true)}
                onMouseLeave={() => setCustomCursorEvent(false)}
                onHoverStart={() => {
                  figureAnimation.start("zoom");
                }}
                onHoverEnd={() => {
                  figureAnimation.start("visible");
                }}
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  animate={figureAnimation}
                  variants={imageAnimationVariants}
                >
                  {imageOrVideo.imageOrVideoSelect === "image" && (
                    <div className={style.imageHolder}>
                      <GatsbyImage
                        image={imageOrVideo.image?.localFile.childImageSharp.image}
                        style={{ width: "100%" }}
                        alt={imageOrVideo?.image.caption || ""}
                      />
                    </div>
                  )}
                  {imageOrVideo.imageOrVideoSelect === "video" && (
                    <div className={style.imageHolder}>
                      <VideoElement
                        url={
                          imageOrVideo.type === "vimeo"
                            ? imageOrVideo.videoUrl
                            : imageOrVideo.videoFile.mediaItemUrl
                        }
                        poster={imageOrVideo.videoPoster}
                        controls={imageOrVideo.videoSettings.controls}
                        loop={imageOrVideo.videoSettings.loop}
                        freeAspectRatio={true}
                      />
                    </div>
                  )}
                </motion.div>
              </motion.div>
            </Link>
          </div>
        </motion.figure>
      );
    }
  )
);
