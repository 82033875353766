import React from "react";
import { Link } from "gatsby";
import { motion, useAnimation } from "framer-motion";

import { LanguageSwitcher } from "~components";
import {
  listItemAnimationVariants,
  listAnimationVariants,
  elementAnimationVariants,
} from "~libs/framer/variants";
import { IMenuItem } from "~models";

import * as style from "./Sidebar.module.scss";

interface Props {
  menuItems: IMenuItem[];
  setSidebar: React.Dispatch<boolean>;
  isOpen: boolean;
}

export const Sidebar = ({ menuItems, setSidebar, isOpen }: Props) => {
  const sidebarAnimations = useAnimation();

  return (
    <motion.div
      className={`${style.sidebar} ${isOpen ? style.sidebarActive : ""}`}
      onViewportEnter={() => sidebarAnimations.start("visible")}
      onViewportLeave={() => sidebarAnimations.start("hidden")}
    >
      <div className={style.sidebarContentHolder}>
        <div className={style.sidebarHeader}>
          <motion.div
            initial={"hidden"}
            animate={sidebarAnimations}
            variants={elementAnimationVariants}
            className={style.mainLogo}
          >
            <div style={{ width: "100%" }}>
              <img
                src={"/images/essen-logo-light.svg"}
                alt={"Essen International Logo"}
                width="auto"
                height="auto"
              />
            </div>
          </motion.div>
          <motion.i
            initial={"hidden"}
            animate={sidebarAnimations}
            variants={elementAnimationVariants}
            className="icon-cross"
            onClick={() => setSidebar(false)}
          ></motion.i>
        </div>
        <div className={style.sidebarContent}>
          <motion.ul
            initial={"hidden"}
            animate={sidebarAnimations}
            variants={listAnimationVariants}
          >
            {menuItems &&
              menuItems.map((item, index) => (
                <motion.li key={index} variants={listItemAnimationVariants}>
                  <Link to={item.path} onClick={() => setSidebar(false)}>
                    {item.label.charAt(0).toUpperCase() +
                      item.label.slice(1).toLowerCase()}
                  </Link>
                </motion.li>
              ))}
          </motion.ul>
          <LanguageSwitcher theme={"light"} />
        </div>
      </div>
    </motion.div>
  );
};
