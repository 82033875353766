import React, { useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { inject, observer } from "mobx-react";
import { AnimatePresence, motion } from "framer-motion";
import { tx } from "~libs/i18n";

import { VideoElement, Label, Button, CustomCursor } from "~components";
import { UIStore } from "~stores";
import { ILatestItem } from "~models/PostTypes";
import { fadeInItem } from "~libs/framer/variants";

import * as style from "./style.module.scss";

export interface NewsFeedCardProps {
  isFiltered: boolean;
  activeCategory: string;
  uiStore?: UIStore;
  element: ILatestItem;
}

export const NewsFeedCard = inject("uiStore")(
  observer(({ element, isFiltered, uiStore, activeCategory }: NewsFeedCardProps) => {
    const { isEqualOrGreatherThanBreakpoint, setCustomCursorEvent } = uiStore;
    const [isAnimationEnd, setIsAnimationEnd] = useState(false);
    const el = {
      uri: element.uri,
      type: element.latestFields.latestSettings.type,
      title: element.title,
      description: element.latestFields.latestSettings.description,
      date: element.latestFields.latestSettings.date,
      season: element.latestFields.latestSettings.season,
      imageOrVideoGroup: element.latestFields.latestSettings.imageOrVideoGroup,
      hasLinkedDetailPage: element.latestFields.latestSettings.hasLinkedDetailPage,
      isFeatured: element.latestFields.latestSettings.isFeatured,
    };

    const renderFigure = (element) => {
      if (element.imageOrVideoSelect === "image") {
        return (
          element.image && (
            <GatsbyImage
              image={element.image.localFile.childImageSharp.gatsbyImageData}
              style={{ width: "100%" }}
              alt={element.image?.caption || ""}
            />
          )
        );
      } else if (element.imageOrVideoSelect === "video") {
        return (
          <VideoElement
            url={
              element.type === "vimeo" ? element.videoUrl : element.videoFile.mediaItemUrl
            }
            poster={element.videoPoster}
            controls={element.videoSettings.controls}
            loop={element.videoSettings.loop}
            freeAspectRatio={true}
          />
        );
      }
    };

    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={activeCategory}
          viewport={{
            once: true,
            amount: el.type === "news" ? 0.7 : 0.2,
          }}
          variants={!isFiltered ? fadeInItem : null}
          custom={!isFiltered ? 0 : null}
          initial={"hidden"}
          whileInView={"visible"}
          className={style.newsFeedCard}
          onAnimationComplete={() => setIsAnimationEnd(true)}
          onMouseEnter={() =>
            el.type === "news"
              ? el.hasLinkedDetailPage
                ? setCustomCursorEvent(true)
                : setCustomCursorEvent(false)
              : setCustomCursorEvent(true)
          }
          onMouseLeave={() => setCustomCursorEvent(false)}
        >
          {isAnimationEnd && <CustomCursor text={tx("cursor.readMore")} />}
          {el.hasLinkedDetailPage || el.type !== "news" ? (
            <Link to={el.uri}>
              <div className={el.type === "news" ? style.landscape : style.portrait}>
                {renderFigure(el.imageOrVideoGroup)}
              </div>
              <div className={style.overview}>
                <Label
                  data-name={el.type}
                  text={el.type.toUpperCase().replaceAll("-", " ")}
                />
                <p className={style.season}>
                  {el.season
                    ? el.season
                    : new Date(el.date).toLocaleString("default", {
                        month: "long",
                        year: "numeric",
                      })}
                </p>
              </div>
              <h5 className={style.title}>{el.title}</h5>
              {el.type === "news" ? (
                el.hasLinkedDetailPage ? (
                  <></>
                ) : (
                  <p
                    className={style.description}
                    dangerouslySetInnerHTML={{ __html: el.description }}
                  />
                )
              ) : (
                <></>
              )}
            </Link>
          ) : (
            <div className={style.linkLike}>
              {" "}
              <div className={el.type === "news" ? style.landscape : style.portrait}>
                {renderFigure(el.imageOrVideoGroup)}
              </div>
              <div className={style.overview}>
                <Label
                  data-name={el.type}
                  text={el.type.toUpperCase().replaceAll("-", " ")}
                />
                <p className={style.season}>
                  {el.season
                    ? el.season
                    : new Date(el.date).toLocaleString("default", {
                        month: "long",
                        year: "numeric",
                      })}
                </p>
              </div>
              <h5 className={style.title}>{el.title}</h5>
              {
                <p
                  className={style.description}
                  dangerouslySetInnerHTML={{ __html: el.description }}
                />
              }
            </div>
          )}
          {!isEqualOrGreatherThanBreakpoint("md") &&
            (el.type === "news" ? (
              el.hasLinkedDetailPage ? (
                <Link to={el.uri} className={style.mobileLink}>
                  <Button icon="icon-arrow-right" secondary>
                    READ MORE
                  </Button>
                </Link>
              ) : (
                <></>
              )
            ) : (
              <Link to={el.uri} className={style.mobileLink}>
                <Button icon="icon-arrow-right" secondary>
                  READ MORE
                </Button>
              </Link>
            ))}
        </motion.div>
      </AnimatePresence>
    );
  })
);
