import React, { Component } from "react";
import { ISections } from "~models";
import { LatestSections } from "~views/Sections";
import { SectionsContainer } from "~components";

interface TemplateProps {
  template: string;
  sections: ISections;
  bgColor?: string;
  settings: any;
}

export class LatestTemplate extends Component<TemplateProps> {
  render() {
    const { template, sections, bgColor } = this.props;

    const details = {
      tag: this.props.settings.universalTag.tagFields.tagName,
      season: this.props.settings.season,
      type: this.props.settings.type,
    };

    switch (template) {
      case "page_builder":
        return (
          <SectionsContainer bgColor={bgColor} template="page_builder">
            <LatestSections sections={sections} details={details} />
          </SectionsContainer>
        );
    }
  }
}
