// extracted by mini-css-extract-plugin
export var arrow = "Header-module--arrow--fddb9";
export var arrow_no = "Header-module--arrow_no--5a10c";
export var arrow_yes = "Header-module--arrow_yes--25a21";
export var dark = "Header-module--dark--4beef";
export var flex = "Header-module--flex--8b55a";
export var flex_1 = "Header-module--flex_1--c20ef";
export var flex_center = "Header-module--flex_center--c8d79";
export var flex_center_col = "Header-module--flex_center_col--6f3a6";
export var flex_col = "Header-module--flex_col--4771f";
export var flex_column = "Header-module--flex_column--2b6e3";
export var flex_fix_center_col = "Header-module--flex_fix_center_col--5feaa";
export var flex_fixed_center_col = "Header-module--flex_fixed_center_col--20c82";
export var flex_row = "Header-module--flex_row--9801b";
export var header = "Header-module--header--68a59";
export var headerHolder = "Header-module--header-holder--b300d";
export var headerInner = "Header-module--header-inner--d8f1c";
export var hiddenMenu = "Header-module--hidden-menu--94ed4";
export var hideDuplicatedLogo = "Header-module--hide-duplicated-logo--c1f35";
export var hideHeader = "Header-module--hide-header--0ef09";
export var imageAnimationHolder = "Header-module--image-animation-holder--97a2c";
export var languageSwitcherHolder = "Header-module--language-switcher-holder--a2959";
export var light = "Header-module--light--0c688";
export var linkAnimation = "Header-module--link-animation--01e51";
export var logoDark = "Header-module--logo-dark--7b066";
export var logoExceptionHolder = "Header-module--logo-exception-holder--95151";
export var logoLight = "Header-module--logo-light--c9924";
export var mainLogo = "Header-module--main-logo--f726e";
export var mainLogoLink = "Header-module--main-logo-link--4e06b";
export var menu = "Header-module--menu--e48e7";
export var menuHolder = "Header-module--menu-holder--513d0";
export var menuIcon = "Header-module--menu-icon--b6629";
export var mobileMenu = "Header-module--mobile-menu--23251";
export var rotating = "Header-module--rotating--d27a9";
export var showHeaderWithBg = "Header-module--show-header-with-bg--720d5";
export var swiperWrapper = "Header-module--swiper-wrapper--41ceb";
export var underline = "Header-module--underline--29f0f";
export var visibleMenu = "Header-module--visible-menu--a825d";
export var withSidebar = "Header-module--with-sidebar--504e2";