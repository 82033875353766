// extracted by mini-css-extract-plugin
export var arrow = "TextAndImageCollage-module--arrow--b1905";
export var arrow_no = "TextAndImageCollage-module--arrow_no--89db0";
export var arrow_yes = "TextAndImageCollage-module--arrow_yes--c659f";
export var dark = "TextAndImageCollage-module--dark--45e67";
export var flex = "TextAndImageCollage-module--flex--96cf4";
export var flex_1 = "TextAndImageCollage-module--flex_1--70039";
export var flex_center = "TextAndImageCollage-module--flex_center--e519b";
export var flex_center_col = "TextAndImageCollage-module--flex_center_col--65b8a";
export var flex_col = "TextAndImageCollage-module--flex_col--102f2";
export var flex_column = "TextAndImageCollage-module--flex_column--55776";
export var flex_fix_center_col = "TextAndImageCollage-module--flex_fix_center_col--a604e";
export var flex_fixed_center_col = "TextAndImageCollage-module--flex_fixed_center_col--30b0a";
export var flex_row = "TextAndImageCollage-module--flex_row--6f080";
export var imageAnimationHolder = "TextAndImageCollage-module--image-animation-holder--257fc";
export var imageHolder = "TextAndImageCollage-module--image-holder--6e4b1";
export var linkAnimation = "TextAndImageCollage-module--link-animation--094bf";
export var lowerText = "TextAndImageCollage-module--lower-text--9abd8";
export var rotating = "TextAndImageCollage-module--rotating--6a824";
export var swiperWrapper = "TextAndImageCollage-module--swiper-wrapper--20fa4";
export var textCenter = "TextAndImageCollage-module--text-center--0186a";
export var upperText = "TextAndImageCollage-module--upper-text--9684d";