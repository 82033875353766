// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--5bb5c";
export var arrow_no = "styles-module--arrow_no--10dd4";
export var arrow_yes = "styles-module--arrow_yes--302b6";
export var figure = "styles-module--figure--f2c58";
export var flex = "styles-module--flex--a9d54";
export var flex_1 = "styles-module--flex_1--7b79a";
export var flex_center = "styles-module--flex_center--e63a4";
export var flex_center_col = "styles-module--flex_center_col--b9fec";
export var flex_col = "styles-module--flex_col--f5d6b";
export var flex_column = "styles-module--flex_column--95cbd";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--d5a83";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--b8cd6";
export var flex_row = "styles-module--flex_row--fe9f0";
export var imageAnimationHolder = "styles-module--image-animation-holder--c8a06";
export var imageHolder = "styles-module--image-holder--00133";
export var landscape = "styles-module--landscape--ef2f8";
export var linkAnimation = "styles-module--link-animation--02343";
export var outer = "styles-module--outer--a2014";
export var partnersLabel = "styles-module--partners-label--e905d";
export var partnersLink = "styles-module--partners-link--143bc";
export var rotating = "styles-module--rotating--3843b";
export var swiperWrapper = "styles-module--swiper-wrapper--89411";
export var tagHolder = "styles-module--tag-holder--8c94c";