// extracted by mini-css-extract-plugin
export var arrow = "_404-module--arrow--c9ef8";
export var arrow_no = "_404-module--arrow_no--92798";
export var arrow_yes = "_404-module--arrow_yes--29eee";
export var buttonHolder = "_404-module--button-holder--dacc3";
export var flex = "_404-module--flex--cf4ad";
export var flex_1 = "_404-module--flex_1--c77cb";
export var flex_center = "_404-module--flex_center--a540d";
export var flex_center_col = "_404-module--flex_center_col--c1786";
export var flex_col = "_404-module--flex_col--608f7";
export var flex_column = "_404-module--flex_column--b0d47";
export var flex_fix_center_col = "_404-module--flex_fix_center_col--ba824";
export var flex_fixed_center_col = "_404-module--flex_fixed_center_col--fe4bd";
export var flex_row = "_404-module--flex_row--69ae7";
export var imageAnimationHolder = "_404-module--image-animation-holder--2c24d";
export var linkAnimation = "_404-module--link-animation--4cacc";
export var notFoundExtra = "_404-module--not-found-extra--f5ee6";
export var notFoundHolder = "_404-module--not-found-holder--a8953";
export var rotating = "_404-module--rotating--aa329";
export var swiperWrapper = "_404-module--swiper-wrapper--a66c9";