// extracted by mini-css-extract-plugin
export var arrow = "ExploreMore-module--arrow--0f7fa";
export var arrow_no = "ExploreMore-module--arrow_no--2e00e";
export var arrow_yes = "ExploreMore-module--arrow_yes--13c94";
export var card = "ExploreMore-module--card--0dc05";
export var cardHolder = "ExploreMore-module--card-holder--3d65e";
export var flex = "ExploreMore-module--flex--f5338";
export var flex_1 = "ExploreMore-module--flex_1--d1e56";
export var flex_center = "ExploreMore-module--flex_center--763a2";
export var flex_center_col = "ExploreMore-module--flex_center_col--be1db";
export var flex_col = "ExploreMore-module--flex_col--92911";
export var flex_column = "ExploreMore-module--flex_column--7f1fc";
export var flex_fix_center_col = "ExploreMore-module--flex_fix_center_col--732ad";
export var flex_fixed_center_col = "ExploreMore-module--flex_fixed_center_col--ebc48";
export var flex_row = "ExploreMore-module--flex_row--4c57d";
export var imageAnimationHolder = "ExploreMore-module--image-animation-holder--2dec8";
export var isReversed = "ExploreMore-module--is-reversed--ae498";
export var linkAnimation = "ExploreMore-module--link-animation--d6cd2";
export var rotating = "ExploreMore-module--rotating--f2ae1";
export var swiperWrapper = "ExploreMore-module--swiper-wrapper--437e6";
export var title = "ExploreMore-module--title--b1ab3";