import React, { useEffect, useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import { getCurrentLanguage, initTranslations, tx } from "~libs/i18n";

import { ISettings } from "~models";
import { Container, Row, Col, Button } from "~components";
import { withStore } from "~libs/mobx";
import { Header, Footer } from "~views/layouts";

import * as style from "./404.module.scss";

interface Props {
  settings?: ISettings;
}

const NotFoundPageComponent = ({}: Props) => {
  const pathname = typeof window !== "undefined" ? window.location.pathname : "";
  const [currentLanguage, setCurrentLanguage] = useState("");
  initTranslations(pathname.startsWith("/sv") ? "sv" : "en");

  useEffect(() => {
    setCurrentLanguage(getCurrentLanguage());
  }, []);

  return (
    <>
      <Header />
      <section data-sectionname="404">
        <Container>
          <Row>
            <Col>
              <div className={style.notFoundHolder}>
                <h1 className={style.notFoundTitle}>
                  <motion.span
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={{
                      hidden: {
                        opacity: 0,
                        y: -40,
                      },
                      visible: {
                        opacity: 1,
                        y: 0,
                      },
                    }}
                  >
                    {tx("notFound.line1")}
                  </motion.span>
                  <motion.span
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={{
                      hidden: {
                        opacity: 0,
                        x: 40,
                      },
                      visible: {
                        opacity: 1,
                        x: 0,
                        transition: {
                          delay: 0.2,
                        },
                      },
                    }}
                  >
                    <i>{tx("notFound.line2")}</i>
                  </motion.span>
                  <motion.span
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={{
                      hidden: {
                        opacity: 0,
                        x: -40,
                      },
                      visible: {
                        opacity: 1,
                        x: 0,
                        transition: {
                          delay: 0.4,
                        },
                      },
                    }}
                  >
                    <i>{tx("notFound.line3")}</i>
                  </motion.span>
                </h1>
                <motion.img
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={{
                    hidden: {
                      opacity: 0,
                      x: 40,
                    },
                    visible: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        delay: 0.6,
                      },
                    },
                  }}
                  src="/images/roulette.gif"
                  alt=""
                />
                <motion.img
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={{
                    hidden: {
                      opacity: 0,
                      x: 40,
                    },
                    visible: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        delay: 0.8,
                      },
                    },
                  }}
                  src="/images/fish.gif"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={style.notFoundExtra}>
        <h4
          className={style.sectionText}
          dangerouslySetInnerHTML={{
            __html: tx("notFound.extra"),
          }}
        >
          {}
        </h4>
        <div className={style.buttonHolder}>
          <Link to={`/${currentLanguage === "en" ? "" : currentLanguage}`}>
            <Button icon={"icon-arrow-right"} filled={true}>
              {tx("notFound.button")}
            </Button>
          </Link>
        </div>
      </section>
      <Footer showBottomRowInFooter={true} theme={"dark"} />
    </>
  );
};

const NotFoundPage = (props: ISettings) => (
  <StaticQuery
    query={query}
    render={(data) => <NotFoundPageComponent {...props} settings={data.siteSettings} />}
  />
);

const query = graphql`
  query {
    ...siteSettingsFragment
  }
`;

export default withStore(NotFoundPage);
