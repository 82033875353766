import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { createMenu, IFlatListMenu, ILanguage, IMenu } from "~models";

interface IAllWpMenu {
  allWpMenu: {
    menus: IFlatListMenu[];
  };
  wp: {
    languages: ILanguage[];
    defaultLanguage: ILanguage;
  };
}

interface Props {
  menu: IMenu[];
}

export const withMenu = <T extends Props>(Comp: React.ComponentType<T>) => {
  return (props: Omit<T, "options">) => (
    <StaticQuery
      query={graphql`
        query {
          allWpMenu {
            menus: nodes {
              locations
              menuItems {
                items: nodes {
                  id
                  parentId
                  label
                  url
                  path
                }
              }
            }
          }
          wp {
            languages {
              locale
              code
              id
              name
              slug
            }
            defaultLanguage {
              locale
              code
              id
              name
              slug
            }
          }
        }
      `}
      render={(data: IAllWpMenu) => (
        <Comp
          {...(props as any)}
          menu={data.allWpMenu.menus.map(createMenu)}
          languages={data.wp.languages}
          defaultLanguage={data.wp.defaultLanguage}
        />
      )}
    />
  );
};
