// extracted by mini-css-extract-plugin
export var arrow = "TextContent-module--arrow--afc00";
export var arrow_no = "TextContent-module--arrow_no--24c7d";
export var arrow_yes = "TextContent-module--arrow_yes--e2167";
export var flex = "TextContent-module--flex--b2524";
export var flex_1 = "TextContent-module--flex_1--6df2b";
export var flex_center = "TextContent-module--flex_center--040ae";
export var flex_center_col = "TextContent-module--flex_center_col--11b73";
export var flex_col = "TextContent-module--flex_col--8cf5f";
export var flex_column = "TextContent-module--flex_column--a7147";
export var flex_fix_center_col = "TextContent-module--flex_fix_center_col--3738c";
export var flex_fixed_center_col = "TextContent-module--flex_fixed_center_col--589fb";
export var flex_row = "TextContent-module--flex_row--8f25f";
export var imageAnimationHolder = "TextContent-module--image-animation-holder--878a9";
export var isBigger = "TextContent-module--is-bigger--20b31";
export var linkAnimation = "TextContent-module--link-animation--417a6";
export var noPadding = "TextContent-module--no-padding--80b5b";
export var rotating = "TextContent-module--rotating--fe414";
export var sectionText = "TextContent-module--section-text--081ce";
export var swiperWrapper = "TextContent-module--swiper-wrapper--d1d18";