import React from "react";
import { Link } from "gatsby";
import { inject, observer } from "mobx-react";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import { Container, Row, Col, Button } from "~components";
import { UIStore } from "~stores";
import { ILowerHeader, IImage } from "~models";
import {
  fadeInItem,
  imageAnimationVariants,
  imageHolderAnimationVariants,
  staggerChildren,
  staggerChildrenItem,
} from "~libs/framer/variants";

import * as style from "./LowerHeader.module.scss";

interface ImageWithGif {
  gifName: "bees" | "oyster";
  image: IImage;
  key?: any;
  textColor: any;
}

const ImageWithGif = ({ gifName, image, key }: ImageWithGif) => {
  return (
    <div key={key} className={style.imageHolder}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={imageHolderAnimationVariants}
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={imageAnimationVariants}
        >
          <GatsbyImage
            image={image?.localFile.childImageSharp.image}
            style={{ width: "100%" }}
            alt={image?.caption || ""}
          />
        </motion.div>
      </motion.div>
      <motion.img
        className={style.gif}
        src={`/images/${gifName}.gif`}
        alt="Cupcake Figure"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeInItem}
        custom={0.4}
      ></motion.img>
    </div>
  );
};

interface Props {
  section: ILowerHeader;
  uiStore?: UIStore;
}

export const LowerHeader = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { title, text, styleKey, linkButton, images, transition } = section;
    const { contentColor } = uiStore;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="LowerHeader"
        className={`${styleKey === "text_color_white" ? style.dark : ""}`}
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
      >
        <Container fluid>
          <Row>
            <Col>
              <figure className={style.figure}>
                <motion.figcaption
                  className={style.figcaption}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={staggerChildren}
                >
                  <motion.h1
                    variants={staggerChildrenItem}
                    className={style.title}
                    dangerouslySetInnerHTML={{ __html: title }}
                    style={{ color: contentColor }}
                  ></motion.h1>
                  {text && (
                    <motion.p
                      variants={staggerChildrenItem}
                      className={style.text}
                      dangerouslySetInnerHTML={{ __html: text }}
                      style={{ color: contentColor }}
                    ></motion.p>
                  )}
                  {linkButton && (
                    <motion.div variants={staggerChildrenItem}>
                      <Link to={linkButton.buttonLink.url}>
                        <Button
                          className={style.button}
                          icon={
                            styleKey === "text_color_white"
                              ? "icon-arrow-right"
                              : "icon-arrow-down"
                          }
                          dark={styleKey !== "text_color_white"}
                          filled={true}
                        >
                          {linkButton.buttonText}
                        </Button>
                      </Link>
                    </motion.div>
                  )}
                </motion.figcaption>
                {images &&
                  images.map((image, index) => (
                    <ImageWithGif
                      image={image.image}
                      key={index}
                      gifName={index === 0 ? "bees" : "oyster"}
                      textColor={contentColor}
                    />
                  ))}
              </figure>
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
