import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation, useDragControls } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Navigation } from "swiper";
import { inject, observer } from "mobx-react";

import { DraggableCard, Switcher, Container, Row, Col } from "~views/shared/components";
import { IDraggableCards } from "~models";
import { UIStore } from "~stores";
import { tx } from "~libs/i18n";

import * as style from "./DraggableCards.module.scss";
import "swiper/css/effect-cards";

const random = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min)) + min;

const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

interface Props {
  section: IDraggableCards;
  uiStore?: UIStore;
}

export const DraggableCards = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { cards, title } = section;
    const { isEqualOrGreatherThanBreakpoint } = uiStore;

    const switcherOptions = [
      {
        label: tx("draggableCards.stack"),
        action: () => shuffleCards(),
      },
      {
        label: tx("draggableCards.spread"),
        action: () => spreadCards(),
      },
    ];

    const initialIndexList = Array.from(Array(cards.length).keys()).map((e) => e + 2);
    const [constraintsWidth, setConstraintsWidth] = useState(0);
    const [constraintsHeight, setConstraintsHeight] = useState(0);
    const [indexList, setIndexList] = useState(initialIndexList);
    const [isShuffle, setShuffle] = useState(true);
    const isTablet =
      isEqualOrGreatherThanBreakpoint("md") && !isEqualOrGreatherThanBreakpoint("xl");
    const cardWidth = isTablet ? 320 : 376;

    const cardColumnGap = isTablet ? 24 : 32;
    const cardHeight = cardWidth * 1.382;
    const cardRowGap = isTablet ? 28 : 32;
    const constraintsRef = useRef(null);
    const navigationNextRef = useRef(null);
    const navigationPrevRef = useRef(null);
    const [isEnterViewport, setEnterViewport] = useState(false);
    const [initialActiveSwitch, setInitialActiveSwitch] = useState(0);
    const handleViewport = () => {
      if (!isEnterViewport) {
        setEnterViewport(true);
        setTimeout(() => {
          spreadCards();
          setShuffle(false);
          setInitialActiveSwitch(1);
        }, 400);
      }
    };

    const controls = useDragControls();
    const animationControls = useAnimation();

    const shuffleCards = () => {
      setTimeout(() => {
        setIndexList([...shuffleArray([...initialIndexList])]);
        animationControls.start("shuffle");
      }, 150);
      setShuffle(true);
    };

    const spreadCards = () => {
      setTimeout(() => {
        setIndexList([...initialIndexList]);
        animationControls.start("spread");
      }, 150);
      setShuffle(false);
    };

    useEffect(() => {
      const constraintsElement = constraintsRef?.current;
      setConstraintsWidth(constraintsRef.current.offsetWidth);
      setConstraintsHeight(constraintsRef.current.offsetHeight);
      setTimeout(() => {
        shuffleCards();
      }, 50);
      if (!constraintsElement) return;
      const constraintsElementObserver = new ResizeObserver(() => {
        setConstraintsWidth(constraintsRef.current.offsetWidth);
        setConstraintsHeight(constraintsRef.current.offsetHeight);
      });
      constraintsElementObserver.observe(constraintsElement);
      return () => {
        constraintsElementObserver.disconnect();
      };
    }, []);

    const cardVariants = {
      spread: (custom) => ({
        x: isTablet
          ? (constraintsWidth - (cardWidth * 2 + cardColumnGap)) / 2 +
            (custom % 2) * (cardWidth + cardColumnGap)
          : (constraintsWidth - (cardWidth * 3 + cardColumnGap * 2)) / 2 +
            (custom % 3) * (cardWidth + cardColumnGap),
        y: isTablet
          ? custom < 2
            ? 0
            : custom < 4
            ? cardHeight + cardColumnGap
            : cardHeight * 2 + cardColumnGap * 2
          : custom < 3
          ? 0
          : cardHeight + cardColumnGap,
        rotate: 0,
        transition: {
          duration: 0.3,
          ease: [0.22, 0, 0.18, 1],
        },
      }),
      shuffle: () => ({
        x: random(
          constraintsWidth / 2 - cardWidth / 2 - 100,
          constraintsWidth / 2 - cardWidth / 2 + 100
        ),
        y: random(0, constraintsHeight - cardHeight),
        rotate: random(-7, 7),
        transition: {
          duration: 0.3,
          ease: [0.22, 0, 0.18, 1],
        },
      }),
    };

    return (
      <section data-sectionname="DraggableCards" className={style.draggableCards}>
        <Container fluid>
          <Row>
            <Col>
              <div className={style.heading}>
                <h2 className="h4" dangerouslySetInnerHTML={{ __html: title }} />
                <div className={style.switcherHolder}>
                  <Switcher
                    options={switcherOptions}
                    initialActiveIndex={initialActiveSwitch}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <motion.div
                onViewportEnter={() => handleViewport()}
                viewport={{ once: true }}
              />
              <motion.div
                className={style.deck}
                ref={constraintsRef}
                animate={{
                  height: isShuffle
                    ? isTablet
                      ? cardHeight * 1.6
                      : cardHeight * 1.4
                    : isTablet
                    ? cardHeight * 3 + cardRowGap * 2 + 80
                    : cardHeight * 2 + cardRowGap + 80,
                }}
                transition={{
                  type: "tween",
                  duration: 0.14,
                }}
              >
                {cards.map((card, index) => (
                  <motion.div
                    key={index}
                    custom={Number(index)}
                    className={style.cardHolder}
                    style={{
                      width: cardWidth,
                      height: cardHeight,
                      zIndex: indexList[index],
                    }}
                    variants={cardVariants}
                    animate={animationControls}
                    drag
                    dragElastic
                    dragMomentum
                    dragControls={controls}
                    whileHover={{ scale: 1.03 }}
                    whileDrag={{ cursor: "grabbing" }}
                    dragListener
                    dragConstraints={constraintsRef}
                    onMouseDown={() => {
                      let newIndexList = [];
                      let highestValue = 0;
                      for (let i in indexList) {
                        if (indexList[i] > highestValue) {
                          highestValue = indexList[i];
                        }
                        newIndexList.push(indexList[i]);
                      }
                      newIndexList[index] = highestValue + 1;
                      setIndexList([...newIndexList]);
                    }}
                  >
                    <DraggableCard
                      title={card.title}
                      description={card.content}
                      gif={card.gifName}
                      // loadGif={false}
                    />
                  </motion.div>
                ))}
              </motion.div>
              <div className={style.contentHolder}>
                <div className={style.sliderHolder}>
                  <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    modules={[EffectCards, Navigation]}
                    className="mySwiper"
                    navigation={{
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                      swiper.navigation.nextEl = navigationNextRef.current;
                      swiper.navigation.prevEl = navigationPrevRef.current;
                    }}
                  >
                    {cards.map((card, index) => (
                      <SwiperSlide key={index}>
                        <div className={style.sliderItemHolder}>
                          <DraggableCard
                            title={card.title}
                            description={card.content}
                            gif={card.gifName}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className={style.buttonHolder}>
                    <button ref={navigationPrevRef}>
                      <i className={"icon-arrow-left"}></i>
                    </button>
                    <button ref={navigationNextRef}>
                      <i className={"icon-arrow-right"}></i>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={style.spacing}></div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  })
);
