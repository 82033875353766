import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useScroll, motion, useTransform } from "framer-motion";

import { IHero } from "~models";
import { Container, Row, Col, VideoElement } from "~views/shared/components";
import { UIStore } from "~stores";
import { staggerChildren, staggerChildrenItem } from "~libs/framer/variants";

import * as style from "./Hero.module.scss";

interface Props {
  section: IHero;
  uiStore?: UIStore;
}

export const Hero = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const [hookedYPostion, setHookedYPosition] = useState(0);
    const { sectionText, transition, randomizedShowReel } = section;
    const { contentColor, isEqualOrGreatherThanBreakpoint } = uiStore;

    const { scrollY } = useScroll();
    useEffect(() => {
      scrollY.onChange((v) => setHookedYPosition(v));
    }, [scrollY]);

    // const elementY = useTransform(scrollY, [0, 100], [0, 100]);
    const videoHolderWidth = useTransform(scrollY, [0, 172], ["80%", "100%"]);
    const videoHolderMarginLeft = useTransform(scrollY, [0, 172], ["10%", "0%"]);

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    const videoElementRef = useRef(null);

    const [heroElements, setHeroElements] = useState([]);

    useEffect(() => {
      let shuffledHeroElements = randomizedShowReel.sort(() => Math.random() - 0.5);
      setHeroElements(shuffledHeroElements.slice(0, 4));
    }, []);

    // NOTE: Hacky solution for home page's background colour bug
    useEffect(() => {
      const timer = setTimeout(() => {
        uiStore.setBackgroundColor(transition.transitionBackgroundColor);
        uiStore.setContentColor(transition.transitionTextColor);
      }, 100);
      return () => clearTimeout(timer);
    }, []);

    const [switcher, setSwitcher] = useState(true);

    const listenScroll = () => {
      if (videoElementRef.current.getBoundingClientRect().top > 0) {
        if (switcher) {
          uiStore.allowLogoSlideAnimate(false);
          setSwitcher(false);
          if (transition && transition.transitionAnimation === "transition") {
            uiStore.setBackgroundColor(transition.transitionBackgroundColor);
            uiStore.setContentColor(transition.transitionTextColor);
          }
        }
      } else {
        uiStore.allowLogoSlideAnimate(true);
        setSwitcher(true);
      }
    };

    useEffect(() => {
      window.addEventListener("scroll", listenScroll);
      return () => window.removeEventListener("scroll", listenScroll);
    }, [switcher]);

    return (
      <motion.section
        data-sectionname="Hero"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-40% -40%", amount: 0.5 }}
        className={
          isEqualOrGreatherThanBreakpoint("xl")
            ? hookedYPostion > 100
              ? style.unStick
              : style.stick
            : isEqualOrGreatherThanBreakpoint("md")
            ? hookedYPostion > 180
              ? style.unStick
              : style.stick
            : hookedYPostion > 180
            ? style.unStick
            : style.stick
        }
      >
        <Container fluid className="bigger-container-exception">
          <Row>
            <Container fluid>
              <Row>
                <Col>
                  <motion.div
                    className={style.titleHolder}
                    // style={{ y: elementY }}
                  >
                    <motion.h3
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      variants={staggerChildren}
                      className={style.title}
                      style={{ color: contentColor }}
                    >
                      {sectionText.split("<br/>").map((t, index) => (
                        <motion.span
                          variants={staggerChildrenItem}
                          custom={Number(index) + 1}
                          key={index}
                          dangerouslySetInnerHTML={{ __html: t }}
                          style={{ color: contentColor }}
                        />
                      ))}
                    </motion.h3>
                  </motion.div>
                </Col>
              </Row>
            </Container>
          </Row>
          <Row>
            {Number(heroElements.length) > 0 && (
              <motion.div
                style={{
                  // y: elementY,
                  width: videoHolderWidth,
                  marginLeft: videoHolderMarginLeft,
                }}
                className={style.videoHolder}
                ref={videoElementRef}
              >
                {heroElements[0].imageOrVideoGroup.imageOrVideoSelect === "image" && (
                  <GatsbyImage
                    image={
                      heroElements[0].imageOrVideoGroup.image?.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    style={{ width: "100%" }}
                    alt={heroElements[0].imageOrVideoGroup.image?.caption}
                  />
                )}

                {heroElements[0].imageOrVideoGroup.imageOrVideoSelect === "video" && (
                  <VideoElement
                    url={
                      heroElements[0].imageOrVideoGroup.type === "vimeo"
                        ? heroElements[0].imageOrVideoGroup.videoUrl
                        : heroElements[0].imageOrVideoGroup.videoFile.mediaItemUrl
                    }
                    poster={heroElements[0].imageOrVideoGroup.videoPoster}
                    controls={heroElements[0].imageOrVideoGroup.videoSettings.controls}
                    loop={heroElements[0].imageOrVideoGroup.videoSettings.loop}
                    freeAspectRatio={true}
                  />
                )}
              </motion.div>
            )}
          </Row>
        </Container>
      </motion.section>
    );
  })
);
