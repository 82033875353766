// extracted by mini-css-extract-plugin
export var arrow = "BodyText-module--arrow--625ff";
export var arrow_no = "BodyText-module--arrow_no--197f5";
export var arrow_yes = "BodyText-module--arrow_yes--00b03";
export var flex = "BodyText-module--flex--e86a6";
export var flex_1 = "BodyText-module--flex_1--621c1";
export var flex_center = "BodyText-module--flex_center--a5630";
export var flex_center_col = "BodyText-module--flex_center_col--5768d";
export var flex_col = "BodyText-module--flex_col--f8710";
export var flex_column = "BodyText-module--flex_column--e88de";
export var flex_fix_center_col = "BodyText-module--flex_fix_center_col--2c606";
export var flex_fixed_center_col = "BodyText-module--flex_fixed_center_col--f5f97";
export var flex_row = "BodyText-module--flex_row--f8513";
export var imageAnimationHolder = "BodyText-module--image-animation-holder--0c93d";
export var linkAnimation = "BodyText-module--link-animation--b894d";
export var rotating = "BodyText-module--rotating--da769";
export var swiperWrapper = "BodyText-module--swiper-wrapper--15d65";
export var titleAndTextHolder = "BodyText-module--title-and-text-holder--156ad";