export default {
  translation: {
    general: {
      loadMore: "LADDA FLER",
      readMore: "LÄS MER",
      mailTo: "Maila",
    },
    notFound: {
      line1: "This page",
      line2: "does not",
      line3: "exist",
      extra: "Please don't rage,<br /><i>just go back to the start page</i>",
      button: "HEAD BACK",
    },
    newsletter: {
      thankYou: "TACK FÖR ATT DU PRENUMERERAR!",
      already: "DU PRENUMERERAR REDAN! KLICKA FÖR ATT FÖRSÖKA IGEN.",
      problem: "SERVERPROBLEM! KLICKA FÖR ATT FÖRSÖKA IGEN.",
      signUp: "ANMÄL DIG TILL VÅRT NYHETSBREV",
    },
    cursor: {
      readMore: "LÄS MER",
      viewWork: "SE CASE",
    },
    insights: {
      readTime: "MINUTERS LÄSNING",
    },
    whitePaperForm: {
      firstName: "FÖRNAMN *",
      lastName: "EFTERNAMN *",
      companyName: "FÖRETAG *",
      emailAddress: "E-POSTADRESS *",
      newsletter: "JA, JAG VILL LÄSA FLER ARTIKLAR FRÅN ESSEN.",
      privacy:
        "JA, JAG HAR LÄST OCH GODKÄNNER <a href='/privacy-policy'>INTEGRITETSPOLICYN</a>.",
      button: "SKICKA IN",
    },
    draggableCards: {
      stack: "STAPLA",
      spread: "SPRID",
    },
    services: {
      readMore: "LÄS MER",
      close: "STÄNG ",
    },
  },
};
