// extracted by mini-css-extract-plugin
export var arrow = "PartnerCitation-module--arrow--43e0e";
export var arrow_no = "PartnerCitation-module--arrow_no--c0b84";
export var arrow_yes = "PartnerCitation-module--arrow_yes--c200c";
export var citation = "PartnerCitation-module--citation--a4ecb";
export var flex = "PartnerCitation-module--flex--e1226";
export var flex_1 = "PartnerCitation-module--flex_1--f8732";
export var flex_center = "PartnerCitation-module--flex_center--c71a7";
export var flex_center_col = "PartnerCitation-module--flex_center_col--6aabb";
export var flex_col = "PartnerCitation-module--flex_col--ce234";
export var flex_column = "PartnerCitation-module--flex_column--ba23f";
export var flex_fix_center_col = "PartnerCitation-module--flex_fix_center_col--2ccd8";
export var flex_fixed_center_col = "PartnerCitation-module--flex_fixed_center_col--4e064";
export var flex_row = "PartnerCitation-module--flex_row--465a7";
export var imageAnimationHolder = "PartnerCitation-module--image-animation-holder--9c8ba";
export var linkAnimation = "PartnerCitation-module--link-animation--bed40";
export var rotating = "PartnerCitation-module--rotating--8964b";
export var swiperWrapper = "PartnerCitation-module--swiper-wrapper--9dc2e";