import React from "react";
import { motion } from "framer-motion";
import { inject, observer } from "mobx-react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container, Row, Col, VideoElement } from "~components";
import { IImageRow } from "~models";
import { UIStore } from "~stores";
import {
  imageAnimationVariants,
  imageHolderAnimationVariants,
} from "~libs/framer/variants";

import * as style from "./ImageRow.module.scss";

interface Props {
  section: IImageRow;
  uiStore?: UIStore;
}

export const ImageRow = inject(`uiStore`)(
  observer(({ section, uiStore }: Props) => {
    const {
      imageOrVideoGroup1,
      imageOrVideoGroup2,
      transition,
      typeSelection,
      styleKey,
      overflow,
    } = section;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="ImageRow"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
      >
        <Container fluid>
          <Row>
            {typeSelection.startsWith("single") ? (
              <Col>
                <div
                  className={`
                  ${style.singleImageHolder} 
                  ${
                    typeSelection === "single_small_landscape"
                      ? style.singleSmallLandscape
                      : ""
                  }
                  ${styleKey === "reversed" ? style.isReversed : ""}`}
                >
                  <Col
                    xs={typeSelection === "single_small_landscape" ? 2 : 4}
                    md={typeSelection === "single_small_landscape" ? 4 : 5}
                    lg={typeSelection === "single_small_landscape" ? 4 : 6}
                  >
                    <div></div>
                  </Col>
                  <Col
                    xs={typeSelection === "single_small_landscape" ? 10 : 8}
                    md={typeSelection === "single_small_landscape" ? 8 : 7}
                    lg={typeSelection === "single_small_landscape" ? 8 : 6}
                  >
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      variants={imageHolderAnimationVariants}
                      className={style.imageAnimationHolder}
                    >
                      <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={imageAnimationVariants}
                      >
                        <div className={style.figureHolder}>
                          {imageOrVideoGroup1.imageOrVideoSelect === "image" ? (
                            <GatsbyImage
                              image={
                                imageOrVideoGroup1.image?.localFile.childImageSharp.image
                              }
                              style={{ width: "100%" }}
                              alt={imageOrVideoGroup1.image?.caption}
                            />
                          ) : (
                            <VideoElement
                              url={
                                imageOrVideoGroup1.type === "vimeo"
                                  ? imageOrVideoGroup1.videoUrl
                                  : imageOrVideoGroup1.videoFile.mediaItemUrl
                              }
                              poster={imageOrVideoGroup1.videoPoster}
                              controls={imageOrVideoGroup1.videoSettings.controls}
                              loop={imageOrVideoGroup1.videoSettings.loop}
                              freeAspectRatio={true}
                            />
                          )}
                        </div>
                      </motion.div>
                    </motion.div>
                  </Col>
                </div>
              </Col>
            ) : null}
            {typeSelection === "two_portrait" && (
              <Col>
                <div
                  className={`
                  ${style.multipleImageHolder} 
                  ${style.twoPortrait} 
                  ${styleKey === "reversed" ? style.isReversed : ""}`}
                >
                  <Col
                    xs={styleKey === "reversed" ? 5 : 6}
                    md={styleKey === "reversed" ? 4 : 5}
                    lg={styleKey === "reversed" ? 3 : 5}
                    className={"offset-lg-1"}
                  >
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      variants={imageHolderAnimationVariants}
                      className={style.imageAnimationHolder}
                    >
                      <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={imageAnimationVariants}
                      >
                        <div className={style.figureHolder}>
                          {imageOrVideoGroup1.imageOrVideoSelect === "image" ? (
                            <GatsbyImage
                              image={
                                imageOrVideoGroup1.image?.localFile.childImageSharp.image
                              }
                              style={{ width: "100%" }}
                              alt={imageOrVideoGroup1.image?.caption}
                            />
                          ) : (
                            <VideoElement
                              url={
                                imageOrVideoGroup1.type === "vimeo"
                                  ? imageOrVideoGroup1.videoUrl
                                  : imageOrVideoGroup1.videoFile.mediaItemUrl
                              }
                              poster={imageOrVideoGroup1.videoPoster}
                              controls={imageOrVideoGroup1.videoSettings.controls}
                              loop={imageOrVideoGroup1.videoSettings.loop}
                              freeAspectRatio={true}
                            />
                          )}
                        </div>
                      </motion.div>
                    </motion.div>
                  </Col>
                  <Col
                    xs={styleKey === "reversed" ? 6 : 5}
                    md={styleKey === "reversed" ? 5 : 4}
                    lg={styleKey === "reversed" ? 5 : 3}
                    className={"offset-1 offset-md-2"}
                  >
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      variants={imageHolderAnimationVariants}
                      className={style.imageAnimationHolder}
                    >
                      <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={imageAnimationVariants}
                      >
                        <div className={style.figureHolder}>
                          {imageOrVideoGroup2.imageOrVideoSelect === "image" ? (
                            <GatsbyImage
                              image={
                                imageOrVideoGroup2.image?.localFile.childImageSharp.image
                              }
                              style={{ width: "100%" }}
                              alt={imageOrVideoGroup2.image?.caption}
                            />
                          ) : (
                            <VideoElement
                              url={
                                imageOrVideoGroup2.type === "vimeo"
                                  ? imageOrVideoGroup2.videoUrl
                                  : imageOrVideoGroup2.videoFile.mediaItemUrl
                              }
                              poster={imageOrVideoGroup2.videoPoster}
                              controls={imageOrVideoGroup2.videoSettings.controls}
                              loop={imageOrVideoGroup2.videoSettings.loop}
                              freeAspectRatio={true}
                            />
                          )}
                        </div>
                      </motion.div>
                    </motion.div>
                  </Col>
                </div>
              </Col>
            )}
            {typeSelection === "portrait_and_landscape" && (
              <Col>
                <div
                  className={`
                  ${style.multipleImageHolder}
                  ${style.portraitAndLandscape} 
                  ${styleKey === "reversed" ? style.isReversed : ""}
                  ${
                    overflow === "leftOverflow"
                      ? style.leftOverflow
                      : overflow === "rightOverflow"
                      ? style.rightOverflow
                      : ""
                  }
                  `}
                >
                  <Col
                    xs={styleKey === "reversed" ? 6 : 6}
                    md={styleKey === "reversed" ? 5 : 6}
                    lg={styleKey === "reversed" ? 5 : 5}
                    className={`${styleKey === "reversed" ? "" : ""}`}
                  >
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      variants={imageHolderAnimationVariants}
                      className={style.imageAnimationHolder}
                    >
                      <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={imageAnimationVariants}
                      >
                        <div className={style.figureHolder}>
                          {imageOrVideoGroup1.imageOrVideoSelect === "image" ? (
                            <GatsbyImage
                              image={
                                imageOrVideoGroup1.image?.localFile.childImageSharp.image
                              }
                              style={{ width: "100%" }}
                              alt={imageOrVideoGroup1.image?.caption}
                            />
                          ) : (
                            <VideoElement
                              url={
                                imageOrVideoGroup1.type === "vimeo"
                                  ? imageOrVideoGroup1.videoUrl
                                  : imageOrVideoGroup1.videoFile.mediaItemUrl
                              }
                              poster={imageOrVideoGroup1.videoPoster}
                              controls={imageOrVideoGroup1.videoSettings.controls}
                              loop={imageOrVideoGroup1.videoSettings.loop}
                              freeAspectRatio={true}
                            />
                          )}
                        </div>
                      </motion.div>
                    </motion.div>
                  </Col>
                  <Col
                    xs={styleKey === "reversed" ? 6 : 6}
                    md={styleKey === "reversed" ? 6 : 5}
                    lg={styleKey === "reversed" ? 5 : 5}
                    className={`${
                      styleKey === "reversed"
                        ? "offset-md-1 offset-lg-2"
                        : "offset-md-1 offset-lg-2"
                    }`}
                  >
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      variants={imageHolderAnimationVariants}
                      className={style.imageAnimationHolder}
                    >
                      <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={imageAnimationVariants}
                      >
                        <div className={style.figureHolder}>
                          {imageOrVideoGroup2.imageOrVideoSelect === "image" ? (
                            <GatsbyImage
                              image={
                                imageOrVideoGroup2.image?.localFile.childImageSharp.image
                              }
                              style={{ width: "100%" }}
                              alt={imageOrVideoGroup2.image?.caption}
                            />
                          ) : (
                            <VideoElement
                              url={
                                imageOrVideoGroup2.type === "vimeo"
                                  ? imageOrVideoGroup2.videoUrl
                                  : imageOrVideoGroup2.videoFile.mediaItemUrl
                              }
                              poster={imageOrVideoGroup2.videoPoster}
                              controls={imageOrVideoGroup2.videoSettings.controls}
                              loop={imageOrVideoGroup2.videoSettings.loop}
                              freeAspectRatio={true}
                            />
                          )}
                        </div>
                      </motion.div>
                    </motion.div>
                  </Col>
                </div>
              </Col>
            )}
            {typeSelection === "two_landscape" && (
              <Col>
                <div
                  className={`
                  ${style.multipleImageHolder} ${style.twoLandscape} 
                  ${styleKey === "reversed" ? style.isReversed : ""}
                  ${
                    overflow === "leftOverflow"
                      ? style.leftOverflow
                      : overflow === "rightOverflow"
                      ? style.rightOverflow
                      : ""
                  }`}
                >
                  <Col
                    xs={styleKey === "reversed" ? 6 : 6}
                    md={styleKey === "reversed" ? 6 : 5}
                    lg={styleKey === "reversed" ? 6 : 5}
                  >
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      variants={imageHolderAnimationVariants}
                      className={style.imageAnimationHolder}
                    >
                      <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={imageAnimationVariants}
                      >
                        <div
                          className={`${style.figureHolder} ${
                            imageOrVideoGroup1.imageOrVideoSelect === "image" &&
                            !imageOrVideoGroup1.image
                              ? style.empty
                              : style.notEmpty
                          }`}
                        >
                          {imageOrVideoGroup1.imageOrVideoSelect === "image" ? (
                            <GatsbyImage
                              image={
                                imageOrVideoGroup1.image?.localFile.childImageSharp.image
                              }
                              style={{ width: "100%" }}
                              alt={imageOrVideoGroup1.image?.caption}
                            />
                          ) : (
                            <VideoElement
                              url={
                                imageOrVideoGroup1.type === "vimeo"
                                  ? imageOrVideoGroup1.videoUrl
                                  : imageOrVideoGroup1.videoFile.mediaItemUrl
                              }
                              poster={imageOrVideoGroup1.videoPoster}
                              controls={imageOrVideoGroup1.videoSettings.controls}
                              loop={imageOrVideoGroup1.videoSettings.loop}
                              freeAspectRatio={true}
                            />
                          )}
                        </div>
                      </motion.div>
                    </motion.div>
                  </Col>
                  <Col
                    xs={styleKey === "reversed" ? 6 : 6}
                    md={styleKey === "reversed" ? 5 : 6}
                    lg={styleKey === "reversed" ? 5 : 6}
                    className={"offset-md-1"}
                  >
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      variants={imageHolderAnimationVariants}
                      className={style.imageAnimationHolder}
                    >
                      <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={imageAnimationVariants}
                      >
                        <div
                          className={`${style.figureHolder} ${
                            imageOrVideoGroup2.imageOrVideoSelect === "image" &&
                            !imageOrVideoGroup2.image
                              ? style.empty
                              : style.notEmpty
                          }`}
                        >
                          {imageOrVideoGroup2.imageOrVideoSelect === "image" ? (
                            <GatsbyImage
                              image={
                                imageOrVideoGroup2.image?.localFile.childImageSharp.image
                              }
                              style={{ width: "100%" }}
                              alt={imageOrVideoGroup2.image?.caption}
                            />
                          ) : (
                            <VideoElement
                              url={
                                imageOrVideoGroup2.type === "vimeo"
                                  ? imageOrVideoGroup2.videoUrl
                                  : imageOrVideoGroup2.videoFile.mediaItemUrl
                              }
                              poster={imageOrVideoGroup2.videoPoster}
                              controls={imageOrVideoGroup2.videoSettings.controls}
                              loop={imageOrVideoGroup2.videoSettings.loop}
                              freeAspectRatio={true}
                            />
                          )}
                        </div>
                      </motion.div>
                    </motion.div>
                  </Col>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </motion.section>
    );
  })
);
