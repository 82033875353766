import i18next from "i18next";
import en from "./translations/en";
import sv from "./translations/sv";

let inited: string | boolean = false;
export const initTranslations = (lang?: "en" | "sv") => {
  if (inited === lang) {
    return;
  }
  inited = lang;
  i18next.init({
    lng: lang || "en",
    debug: false,
    cleanCode: true,
    resources: {
      en,
      sv,
    },
    interpolation: {
      format: (value, format, _) => {
        switch (format) {
          case "upper":
            return value.toUpperCase();
          case "lower":
            return value.toLowerCase();
          case "capital":
            return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
        }

        return value;
      },
    },
  });
};
