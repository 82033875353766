// extracted by mini-css-extract-plugin
export var arrow = "Footer-module--arrow--9c4f8";
export var arrow_no = "Footer-module--arrow_no--bf1f1";
export var arrow_yes = "Footer-module--arrow_yes--fd007";
export var copyright = "Footer-module--copyright--c0c37";
export var flex = "Footer-module--flex--9f927";
export var flex_1 = "Footer-module--flex_1--3ccb4";
export var flex_center = "Footer-module--flex_center--eff13";
export var flex_center_col = "Footer-module--flex_center_col--5788c";
export var flex_col = "Footer-module--flex_col--18d4e";
export var flex_column = "Footer-module--flex_column--2a17a";
export var flex_fix_center_col = "Footer-module--flex_fix_center_col--827d4";
export var flex_fixed_center_col = "Footer-module--flex_fixed_center_col--92959";
export var flex_row = "Footer-module--flex_row--66d9d";
export var footer = "Footer-module--footer--9f4b2";
export var footerBottomRow = "Footer-module--footer-bottom-row--02d5a";
export var footerUpperRow = "Footer-module--footer-upper-row--aa440";
export var imageAnimationHolder = "Footer-module--image-animation-holder--a9866";
export var light = "Footer-module--light--8fb55";
export var linkAnimation = "Footer-module--link-animation--0a3e2";
export var navigation = "Footer-module--navigation--c4b67";
export var rotating = "Footer-module--rotating--04e6a";
export var socialMedia = "Footer-module--social-media--78a73";
export var swiperWrapper = "Footer-module--swiper-wrapper--5c789";