import React from "react";
import { motion } from "framer-motion";
import { inject, observer } from "mobx-react";

import { IProjectScope } from "~models";
import { Container, Row, Col } from "~components";
import { UIStore } from "~stores";
import { fadeInItem } from "~libs/framer/variants";

import * as style from "./ProjectScope.module.scss";

interface ScopeProps {
  title: string;
  text: string;
  contentColor?: string;
}

const Scope = ({ title, text, contentColor }: ScopeProps) => {
  return (
    <div className={style.titleAndText}>
      <motion.p
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeInItem}
        custom={0}
        className={`${style.title} body-large`}
        dangerouslySetInnerHTML={{ __html: title }}
        style={{ color: contentColor }}
      />
      <motion.p
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeInItem}
        custom={0}
        dangerouslySetInnerHTML={{ __html: text }}
        style={{ color: contentColor }}
      />
    </div>
  );
};

interface Props {
  section: IProjectScope;
  uiStore?: UIStore;
}

export const ProjectScope = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { sectionTitle, titleAndText, transition } = section;
    const { contentColor } = uiStore;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="ProjectScope"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
      >
        <Container fluid>
          <Row>
            <Col md={12} lg={10} className={"offset-lg-1"}>
              <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInItem}
                custom={0}
                className={`body-large ${style.generalTitle}`}
                dangerouslySetInnerHTML={{ __html: sectionTitle }}
                style={{ color: contentColor }}
              />
            </Col>
            {titleAndText.map((tat, index) => (
              <Col md={4} lg={3} className={"offset-lg-1"} key={index}>
                <Scope
                  title={tat.titleAndTextGroup.title}
                  text={tat.titleAndTextGroup.text}
                  contentColor={contentColor}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </motion.section>
    );
  })
);
