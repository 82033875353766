import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import { Container, Row, Col } from "~components";
import { ITextAndImageCollage } from "~models";
import {
  imageAnimationVariants,
  imageHolderAnimationVariants,
  staggerChildren,
  staggerChildrenItem,
} from "~libs/framer/variants";

import * as style from "./TextAndImageCollage.module.scss";

interface Props {
  section: ITextAndImageCollage;
}

interface UpperTextProps {
  title: string;
  text: string;
  styleKey: string;
}

interface LowerTextProps {
  title: string;
  text: string;
  styleKey: string;
}

const UpperText = ({ title, text, styleKey }: UpperTextProps) => {
  return (
    <Container className={style.upperText} fluid={styleKey !== "middle"}>
      <Row>
        <Col
          md={styleKey === "middle" ? 10 : 8}
          lg={styleKey === "middle" ? 10 : 6}
          className={
            styleKey === "middle"
              ? "offset-md-1 text-center"
              : styleKey === "right"
              ? "offset-md-4 offset-lg-6"
              : "offset-lg-1"
          }
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={staggerChildren}
          >
            <motion.h4
              variants={staggerChildrenItem}
              dangerouslySetInnerHTML={{ __html: title }}
            ></motion.h4>
            <motion.p
              variants={staggerChildrenItem}
              dangerouslySetInnerHTML={{ __html: text }}
            ></motion.p>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

const LowerText = ({ title, text, styleKey }: LowerTextProps) => {
  return (
    <Container className={style.lowerText} fluid={styleKey !== "middle"}>
      <Row>
        <Col
          md={styleKey === "middle" ? 10 : 8}
          lg={styleKey === "middle" ? 10 : 6}
          className={
            styleKey === "middle"
              ? "offset-md-1 text-center"
              : styleKey === "right"
              ? "offset-md-4 offset-lg-6"
              : ""
          }
        >
          <motion.div
            variants={staggerChildren}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <motion.h4
              variants={staggerChildrenItem}
              dangerouslySetInnerHTML={{ __html: title }}
            ></motion.h4>
            <motion.p
              variants={staggerChildrenItem}
              dangerouslySetInnerHTML={{ __html: text }}
            ></motion.p>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export const TextAndImageCollage = ({ section }: Props) => {
  const { upperText, lowerText, image } = section;

  return (
    <section
      data-sectionname="TextAndImageCollage"
      className={upperText.upperTextTitle ? style.dark : ""}
    >
      <figure className={style.figure}>
        {upperText.upperTextTitle && (
          <UpperText
            title={upperText.upperTextTitle}
            text={upperText.upperTextDescription}
            styleKey={upperText.styleKey}
          />
        )}

        <Container fluid={true}>
          <Row>
            <Col>
              <motion.figure
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={imageHolderAnimationVariants}
                className={style.imageHolder}
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={imageAnimationVariants}
                >
                  <GatsbyImage
                    image={image?.localFile.childImageSharp.image}
                    style={{ width: "100%" }}
                    alt={image?.caption || ""}
                  />
                </motion.div>
              </motion.figure>
            </Col>
          </Row>
        </Container>

        {lowerText.lowerTextTitle && (
          <LowerText
            title={lowerText.lowerTextTitle}
            text={lowerText.lowerTextDescription}
            styleKey={lowerText.styleKey}
          />
        )}
      </figure>
    </section>
  );
};
