// extracted by mini-css-extract-plugin
export var arrow = "Hero-module--arrow--e17c0";
export var arrow_no = "Hero-module--arrow_no--3b43a";
export var arrow_yes = "Hero-module--arrow_yes--5597f";
export var flex = "Hero-module--flex--ab289";
export var flex_1 = "Hero-module--flex_1--99c72";
export var flex_center = "Hero-module--flex_center--ef2ad";
export var flex_center_col = "Hero-module--flex_center_col--3639b";
export var flex_col = "Hero-module--flex_col--a3801";
export var flex_column = "Hero-module--flex_column--93f2c";
export var flex_fix_center_col = "Hero-module--flex_fix_center_col--501c7";
export var flex_fixed_center_col = "Hero-module--flex_fixed_center_col--e4a0f";
export var flex_row = "Hero-module--flex_row--2db8b";
export var imageAnimationHolder = "Hero-module--image-animation-holder--fc8e7";
export var linkAnimation = "Hero-module--link-animation--67486";
export var rotating = "Hero-module--rotating--d21b2";
export var stick = "Hero-module--stick--4fb70";
export var swiperWrapper = "Hero-module--swiper-wrapper--d6a47";
export var title = "Hero-module--title--9dd7a";
export var unStick = "Hero-module--unStick--1701f";
export var videoHolder = "Hero-module--video-holder--b7fdc";