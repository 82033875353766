// extracted by mini-css-extract-plugin
export var arrow = "Sidebar-module--arrow--de27d";
export var arrow_no = "Sidebar-module--arrow_no--ccde4";
export var arrow_yes = "Sidebar-module--arrow_yes--e2fa0";
export var flex = "Sidebar-module--flex--23099";
export var flex_1 = "Sidebar-module--flex_1--d60eb";
export var flex_center = "Sidebar-module--flex_center--9d3bd";
export var flex_center_col = "Sidebar-module--flex_center_col--9309b";
export var flex_col = "Sidebar-module--flex_col--ad466";
export var flex_column = "Sidebar-module--flex_column--94c42";
export var flex_fix_center_col = "Sidebar-module--flex_fix_center_col--74e60";
export var flex_fixed_center_col = "Sidebar-module--flex_fixed_center_col--c752c";
export var flex_row = "Sidebar-module--flex_row--f54c0";
export var imageAnimationHolder = "Sidebar-module--image-animation-holder--95331";
export var linkAnimation = "Sidebar-module--link-animation--fc247";
export var mainLogo = "Sidebar-module--main-logo--a586d";
export var rotating = "Sidebar-module--rotating--7f037";
export var sidebar = "Sidebar-module--sidebar--43ae5";
export var sidebarActive = "Sidebar-module--sidebar-active--d7e27";
export var sidebarContent = "Sidebar-module--sidebar-content--aa32c";
export var sidebarContentHolder = "Sidebar-module--sidebar-content-holder--3287b";
export var sidebarHeader = "Sidebar-module--sidebar-header--492db";
export var swiperWrapper = "Sidebar-module--swiper-wrapper--efd08";