import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "gatsby";
import { motion, useAnimation } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";

import { IPortal } from "~models";
import { Container, Row, Col, Label, Button, VideoElement } from "~components";
import { UIStore } from "~stores";
import {
  imageAnimationVariants,
  imageHolderAnimationVariants,
} from "~libs/framer/variants";

import * as style from "./Portal.module.scss";

interface Props {
  section: IPortal;
  uiStore?: UIStore;
}

export const Portal = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { sectionTitle, partner, tag, text, imageOrVideoGroup, linkButton } = section;

    const portalSection = useRef(null);
    const [switcher, setSwitcher] = useState(true);

    const listenScroll = () => {
      if (portalSection.current.getBoundingClientRect().top < 30) {
        if (switcher) {
          uiStore.setHeaderTheme("dark");
          setSwitcher(false);
        }
      } else {
        setSwitcher(true);
      }
    };

    useEffect(() => {
      window.addEventListener("scroll", listenScroll);
      return () => window.removeEventListener("scroll", listenScroll);
    }, [switcher]);

    const imageAnimation = useAnimation();

    return (
      <section data-sectionname="Portal" ref={portalSection}>
        <Container fluid>
          <Row className={style.exceptionRow}>
            <Col>
              <h4 dangerouslySetInnerHTML={{ __html: sectionTitle }}></h4>
            </Col>
          </Row>
          <Row className={style.portalRow}>
            <Col md={6}>
              <div className={style.content}>
                <h4>
                  See next case <br />
                  in category
                </h4>
                <aside>
                  <div>
                    <h5>{partner.toUpperCase()}</h5>
                    <div className={style.tagHolder}>
                      {tag.length &&
                        tag.map((e, i) => (
                          <Label text={e.tagFields.tagName} key={i}></Label>
                        ))}
                    </div>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                  <Link to={linkButton.buttonLink?.url}>
                    <Button dark={true} icon={"icon-arrow-right"}>
                      {linkButton.buttonText}
                    </Button>
                  </Link>
                </aside>
              </div>
            </Col>
            <Col md={5} className={"offset-md-1"}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                animate={imageAnimation}
                variants={imageHolderAnimationVariants}
                className={style.imageAnimationHolder}
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  animate={imageAnimation}
                  variants={imageAnimationVariants}
                >
                  {imageOrVideoGroup.imageOrVideoSelect === "image" && (
                    <div className={style.imageHolder}>
                      <GatsbyImage
                        className={`${style.imageHolder} ${
                          style.imageHolder === "right"
                            ? style.alignRight
                            : imageOrVideoGroup.imageAlign === "left"
                            ? style.alignLeft
                            : style.alignCenter
                        }`}
                        alt={imageOrVideoGroup.image?.caption}
                        image={
                          imageOrVideoGroup.image.localFile.childImageSharp
                            .gatsbyImageData
                        }
                      />
                      <Link to={linkButton.buttonLink?.url}>
                        <motion.div
                          onHoverStart={() => imageAnimation.start("zoom")}
                          onHoverEnd={() => imageAnimation.start("visible")}
                        />
                      </Link>
                    </div>
                  )}
                  {imageOrVideoGroup.imageOrVideoSelect === "video" && (
                    <div
                      className={`${style.imageHolder} ${
                        style.imageHolder === "right"
                          ? style.alignRight
                          : imageOrVideoGroup.imageAlign === "left"
                          ? style.alignLeft
                          : style.alignCenter
                      }`}
                    >
                      <VideoElement
                        url={
                          imageOrVideoGroup.type === "vimeo"
                            ? imageOrVideoGroup.videoUrl
                            : imageOrVideoGroup.videoFile.mediaItemUrl
                        }
                        poster={imageOrVideoGroup.videoPoster}
                        controls={imageOrVideoGroup.videoSettings.controls}
                        loop={imageOrVideoGroup.videoSettings.loop}
                        freeAspectRatio={true}
                      />
                      <Link to={linkButton.buttonLink?.url}>
                        <motion.div
                          onHoverStart={() => imageAnimation.start("zoom")}
                          onHoverEnd={() => imageAnimation.start("visible")}
                        />
                      </Link>
                    </div>
                  )}
                </motion.div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  })
);
