// extracted by mini-css-extract-plugin
export var arrow = "WhitePaperForm-module--arrow--ffbed";
export var arrow_no = "WhitePaperForm-module--arrow_no--09ad9";
export var arrow_yes = "WhitePaperForm-module--arrow_yes--64506";
export var buttonHolder = "WhitePaperForm-module--button-holder--689f4";
export var checkbox = "WhitePaperForm-module--checkbox--855cb";
export var checkboxHolder = "WhitePaperForm-module--checkbox-holder--ea690";
export var flex = "WhitePaperForm-module--flex--7ed74";
export var flex_1 = "WhitePaperForm-module--flex_1--c12c3";
export var flex_center = "WhitePaperForm-module--flex_center--4a4a6";
export var flex_center_col = "WhitePaperForm-module--flex_center_col--c3b57";
export var flex_col = "WhitePaperForm-module--flex_col--47d4a";
export var flex_column = "WhitePaperForm-module--flex_column--a330e";
export var flex_fix_center_col = "WhitePaperForm-module--flex_fix_center_col--2bba3";
export var flex_fixed_center_col = "WhitePaperForm-module--flex_fixed_center_col--0b509";
export var flex_row = "WhitePaperForm-module--flex_row--a5392";
export var formItems = "WhitePaperForm-module--form-items--1a7dc";
export var imageAnimationHolder = "WhitePaperForm-module--image-animation-holder--5f7ed";
export var innerHolder = "WhitePaperForm-module--inner-holder--fab6e";
export var linkAnimation = "WhitePaperForm-module--link-animation--5e754";
export var required = "WhitePaperForm-module--required--3e3f1";
export var rotating = "WhitePaperForm-module--rotating--e9c0d";
export var shake = "WhitePaperForm-module--shake--3cda7";
export var swiperWrapper = "WhitePaperForm-module--swiper-wrapper--2dda4";
export var title = "WhitePaperForm-module--title--6e245";
export var visible = "WhitePaperForm-module--visible--5e87c";
export var whitePaperForm = "WhitePaperForm-module--white-paper-form--c6a9e";