// extracted by mini-css-extract-plugin
export var arrow = "ProjectScope-module--arrow--444e5";
export var arrow_no = "ProjectScope-module--arrow_no--91f27";
export var arrow_yes = "ProjectScope-module--arrow_yes--2a371";
export var flex = "ProjectScope-module--flex--d7881";
export var flex_1 = "ProjectScope-module--flex_1--8e16a";
export var flex_center = "ProjectScope-module--flex_center--6bc96";
export var flex_center_col = "ProjectScope-module--flex_center_col--4c208";
export var flex_col = "ProjectScope-module--flex_col--37d57";
export var flex_column = "ProjectScope-module--flex_column--27ce9";
export var flex_fix_center_col = "ProjectScope-module--flex_fix_center_col--75d7d";
export var flex_fixed_center_col = "ProjectScope-module--flex_fixed_center_col--1f200";
export var flex_row = "ProjectScope-module--flex_row--eee86";
export var generalTitle = "ProjectScope-module--general-title--f2b56";
export var imageAnimationHolder = "ProjectScope-module--image-animation-holder--bc1d2";
export var linkAnimation = "ProjectScope-module--link-animation--9b14b";
export var rotating = "ProjectScope-module--rotating--a4de3";
export var swiperWrapper = "ProjectScope-module--swiper-wrapper--f2009";
export var title = "ProjectScope-module--title--733a4";
export var titleAndText = "ProjectScope-module--title-and-text--1a9e0";