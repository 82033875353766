// extracted by mini-css-extract-plugin
export var absolute = "TextAndImageScatter-module--absolute--050ca";
export var arrow = "TextAndImageScatter-module--arrow--3aa34";
export var arrow_no = "TextAndImageScatter-module--arrow_no--e9034";
export var arrow_yes = "TextAndImageScatter-module--arrow_yes--4bf7f";
export var centered = "TextAndImageScatter-module--centered--6c03e";
export var content = "TextAndImageScatter-module--content--bf288";
export var flex = "TextAndImageScatter-module--flex--bfec3";
export var flex_1 = "TextAndImageScatter-module--flex_1--40794";
export var flex_center = "TextAndImageScatter-module--flex_center--7f018";
export var flex_center_col = "TextAndImageScatter-module--flex_center_col--645fe";
export var flex_col = "TextAndImageScatter-module--flex_col--be83a";
export var flex_column = "TextAndImageScatter-module--flex_column--0191e";
export var flex_fix_center_col = "TextAndImageScatter-module--flex_fix_center_col--ac10b";
export var flex_fixed_center_col = "TextAndImageScatter-module--flex_fixed_center_col--61ac0";
export var flex_row = "TextAndImageScatter-module--flex_row--0016c";
export var imageAnimationHolder = "TextAndImageScatter-module--image-animation-holder--42c12";
export var imageHolder = "TextAndImageScatter-module--image-holder--1afc6";
export var linkAnimation = "TextAndImageScatter-module--link-animation--09d23";
export var main = "TextAndImageScatter-module--main--77d36";
export var noContent = "TextAndImageScatter-module--no-content--7d8a0";
export var rotating = "TextAndImageScatter-module--rotating--da98d";
export var swiperWrapper = "TextAndImageScatter-module--swiper-wrapper--a0eb9";
export var title = "TextAndImageScatter-module--title--6bc56";
export var titleHolder = "TextAndImageScatter-module--title-holder--044c2";
export var withContent = "TextAndImageScatter-module--with-content--6d5a2";