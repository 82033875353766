import React, { Component } from "react";
import { graphql } from "gatsby";
import { ILocation, ISingleWordpressLatest } from "~models";
import { withStore } from "~libs/mobx";
import { LatestTemplate } from "~views/templates";
import "~libs/graphql";
import { inject, observer } from "mobx-react";
import { Seo } from "~components";
import { Header, Footer } from "~views/layouts";
import { NavigationStore, UIStore, ContextStore } from "~stores";

interface Props {
  latestContext: any;
  location?: ILocation;
  data: {
    wpLatest: ISingleWordpressLatest;
  };
  contextStore: ContextStore;
  navigationStore?: NavigationStore;
  uiStore?: UIStore;
}

@inject("contextStore", "navigationStore", "uiStore")
@observer
class LatestPage extends Component<Props> {
  constructor(props) {
    super(props);
    this.props.uiStore.setBackgroundColor("#ffffff");
    this.props.uiStore.setContentColor("#000000");
  }

  componentDidMount() {
    const { latestContext, contextStore, navigationStore, uiStore } = this.props;

    const { initNavigationStore } = navigationStore;
    contextStore.context = latestContext;
    initNavigationStore(location);

    uiStore.setDefaultHeaderTheme("dark");
    uiStore.setHeaderTheme("dark");

    setTimeout(() => {
      if (typeof window !== "undefined") {
        window.scrollTo(window.scrollX, window.scrollY + 1);
        window.document.body.classList.remove("overflow_hidden");
      }
    });
  }

  renderTemplate() {
    const { sections } = this.props.data.wpLatest.latestFields;
    const settings = this.props.data.wpLatest.latestFields.latestSettings;

    return (
      <LatestTemplate
        bgColor={settings.backgroundColor}
        template={"page_builder"}
        sections={[...sections]}
        settings={settings}
      />
    );
  }

  render() {
    const { data, location } = this.props;
    const { wpLatest } = data;
    const {
      title,
      seo,
      latestFields: {
        latestSettings: {},
      },
    } = wpLatest;
    const { origin } = location;

    return (
      <>
        <Seo seo={seo} pageTitle={title} origin={origin} />
        <Header />
        {this.renderTemplate()}
        <Footer showBottomRowInFooter={true} theme={"dark"} />
      </>
    );
  }
}

export default withStore(LatestPage);

export const pageQuery = graphql`
  query ($id: String!) {
    wpLatest(id: { eq: $id }) {
      ...singleWpLatestFragment
    }
  }
`;
