import { action, observable } from "mobx";
import { Store } from "./Store";
import { resetBodyScroll, disableBodyScrollAndTranslate } from "~libs/utils";

export class FilterStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  @observable
  selectedTags: string[] = ["test"];

  @action
  setSelectedTags = (tags) => {
    this.selectedTags = tags;
  };

  //   {this.props.filterStore.selectedTags.map((tag)=>tag)}
}
