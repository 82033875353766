import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { IPartnerLogos } from "~models";
import { Col, Container, Row } from "~components";
import { presenceAnimate } from "~libs/framer/variants";

import * as style from "./PartnerLogos.module.scss";

interface Props {
  section: IPartnerLogos;
}

export const PartnerLogos = ({ section }: Props) => {
  const { logos } = section;
  const [visibleLogos, setVisibleLogos] = useState([]);
  const [counter, setCounter] = useState(1);
  const [hideHolder, setHideHolder] = useState(false);

  const calculateVisibleLogos = (c) => {
    setVisibleLogos([]);
    setTimeout(() => {
      setHideHolder(true);
      setVisibleLogos([...logos[Number(c) - 1].logosRow]);
      setHideHolder(false);
    }, 520);
  };

  const calculateCounter = () => {
    if (logos.length === 1) {
      setCounter(1);
    } else {
      setCounter(counter + 1 > logos.length ? 1 : counter + 1);
    }
  };

  useEffect(() => {
    setCounter(logos.length);
  }, []);

  useEffect(() => {
    calculateVisibleLogos(counter);
    const logoChanger = setInterval(() => calculateCounter(), 2000);
    return () => clearInterval(logoChanger);
  }, [counter]);

  return (
    <section data-sectionname="PartnerLogos">
      <Container>
        <Row>
          <Col md={10} xl={8} className={"offset-md-1 offset-xl-2"}>
            {!hideHolder && (
              <div className={style.logoHolder}>
                <AnimatePresence>
                  {visibleLogos.length &&
                    visibleLogos.map((logo, index) => (
                      <motion.div
                        initial={presenceAnimate.initial}
                        animate={presenceAnimate.animate}
                        exit={presenceAnimate.exit}
                        transition={presenceAnimate.transition(index)}
                        key={index}
                        className={style.logo}
                      >
                        <img
                          src={logo.mediaItemUrl}
                          className={style.bgImage}
                          style={{
                            width: "100%",
                          }}
                          alt={logo?.caption || ""}
                        ></img>
                      </motion.div>
                    ))}
                </AnimatePresence>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
