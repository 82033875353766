import React from "react";
import { motion } from "framer-motion";
import { inject, observer } from "mobx-react";
import { Link } from "gatsby";

import { Container, Row, Col, Divider } from "~components";
import { UIStore } from "~stores";
import { IThreeColumnContent } from "~models";

import * as style from "./ThreeColumnContent.module.scss";

interface Props {
  section: IThreeColumnContent;
  uiStore?: UIStore;
}

export const ThreeColumnContent = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { column, showDivider, transition } = section;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="OurInitiatives"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
      >
        <Container fluid>
          <Row>
            <Col>
              <div className={style.gridHolder}>
                {column.map((c, i) => (
                  <motion.div className={style.column} key={i}>
                    <h3>{c.title}</h3>
                    <p>{c.content}</p>
                    {c.link && c.link.url.length && (
                      <Link
                        to={c.link.url}
                        target={c.link.target}
                        className={style.linkButton}
                      >
                        <span>{c.link.title}</span>
                        <i className={"icon-arrow-right"}></i>
                      </Link>
                    )}
                  </motion.div>
                ))}
              </div>
              {showDivider && <Divider dark={false} />}
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
