import React from "react";
import { inject, observer } from "mobx-react";
import { motion } from "framer-motion";

import { Container, Row, Col } from "~components";
import { IPartnerCitation } from "~models";
import { UIStore } from "~stores";
import { staggerChildren, staggerChildrenItem } from "~libs/framer/variants";

import * as style from "./PartnerCitation.module.scss";

export interface Props {
  section: IPartnerCitation;
  uiStore?: UIStore;
}

export const PartnerCitation = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { partnerCitationText, partnerName, partnerTitleAndCompany, transition } =
      section;

    const { contentColor } = uiStore;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <>
        <motion.section
          data-sectionname="PartnerCitation"
          onViewportEnter={() => changeBackgroundColor()}
          viewport={{ margin: "-50% -50%" }}
        >
          <Container>
            <Row>
              <Col md={10} className={"offset-md-1"}>
                <motion.blockquote
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={staggerChildren}
                  className={style.citation}
                >
                  <motion.h4
                    variants={staggerChildrenItem}
                    dangerouslySetInnerHTML={{ __html: partnerCitationText }}
                    style={{ color: contentColor }}
                  ></motion.h4>
                  <motion.cite
                    variants={staggerChildrenItem}
                    dangerouslySetInnerHTML={{ __html: partnerName }}
                    style={{ color: contentColor }}
                  ></motion.cite>
                  <motion.p
                    variants={staggerChildrenItem}
                    dangerouslySetInnerHTML={{ __html: partnerTitleAndCompany }}
                    style={{ color: contentColor }}
                  ></motion.p>
                </motion.blockquote>
              </Col>
            </Row>
          </Container>
        </motion.section>
      </>
    );
  })
);
