import { observable, computed } from "mobx";
import { Store } from "./Store";
import { ILocation, ILanguage } from "~models";
import { getCurrentLanguage } from "~libs/i18n";

export class NavigationStore {
  headerMenuKey = "HEADER_MENU";
  footerMenuKey = "FOOTER_MENU";

  @observable
  location: ILocation = {
    hash: "",
    host: "",
    hostname: "",
    href: "",
    key: "",
    origin: "",
    pathname: "",
    port: "",
    protocol: "",
    search: "",
    state: null,
  };

  initNavigationStore = (location: ILocation) => {
    Object.keys(location).forEach((key) => {
      if (this.location.hasOwnProperty(key)) this.location[key] = location[key];
    });
  };

  @computed
  get headerMenu() {
    let language = getCurrentLanguage();
    return this.store.contextStore.menu?.find(
      (menu) =>
        menu.location === `${this.headerMenuKey}${language === "en" ? "" : "___SV"}`
    );
  }

  @computed
  get footerMenu() {
    let language = getCurrentLanguage();
    return this.store.contextStore.menu?.find(
      (menu) =>
        menu.location === `${this.footerMenuKey}${language === "en" ? "" : "___SV"}`
    );
  }

  @observable
  languages: ILanguage[];

  @observable
  defaultLanguage: ILanguage;

  constructor(private store: Store) {}
}
