import React from "react";
import { inject, observer } from "mobx-react";
import { motion } from "framer-motion";

import { ISustainabilityContent } from "~models";
import { Container, Row, Col } from "~components";
import { staggerChildren, staggerChildrenItem } from "~libs/framer/variants";
import { UIStore } from "~stores";

import * as style from "./SustainabilityContent.module.scss";

interface Props {
  section: ISustainabilityContent;
  uiStore?: UIStore;
}

export const SustainabilityContent = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const {
      sectionTitle,
      leftText,
      rightTextSecondaryFont,
      rightText,
      transition,
      isWide,
      isDark,
    } = section;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="SustainabilityContent"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
        className={`${isDark ? style.dark : ""}`}
      >
        <Container fluid>
          <Row>
            <Col>
              <hr className={`${style.hr} ${style.hrException}`} />
            </Col>
          </Row>
        </Container>
        <Container fluid={isDark}>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={staggerChildren}
          >
            {sectionTitle && (
              <Row>
                <Col>
                  <motion.h1
                    variants={staggerChildrenItem}
                    className={style.title}
                    dangerouslySetInnerHTML={{ __html: sectionTitle }}
                  ></motion.h1>
                </Col>
              </Row>
            )}
            <Row className={`${style.contentRow} ${isWide ? style.wider : ""}`}>
              <motion.div
                dangerouslySetInnerHTML={{ __html: leftText }}
                variants={staggerChildrenItem}
              ></motion.div>
              <motion.div
                className={`${
                  rightTextSecondaryFont ? style.contentRowSecondaryFont : ""
                }`}
                dangerouslySetInnerHTML={{ __html: rightText }}
                variants={staggerChildrenItem}
              ></motion.div>
            </Row>
          </motion.div>
        </Container>
        <Container fluid>
          <Row>
            <Col>
              <hr className={style.hr} />
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
