// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--d68e8";
export var arrow_no = "styles-module--arrow_no--3e892";
export var arrow_yes = "styles-module--arrow_yes--b6ee1";
export var centered = "styles-module--centered--661cf";
export var flex = "styles-module--flex--da03c";
export var flex_1 = "styles-module--flex_1--a79f3";
export var flex_center = "styles-module--flex_center--b707c";
export var flex_center_col = "styles-module--flex_center_col--7eb26";
export var flex_col = "styles-module--flex_col--2abe8";
export var flex_column = "styles-module--flex_column--fa825";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--641b3";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--258a8";
export var flex_row = "styles-module--flex_row--cf5e5";
export var imageAnimationHolder = "styles-module--image-animation-holder--349fb";
export var linkAnimation = "styles-module--link-animation--1f3c6";
export var rotating = "styles-module--rotating--88e0a";
export var swiperWrapper = "styles-module--swiper-wrapper--22e69";