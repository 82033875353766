import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { allLatestPosts } from "~models/PostTypes";
import { getCurrentLanguage } from "~libs/i18n";

export const withAllLatests = <T extends allLatestPosts>(
  Comp: ({ section, news, insights }) => JSX.Element
) => {
  return (props) => {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWpLatest {
              nodes {
                id
                uri
                slug
                title
                language {
                  code
                  slug
                }
                latestFields {
                  latestSettings {
                    type
                    backgroundColor
                    title
                    description
                    date
                    season
                    universalTag {
                      ... on WpFilter_tag {
                        id
                        tagFields {
                          tagName
                        }
                      }
                    }
                    imageOrVideoGroup {
                      imageOrVideoSelect
                      type
                      image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              width: 1500
                              quality: 80
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                      }
                      videoUrl
                      videoFile {
                        mediaItemUrl
                      }

                      videoPoster {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              width: 1500
                              quality: 80
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                      }
                      videoSettings {
                        controls
                        loop
                      }
                    }
                    isFeatured
                    hasLinkedDetailPage
                    featuredVideo {
                      videoUrl
                      videoPoster {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              width: 1500
                              quality: 80
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                      }
                    }
                  }
                  sections {
                    ... on WpLatest_Latestfields_Sections_ContentBody {
                      highlightedContent
                      content
                      titleAndTextList {
                        title
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data: T) => {
          const filterByLanguage = data.allWpLatest.nodes.filter(
            (e) => e.language.slug === getCurrentLanguage()
          );

          const sortedNews = filterByLanguage.sort((a, b) => {
            const aDate = new Date(a.latestFields.latestSettings.date).getTime();
            const bDate = new Date(b.latestFields.latestSettings.date).getTime();

            if (aDate < bDate) return 1;
            if (aDate > bDate) return -1;
            return 0;
          });

          return <Comp {...(props as any)} news={sortedNews} />;
        }}
      />
    );
  };
};
