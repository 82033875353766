import React from "react";
import { motion } from "framer-motion";

import { ITextContent } from "~models";
import { Container, Row, Col } from "~components";
import { fadeInItem } from "~libs/framer/variants";

import * as style from "./TextContent.module.scss";

interface Props {
  section: ITextContent;
}

export const TextContent = ({ section }: Props) => {
  const { sectionText, isBigger } = section;

  return (
    <section
      data-sectionname="TextContent"
      className={`${isBigger ? style.noPadding : ""}`}
    >
      <Container>
        <Row>
          <Col
            xl={isBigger ? 10 : 8}
            md={10}
            sm={12}
            className={`${isBigger ? "offset-xl-1" : "offset-xl-2"} offset-md-1`}
          >
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeInItem}
              className={`${style.sectionText} body-large ${
                isBigger ? style.isBigger : ""
              }`}
              dangerouslySetInnerHTML={{ __html: sectionText }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
