// extracted by mini-css-extract-plugin
export var arrow = "CompanyStatistics-module--arrow--a1f49";
export var arrow_no = "CompanyStatistics-module--arrow_no--6db7e";
export var arrow_yes = "CompanyStatistics-module--arrow_yes--3600a";
export var boxRow = "CompanyStatistics-module--box-row--2c381";
export var flex = "CompanyStatistics-module--flex--00654";
export var flex_1 = "CompanyStatistics-module--flex_1--b4f08";
export var flex_center = "CompanyStatistics-module--flex_center--e7e04";
export var flex_center_col = "CompanyStatistics-module--flex_center_col--d40aa";
export var flex_col = "CompanyStatistics-module--flex_col--9d526";
export var flex_column = "CompanyStatistics-module--flex_column--2f0a3";
export var flex_fix_center_col = "CompanyStatistics-module--flex_fix_center_col--e779d";
export var flex_fixed_center_col = "CompanyStatistics-module--flex_fixed_center_col--dc31d";
export var flex_row = "CompanyStatistics-module--flex_row--85720";
export var freeWidth = "CompanyStatistics-module--free-width--88d85";
export var imageAnimationHolder = "CompanyStatistics-module--image-animation-holder--70d5f";
export var linkAnimation = "CompanyStatistics-module--link-animation--64408";
export var rotating = "CompanyStatistics-module--rotating--6a404";
export var statisticBox = "CompanyStatistics-module--statistic-box--27eab";
export var swiperWrapper = "CompanyStatistics-module--swiper-wrapper--32a79";
export var withAnimation = "CompanyStatistics-module--with-animation--8fe4d";