import React, { useState } from "react";
import { motion } from "framer-motion";

import { IServices } from "~models";
import { Container, Row, Col, Divider } from "~components";
import { fadeInItem, staggerChildren, staggerChildrenItem } from "~libs/framer/variants";

import * as style from "./Services.module.scss";
import { tx } from "~libs/i18n";

interface ServiceListProps {
  title: string;
  text: string;
}

export const ServiceList = ({ title, text }: ServiceListProps) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={staggerChildren}
      className={style.serviceList}
    >
      <motion.p
        variants={staggerChildrenItem}
        className={`${style.title} body-large`}
        dangerouslySetInnerHTML={{ __html: title }}
      ></motion.p>
      <motion.p
        variants={staggerChildrenItem}
        dangerouslySetInnerHTML={{ __html: text }}
      ></motion.p>
    </motion.div>
  );
};

interface AccordionItemProps {
  title: string;
  subtitle: string;
  content: string;
  list: [
    {
      service: string;
    }
  ];
}

const AccordionItem = ({ title, subtitle, content, list }: AccordionItemProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <motion.div className={style.accordionItem} variants={staggerChildrenItem}>
      <div
        className={style.accordionHeading}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <div>
          <h3>{title}</h3>
          {subtitle && <p>{subtitle}</p>}
        </div>
        <span>
          {isOpen ? tx("services.close") : tx("services.readMore")}
          <i>{isOpen ? "-" : "+"}</i>
        </span>
      </div>
      <div className={`${style.accordionContent} ${isOpen ? style.open : ""}`}>
        {content && <p>{content}</p>}
        {list && (
          <ul>
            {list.map((e, i) => (
              <li key={i}>{e.service}</li>
            ))}
          </ul>
        )}
      </div>
    </motion.div>
  );
};

interface Props {
  section: IServices;
}

export const Services = ({ section }: Props) => {
  const { title, service, theme, withDividerBelow, withPaddingInBottom } = section;

  return (
    <section
      data-sectionname="Services"
      className={`${theme === "light" ? style.light : ""}`}
    >
      <Container fluid>
        <Row>
          <Col md={4}>
            <motion.h2
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeInItem}
              className={`${style.sectionTitle} body-large`}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Col>
          <Col md={8}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={staggerChildren}
            >
              {service.map((s, i) => (
                <AccordionItem
                  title={s.serviceGroup.serviceTitle}
                  subtitle={s.serviceGroup.serviceSubtitle}
                  content={s.serviceGroup.serviceText}
                  list={s.serviceGroup.serviceList}
                  key={i}
                />
              ))}
            </motion.div>
          </Col>
        </Row>
        {(withDividerBelow || withPaddingInBottom) && (
          <Row>
            <Col>
              {withPaddingInBottom && <div className={style.whiteSpace} />}
              {withDividerBelow && <Divider dark={true} />}
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};
