// extracted by mini-css-extract-plugin
export var arrow = "CaseHeader-module--arrow--eb9aa";
export var arrow_no = "CaseHeader-module--arrow_no--e044e";
export var arrow_yes = "CaseHeader-module--arrow_yes--1e0f0";
export var flex = "CaseHeader-module--flex--f86c6";
export var flex_1 = "CaseHeader-module--flex_1--812ef";
export var flex_center = "CaseHeader-module--flex_center--1ae25";
export var flex_center_col = "CaseHeader-module--flex_center_col--35bce";
export var flex_col = "CaseHeader-module--flex_col--0e5f3";
export var flex_column = "CaseHeader-module--flex_column--7499e";
export var flex_fix_center_col = "CaseHeader-module--flex_fix_center_col--87de0";
export var flex_fixed_center_col = "CaseHeader-module--flex_fixed_center_col--88264";
export var flex_row = "CaseHeader-module--flex_row--c0044";
export var imageAnimationHolder = "CaseHeader-module--image-animation-holder--bc52e";
export var linkAnimation = "CaseHeader-module--link-animation--7b418";
export var rotating = "CaseHeader-module--rotating--aa607";
export var swiperWrapper = "CaseHeader-module--swiper-wrapper--d2081";
export var tagHolder = "CaseHeader-module--tag-holder--766a1";
export var titleHolder = "CaseHeader-module--title-holder--63ee8";