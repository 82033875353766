import React from "react";
import { motion } from "framer-motion";
import { inject, observer } from "mobx-react";

import { Container, Row, Col } from "~components";
import { IIntroductiveText } from "~models";
import { UIStore } from "~stores";
import { fadeInItem } from "~libs/framer/variants";

import * as style from "./IntroductiveText.module.scss";

interface Props {
  section: IIntroductiveText;
  uiStore?: UIStore;
}

export const IntroductiveText = inject("uiStore")(
  observer(({ section, uiStore }: Props) => {
    const { text, transition } = section;
    const { contentColor } = uiStore;

    const changeBackgroundColor = () => {
      uiStore.setBackgroundColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionBackgroundColor
          : uiStore.defaultBackgroundColor
      );
      uiStore.setContentColor(
        transition && transition.transitionAnimation === "transition"
          ? transition.transitionTextColor
          : uiStore.defaultContentColor
      );
    };

    return (
      <motion.section
        data-sectionname="IntroductiveText"
        onViewportEnter={() => changeBackgroundColor()}
        viewport={{ margin: "-50% -50%" }}
      >
        <Container>
          <Row>
            <Col md={10} xl={8} className={"offset-md-1 offset-xl-2"}>
              <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInItem}
                custom={0}
                className={`${style.content} body-large`}
                dangerouslySetInnerHTML={{ __html: text }}
                style={{ color: contentColor }}
              ></motion.p>
            </Col>
          </Row>
        </Container>
      </motion.section>
    );
  })
);
