import React from "react";

import * as styles from "./styles.module.scss";

export interface MasonryProps {
  columns: number;
  gap: number;
  margin: number;
  children: React.ReactNode[];
}

export const Masonry = ({ columns, children, gap, margin }) => {
  const columnWrapper = {};
  const result = [];

  for (let i = 0; i < columns; i++) {
    columnWrapper[`column${i}`] = [];
  }

  for (let i = 0; i < children.length; i++) {
    const columnIndex = i % columns;
    columnWrapper[`column${columnIndex}`].push(
      <div
        key={i}
        style={{
          marginBottom: margin,
        }}
      >
        {children[i]}
      </div>
    );
  }

  for (let i = 0; i < columns; i++) {
    result.push(
      <div
        key={i}
        style={{
          marginLeft: `${i > 0 ? gap : 0}px`,
          flex: 1,
        }}
      >
        {columnWrapper[`column${i}`]}
      </div>
    );
  }

  return <div className={styles.masonry}>{result}</div>;
};
