// extracted by mini-css-extract-plugin
export var arrow = "CustomCursor-module--arrow--44ddf";
export var arrow_no = "CustomCursor-module--arrow_no--ac181";
export var arrow_yes = "CustomCursor-module--arrow_yes--9cafb";
export var customCursor = "CustomCursor-module--custom-cursor--625fb";
export var flex = "CustomCursor-module--flex--9a05c";
export var flex_1 = "CustomCursor-module--flex_1--6b75e";
export var flex_center = "CustomCursor-module--flex_center--93387";
export var flex_center_col = "CustomCursor-module--flex_center_col--2dbc6";
export var flex_col = "CustomCursor-module--flex_col--1127c";
export var flex_column = "CustomCursor-module--flex_column--c6293";
export var flex_fix_center_col = "CustomCursor-module--flex_fix_center_col--0e431";
export var flex_fixed_center_col = "CustomCursor-module--flex_fixed_center_col--e6c58";
export var flex_row = "CustomCursor-module--flex_row--f55f8";
export var imageAnimationHolder = "CustomCursor-module--image-animation-holder--3d94d";
export var linkAnimation = "CustomCursor-module--link-animation--eb5c6";
export var rotating = "CustomCursor-module--rotating--429cb";
export var swiperWrapper = "CustomCursor-module--swiper-wrapper--6f4d3";
export var text = "CustomCursor-module--text--c0d69";