import React, { useState, useEffect } from "react";

import { Button } from "~components/Button";
import { Container, Row, Col } from "~views/shared/components";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "gatsby";

import * as style from "./styles.module.scss";

export const CookieBanner = () => {
  const keyName: string = "essen_cookie_accepted";
  const [showModal, setShowModal] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const acceptCookies = () => {
    window.localStorage.setItem(keyName, "true");
    setAccepted(true);
  };

  const rejectCookies = () => {
    window.sessionStorage.setItem(keyName, "true");
    setAccepted(true);
  };

  useEffect(() => {
    if (window.localStorage && window.sessionStorage) {
      let cookieStatus = window.localStorage.getItem(keyName);
      let sessionCookieStatus = window.sessionStorage.getItem(keyName);
      if (JSON.parse(cookieStatus) || JSON.parse(sessionCookieStatus)) {
        setShowModal(false);
        setAccepted(true);
      } else {
        setShowModal(true);
        setAccepted(false);
      }
    }
  }, []);

  return (
    <div>
      <AnimatePresence>
        {!accepted && showModal && (
          <motion.div
            className={style.cookieBanner}
            initial={{ y: 300 }}
            animate={{
              y: 0,
              transition: { duration: 1 },
            }}
            exit={{ y: 300, transition: { duration: 1 } }}
          >
            <Container>
              <Row>
                <Col>
                  <div className={style.cookieBannerInner}>
                    <div>
                      <p>
                        This website uses cookies.{" "}
                        <span>
                          READ MORE ABOUT OUR{" "}
                          <Link className={style.cookieBannerLink} to="/privacy-policy">
                            <span>PRIVACY POLICY</span>
                          </Link>{" "}
                          &amp;{" "}
                          <Link className={style.cookieBannerLink} to="/cookie-policy">
                            <span>COOKIE POLICY</span>
                          </Link>
                        </span>
                      </p>
                    </div>
                    <div className={style.buttonHolder}>
                      <Button
                        onClick={() => rejectCookies()}
                        small={true}
                        tertiary={true}
                      >
                        OPT OUT
                      </Button>
                      <Button
                        secondary={true}
                        dark={false}
                        filled={false}
                        onClick={() => acceptCookies()}
                      >
                        ACCEPT &amp; CLOSE
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
