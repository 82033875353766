// extracted by mini-css-extract-plugin
export var arrow = "CustomHeader-module--arrow--a59af";
export var arrowLink = "CustomHeader-module--arrow-link--014c9";
export var arrow_no = "CustomHeader-module--arrow_no--f0913";
export var arrow_yes = "CustomHeader-module--arrow_yes--d70b0";
export var backToTop = "CustomHeader-module--backToTop--1d11c";
export var customButton = "CustomHeader-module--custom-button--18149";
export var customHeader = "CustomHeader-module--custom-header--ed0a4";
export var customHeaderInner = "CustomHeader-module--custom-header-inner--b65b3";
export var flex = "CustomHeader-module--flex--6f2a8";
export var flex_1 = "CustomHeader-module--flex_1--3bf61";
export var flex_center = "CustomHeader-module--flex_center--82eda";
export var flex_center_col = "CustomHeader-module--flex_center_col--446f9";
export var flex_col = "CustomHeader-module--flex_col--bb28a";
export var flex_column = "CustomHeader-module--flex_column--5f80d";
export var flex_fix_center_col = "CustomHeader-module--flex_fix_center_col--c7665";
export var flex_fixed_center_col = "CustomHeader-module--flex_fixed_center_col--6188e";
export var flex_row = "CustomHeader-module--flex_row--e58f5";
export var hidden = "CustomHeader-module--hidden--f697b";
export var imageAnimationHolder = "CustomHeader-module--image-animation-holder--a0e69";
export var linkAnimation = "CustomHeader-module--link-animation--4dcc7";
export var logo = "CustomHeader-module--logo--a40f2";
export var mobileButton = "CustomHeader-module--mobile-button--c2cab";
export var rotating = "CustomHeader-module--rotating--71fd8";
export var scrollToTop = "CustomHeader-module--scroll-to-top--b5b47";
export var swiperWrapper = "CustomHeader-module--swiper-wrapper--b0b78";
export var textWrapper = "CustomHeader-module--textWrapper--d0b86";