const primaryCurve = [0.11, 0, 0.01, 1];
const smoothCurve = [0.22, 0, 0.18, 1];

// ANIMATION REFACTOR

export const staggerChildren = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
      ease: primaryCurve,
    },
  },
};

export const staggerChildrenItem = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: primaryCurve,
    },
  },
};

export const fadeInItem = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      delay: custom ?? 0,
      ease: primaryCurve,
    },
  }),
};

export const imageHolderAnimationVariants = {
  hidden: {
    y: "20%",
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: smoothCurve,
    },
  },
};

export const imageAnimationVariants = {
  hidden: {
    y: "-20%",
  },
  visible: {
    scale: 1,
    y: 0,
    transition: {
      duration: 1,
      ease: smoothCurve,
    },
  },
  zoom: {
    // scale: 1.05,
    transition: {
      duration: 1,
      ease: smoothCurve,
    },
  },
};

export const horizontalTitleHolderAnimationVariants = {
  visible: {
    transition: {
      staggerChildren: 0.2,
      ease: primaryCurve,
    },
  },
};

export const horizontalTitleAnimationVariants = {
  hidden: (custom) => ({
    opacity: 0,
    x: custom % 2 === 0 ? 50 : -50,
  }),
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.6,
      ease: primaryCurve,
    },
  },
};

export const presenceAnimate = {
  initial: { opacity: 0, y: -30 },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: { opacity: 0, y: -30 },
  transition: (custom) => ({
    delay: (custom + 1) * 0.08,
    ease: "easeInOut",
    type: "spring",
    stiffness: 120,
  }),
};

// IMAGE SLIDER VARIANTS

export const sliderImageAnimationVariants = {
  hidden: {
    scale: 1.3,
    y: "-20%",
  },
  visible: {
    scale: 1.06,
    y: 0,
    transition: {
      duration: 1.5,
      ease: smoothCurve,
    },
  },
  dragging: {
    scale: 1,
    transition: {
      duration: 0.4,
      ease: primaryCurve,
    },
  },
  dragEnd: {
    scale: 1.06,
    transition: {
      duration: 0.4,
      ease: primaryCurve,
    },
  },
};

export const sliderButtonAnimationVariants = {
  buttonHidden: {
    opacity: 0,
    scale: 0.6,
    transition: {
      duration: 0.2,
      ease: smoothCurve,
    },
  },
  buttonVisible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: smoothCurve,
    },
  },
};
