import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { motion } from "framer-motion";
import { inject, observer } from "mobx-react";
import { NavigationStore } from "~stores";

import {
  Container,
  Row,
  Col,
  NewsLetterSignup,
  CookieBanner,
} from "~views/shared/components";
import { staggerChildren, staggerChildrenItem, fadeInItem } from "~libs/framer/variants";

import * as style from "./Footer.module.scss";

interface Props {
  navigationStore?: NavigationStore;
  showBottomRowInFooter: boolean;
  theme: "dark" | "light";
}

const Navigation = ({ links }) => (
  <nav className={style.navigation}>
    <motion.ul
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={staggerChildren}
    >
      {links.map((nav) => (
        <motion.li variants={staggerChildrenItem} key={nav.id}>
          <Link to={nav.path}>
            <span>{nav.label.toUpperCase()}</span>
          </Link>
        </motion.li>
      ))}
    </motion.ul>
  </nav>
);

const CopyrightText = ({ text }) => (
  <aside className={style.copyright}>
    <motion.p
      variants={fadeInItem}
      custom={0}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    ></motion.p>
  </aside>
);

const SocialMediaLinks = ({ links }) => (
  <motion.ul
    className={style.socialMedia}
    variants={staggerChildren}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
  >
    {links.map((sm, index) => (
      <motion.li variants={staggerChildrenItem} key={index}>
        <a href={sm.linkButton.socialMediaLink?.url} target={"_blank"}>
          <span>{sm.linkButton.socialMediaName}</span>
        </a>
      </motion.li>
    ))}
  </motion.ul>
);

export const Footer = inject("navigationStore")(
  observer(({ navigationStore, showBottomRowInFooter, theme }: Props) => {
    const menuItems = navigationStore.footerMenu.items || [];

    return (
      <StaticQuery
        query={query}
        render={(data) => (
          <footer className={`${style.footer} ${theme === "light" ? style.light : ""}`}>
            <Container>
              <Row className={style.footerUpperRow}>
                <Col md={12} lg={4} className={"offset-lg-1"}>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={fadeInItem}
                    custom={0}
                  >
                    <NewsLetterSignup theme={theme} />
                  </motion.div>
                </Col>
                <Col md={12} lg={5} className={"offset-lg-1"}>
                  <SocialMediaLinks
                    links={data.wp.themeSettings.themeSettingsFields.socialMediaLinks}
                  />
                </Col>
              </Row>
            </Container>
            <Container fluid>
              <Row className={style.footerBottomRow}>
                {showBottomRowInFooter && (
                  <>
                    <Col
                      xs={10}
                      md={12}
                      lg={12}
                      xl={9}
                      className={`offset-1 offset-md-0`}
                    >
                      <CopyrightText
                        text={data.wp.themeSettings.themeSettingsFields.copyrightFooter}
                      />
                    </Col>
                    <Col lg={12} xl={3}>
                      <Navigation links={menuItems} />
                    </Col>
                  </>
                )}
              </Row>
            </Container>
            <CookieBanner />
          </footer>
        )}
      />
    );
  })
);

const query = graphql`
  query MyQuery {
    wp {
      themeSettings {
        themeSettingsFields {
          socialMediaLinks {
            linkButton {
              socialMediaLink {
                target
                title
                url
              }
              socialMediaName
            }
          }
          copyrightFooter
        }
      }
    }
  }
`;
