// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--c4c2f";
export var arrow_no = "styles-module--arrow_no--e9311";
export var arrow_yes = "styles-module--arrow_yes--876df";
export var bottomInfo = "styles-module--bottom-info--192b2";
export var contentHolder = "styles-module--content-holder--393c5";
export var description = "styles-module--description--62f47";
export var desktopVideoWrap = "styles-module--desktop-video-wrap--83abb";
export var flex = "styles-module--flex--72bb5";
export var flex_1 = "styles-module--flex_1--372aa";
export var flex_center = "styles-module--flex_center--59904";
export var flex_center_col = "styles-module--flex_center_col--172ea";
export var flex_col = "styles-module--flex_col--5cc98";
export var flex_column = "styles-module--flex_column--52e10";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--f4af8";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--9d895";
export var flex_row = "styles-module--flex_row--dab36";
export var imageAnimationHolder = "styles-module--image-animation-holder--45357";
export var insightHero = "styles-module--insight-hero--a9161";
export var label = "styles-module--label--cf4d9";
export var linkAnimation = "styles-module--link-animation--67e5c";
export var navigation = "styles-module--navigation--d6e06";
export var navigationCounter = "styles-module--navigation-counter--59e24";
export var readMore = "styles-module--read-more--6fb0d";
export var readMoreWrapper = "styles-module--read-more-wrapper--a7f95";
export var readTime = "styles-module--read-time--a8abb";
export var rotating = "styles-module--rotating--99820";
export var swiperWrapper = "styles-module--swiper-wrapper--7cf75";