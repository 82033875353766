// extracted by mini-css-extract-plugin
export var arrow = "ScatteredGallery-module--arrow--1e559";
export var arrow_no = "ScatteredGallery-module--arrow_no--adf30";
export var arrow_yes = "ScatteredGallery-module--arrow_yes--b792c";
export var container = "ScatteredGallery-module--container--96f60";
export var firstGroupHolder = "ScatteredGallery-module--first-group-holder--a8c02";
export var flex = "ScatteredGallery-module--flex--b157f";
export var flex_1 = "ScatteredGallery-module--flex_1--b70c2";
export var flex_center = "ScatteredGallery-module--flex_center--cf5f1";
export var flex_center_col = "ScatteredGallery-module--flex_center_col--7e1e9";
export var flex_col = "ScatteredGallery-module--flex_col--0647f";
export var flex_column = "ScatteredGallery-module--flex_column--95a3a";
export var flex_fix_center_col = "ScatteredGallery-module--flex_fix_center_col--28d63";
export var flex_fixed_center_col = "ScatteredGallery-module--flex_fixed_center_col--d6b73";
export var flex_row = "ScatteredGallery-module--flex_row--bcf37";
export var groupingHolder = "ScatteredGallery-module--grouping-holder--3cba8";
export var imageAnimationHolder = "ScatteredGallery-module--image-animation-holder--006f0";
export var imageHolders = "ScatteredGallery-module--image-holders--0994f";
export var linkAnimation = "ScatteredGallery-module--link-animation--a51bb";
export var rotating = "ScatteredGallery-module--rotating--bb192";
export var secondGroupHolder = "ScatteredGallery-module--second-group-holder--7f957";
export var stickyButton = "ScatteredGallery-module--sticky-button--59f83";
export var stickyTitle = "ScatteredGallery-module--sticky-title--ec878";
export var subtitle = "ScatteredGallery-module--subtitle--17e44";
export var swiperWrapper = "ScatteredGallery-module--swiper-wrapper--a3c02";
export var thirdGroupHolder = "ScatteredGallery-module--third-group-holder--24086";
export var title = "ScatteredGallery-module--title--56b15";