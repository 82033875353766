// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--c4233";
export var arrow_no = "styles-module--arrow_no--d880f";
export var arrow_yes = "styles-module--arrow_yes--5c3f2";
export var flex = "styles-module--flex--5a167";
export var flex_1 = "styles-module--flex_1--46dfb";
export var flex_center = "styles-module--flex_center--0740b";
export var flex_center_col = "styles-module--flex_center_col--d919a";
export var flex_col = "styles-module--flex_col--f790d";
export var flex_column = "styles-module--flex_column--9d377";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--01c8a";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--4eb8c";
export var flex_row = "styles-module--flex_row--db5e4";
export var imageAnimationHolder = "styles-module--image-animation-holder--b20a9";
export var linkAnimation = "styles-module--link-animation--29ad3";
export var rotating = "styles-module--rotating--85360";
export var swiperWrapper = "styles-module--swiper-wrapper--0d923";
export var wrapper = "styles-module--wrapper--f261b";