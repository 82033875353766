import React, { useState, useEffect } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { tx } from "~libs/i18n";

import { Input } from "~components";
import { motion } from "framer-motion";

import * as style from "./NewsLetterSignup.module.scss";

interface Props {
  email?: string;
  responseMessage?: string;
  theme: "dark" | "light";
}

export const NewsLetterSignup = ({ theme }: Props) => {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [shake, setShake] = useState(false);

  const emailValidation = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const resetForm = () => {
    setSubmitted(false);
    setEmail("");
    setResponseMessage("");
    setValidEmail(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validEmail) {
      addToMailchimp(email, {
        FNAME: "-",
        LNAME: "-",
      }).then((data) => {
        if (data.result === "success") {
          setResponseMessage(tx("newsletter.thankYou"));
          setSubmitted(true);
        } else if (data.result === "error") {
          if (data.msg.includes("already")) {
            setResponseMessage(tx("newsletter.already"));
          } else {
            setResponseMessage(tx("newsletter.problem"));
          }
        }
        setSubmitted(true);
      });
    } else {
      setShake(true);
      setTimeout(() => {
        setShake(false);
      }, 400);
    }
  };

  useEffect(() => {
    setValidEmail(emailValidation(email));
  }, [email]);

  return (
    <form
      className={`
      ${style.newsletterForm}
      ${theme === "light" ? style.light : ""} 
      ${validEmail ? style.validEmail : ""} 
      ${shake ? style.invalidEmail : ""}
      ${submitted ? style.submitted : ""} `}
      onSubmit={handleSubmit}
    >
      <motion.div initial={"invalid"}>
        {submitted ? (
          <p onClick={() => resetForm()}>{responseMessage}</p>
        ) : (
          <>
            <Input
              type="email"
              placeholder={tx("newsletter.signUp")}
              label={""}
              onChange={(val) => setEmail(val)}
              value={email}
            />
            <button type={"submit"}>
              <i className={"icon-arrow-right"}></i>
            </button>
          </>
        )}
      </motion.div>
    </form>
  );
};
