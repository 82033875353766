// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--91d99";
export var arrow_no = "styles-module--arrow_no--847b4";
export var arrow_yes = "styles-module--arrow_yes--7e56d";
export var base = "styles-module--base--2ea63";
export var flex = "styles-module--flex--ce4b2";
export var flex_1 = "styles-module--flex_1--4c42f";
export var flex_center = "styles-module--flex_center--b3953";
export var flex_center_col = "styles-module--flex_center_col--ce694";
export var flex_col = "styles-module--flex_col--e63bc";
export var flex_column = "styles-module--flex_column--b1312";
export var flex_fix_center_col = "styles-module--flex_fix_center_col--d6dfe";
export var flex_fixed_center_col = "styles-module--flex_fixed_center_col--db3d5";
export var flex_row = "styles-module--flex_row--6c00f";
export var imageAnimationHolder = "styles-module--image-animation-holder--e685e";
export var linkAnimation = "styles-module--link-animation--99da7";
export var rotating = "styles-module--rotating--08f0f";
export var swiperWrapper = "styles-module--swiper-wrapper--a7a16";
export var visible = "styles-module--visible--5cc55";