import React from "react";

import { Container, Row, Col } from "~components";
import { IGeneralContacts } from "~models";
import { motion } from "framer-motion";
import { Link } from "gatsby";

import * as style from "./GeneralContacts.module.scss";
import { staggerChildren, staggerChildrenItem } from "~libs/framer/variants";

interface Props {
  section: IGeneralContacts;
}

export const GeneralContacts = ({ section }: Props) => {
  const { contacts } = section;

  return (
    <section data-sectionname="GeneralContacts">
      <Container>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={staggerChildren}
        >
          <Row>
            {contacts.map((contact, index) => (
              <Col key={index} lg={4} className={style.contentHolder}>
                <motion.div className={style.content} variants={staggerChildrenItem}>
                  <p dangerouslySetInnerHTML={{ __html: contact.typeOfContact.text }}></p>
                  {contact.typeOfContact.email && (
                    <a
                      href={"mailto:" + contact.typeOfContact.email}
                      target="_blank"
                      className={style.link}
                    >
                      {contact.typeOfContact.email}
                    </a>
                  )}
                  {contact.typeOfContact.number && (
                    <a
                      href={"tel:" + contact.typeOfContact.number}
                      target="_blank"
                      className={style.link}
                    >
                      {contact.typeOfContact.number}
                    </a>
                  )}
                  {contact.typeOfContact.linkButton.buttonText && (
                    <Link to={contact.typeOfContact.linkButton.buttonLink.url}>
                      <span>
                        {contact.typeOfContact.linkButton.buttonText.toLocaleUpperCase()}{" "}
                        <i className={"icon-arrow-right"} />
                      </span>
                    </Link>
                  )}
                </motion.div>
              </Col>
            ))}
          </Row>
        </motion.div>
      </Container>
    </section>
  );
};
