// extracted by mini-css-extract-plugin
export var arrow = "IntroductiveText-module--arrow--83d41";
export var arrow_no = "IntroductiveText-module--arrow_no--12f27";
export var arrow_yes = "IntroductiveText-module--arrow_yes--65481";
export var content = "IntroductiveText-module--content--6e3e2";
export var flex = "IntroductiveText-module--flex--9e82b";
export var flex_1 = "IntroductiveText-module--flex_1--8fdbf";
export var flex_center = "IntroductiveText-module--flex_center--cd772";
export var flex_center_col = "IntroductiveText-module--flex_center_col--01a27";
export var flex_col = "IntroductiveText-module--flex_col--ffe07";
export var flex_column = "IntroductiveText-module--flex_column--4d293";
export var flex_fix_center_col = "IntroductiveText-module--flex_fix_center_col--23889";
export var flex_fixed_center_col = "IntroductiveText-module--flex_fixed_center_col--c718f";
export var flex_row = "IntroductiveText-module--flex_row--47a68";
export var imageAnimationHolder = "IntroductiveText-module--image-animation-holder--6c363";
export var linkAnimation = "IntroductiveText-module--link-animation--782ff";
export var rotating = "IntroductiveText-module--rotating--c6172";
export var swiperWrapper = "IntroductiveText-module--swiper-wrapper--f0ca9";