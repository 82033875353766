import { observable, action } from "mobx";
import { onAnimationFrame } from "~libs/utils";
import { screenBreakpoints, TScreenKeys } from "~libs/constants";

export class UIStore {
  @observable
  breakpoint: TScreenKeys = "xl";

  @observable
  windowWidth: number = 0;

  @observable
  customCursorEvent: boolean = false;

  @observable
  mousePosition: any = { x: 0, y: 0 };

  @observable
  windowHeight: number = 0;

  @observable
  windowPageYOffset: number = 0;

  @observable
  windowPageXOffset: number = 0;

  @observable
  defaultBackgroundColor: string = "#ffffff";

  @observable
  defaultContentColor: string = "#000000";

  @observable
  backgroundColor: string = "";

  @observable
  contentColor: string = "inherit";

  @observable
  headerTheme: "dark" | "light" = "dark";

  @observable
  defaultHeaderTheme: "dark" | "light" = "dark";

  @observable
  canLogoSlideAnimate: boolean = true;

  @observable
  whitePaperFormVisible: boolean = false;

  onMount = () => {
    window.addEventListener("resize", this.onResize);
    this.onResize();

    window.addEventListener("scroll", this.onScroll);
    this.onScroll();

    document.addEventListener("mousemove", this.onMouseMove);
  };

  onUnmount = () => {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.onScroll);
    document.removeEventListener("mousemove", this.onMouseMove);
    this.setCustomCursorEvent(false);
  };

  isEqualOrGreatherThanBreakpoint = (bp: TScreenKeys) => {
    return this.windowWidth >= screenBreakpoints[bp];
  };

  @action
  onResize = () => {
    onAnimationFrame(() => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;

      this.breakpoint = Object.keys(screenBreakpoints).find((key: TScreenKeys) => {
        return screenBreakpoints[key] >= this.windowWidth;
      }) as TScreenKeys;
    });
  };

  @action
  onScroll = () => {
    onAnimationFrame(() => {
      this.windowPageYOffset = window.pageYOffset;
      this.windowPageXOffset = window.pageXOffset;
    });
  };

  @action
  setBackgroundColor = (color: string) => {
    this.backgroundColor = color;
    // this.setHeaderTheme(this.idealReverseColor(color) === "#000000" ? "dark" : "light");
  };

  @action
  setDefaultBackgroundColor = (color: string) => {
    this.defaultBackgroundColor = color;
    this.defaultContentColor = this.idealReverseColor(color);
  };

  @action
  setDefaultContentColor = (color: string) => {
    this.defaultContentColor = color;
  };

  @action
  setContentColor = (color: string) => {
    this.contentColor = color;
  };

  @action
  setHeaderTheme = (theme: "dark" | "light") => {
    this.headerTheme = theme;
  };

  @action
  setDefaultHeaderTheme = (theme: "dark" | "light") => {
    this.defaultHeaderTheme = theme;
  };

  @action
  allowLogoSlideAnimate = (status: boolean) => {
    this.canLogoSlideAnimate = status;
  };

  @action
  setCustomCursorEvent = (status: boolean) => {
    this.customCursorEvent = status;
  };

  @action
  onMouseMove = (event) => {
    const { clientX, clientY } = event;
    this.mousePosition = { x: clientX, y: clientY };
  };

  toggleWhitePaperForm = () => {
    this.whitePaperFormVisible = !this.whitePaperFormVisible;
  };

  hexToRgb = (color) => {
    let r = color.substring(1, 3);
    let g = color.substring(3, 5);
    let b = color.substring(5, 7);
    return {
      R: parseInt(r, 16),
      G: parseInt(g, 16),
      B: parseInt(b, 16),
    };
  };

  idealReverseColor = (bgColor: string) => {
    let nThreshold = 105;
    let components = this.hexToRgb(bgColor);
    let bgDelta = components.R * 0.299 + components.G * 0.587 + components.B * 0.114;
    return 255 - bgDelta < nThreshold ? "#000000" : "#ffffff";
  };
}
